import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import DisclaimerCheck from '../../../components/wizard_components/DisclaimerCheck';
import StartupWizardNotaryCheck from '../../../components/wizard_components/StartupWizardNotaryCheck';
import ShareholdersAgreementGenerator from '../../../components/generated_documents/startup_wizard/ShareholdersAgreementGenerator';
import ShareholdersListGenerator from '../../../components/generated_documents/startup_wizard/ShareholdersListGenerator';
import ShareholdersAgreementGeneratorGerman from '../../../components/generated_documents/startup_wizard/ShareholdersAgreementGeneratorGerman';
import ArticlesOfAssociationGenerator from '../../../components/generated_documents/startup_wizard/ArticlesOfAssociationGenerator';
import ArticlesOfAssociationGeneratorGerman from '../../../components/generated_documents/startup_wizard/ArticlesOfAssociationGeneratorGerman';

const StartupWizardNotaryAndBank = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardName, liveMode, toggleLiveMode, updateWizardData, wizardData, refetchData } = useWizard();
  const [expandedSections, setExpandedSections] = useState({});
  const { data, dataStatus, loading: notaryDataLoading } = useFetchData(wizardName, 'notaryAndBank');
  const { data: introData, loading: introLoading, error: introError } = useFetchData(wizardName, 'introduction');
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const [pageStatusChecked, setPageStatusChecked] = useState(false);
  const [incompletePagesList, setIncompletePagesList] = useState([]);
  const hasInitialLoad = useRef(false);
  const [hasRefetched, setHasRefetched] = useState(false);

  // Initial data refresh on component mount
  useEffect(() => {
    const initializeData = async () => {
      if (!hasInitialLoad.current) {
        console.log('Refreshing wizard data on initial load');
        hasInitialLoad.current = true;
        await refetchData();
        setHasRefetched(true);
      }
    };

    initializeData();
  }, [refetchData]);

  // Memoize pages definition
  const pages = useMemo(() => [
    { id: "introduction", name: t('StartupWizard.introduction') },
    { id: "legalForm", name: t('StartupWizard.legalForm') },
    { id: "company", name: t('StartupWizard.company') },
    { id: "founders", name: t('StartupWizard.founders') },
    { id: "articlesOfAssociation", name: t('StartupWizard.articlesOfAssociation') },
    { id: "shareholdersAgreement", name: t('StartupWizard.shareholdersAgreement') },
  ], [t]);

  // Memoize the getPageStatus function
  const getPageStatus = useCallback((pageId) => {
    const publishedData = wizardData?.published?.[pageId];
    const draftData = wizardData?.draft?.[pageId];

    // Debug logging
    console.log(`Checking status for ${pageId}:`, {
      publishedData,
      draftData,
    });

    const isPublishedEmpty = !publishedData || Object.keys(publishedData).length === 0;
    const isDraftEmpty = !draftData || Object.keys(draftData).length === 0;
    const isDraftDifferent = JSON.stringify(publishedData) !== JSON.stringify(draftData);

    if (isPublishedEmpty && isDraftEmpty) {
      return 'empty';
    } else if (isDraftDifferent) {
      return 'draft';
    }
    return 'complete';
  }, [wizardData]);

// Effect to check page status once all data is loaded
useEffect(() => {
  const checkPageStatus = async () => {
    // Only proceed if we have the initial data refresh
    if (!hasRefetched) {
      return;
    }

    // Wait for any pending state updates
    await new Promise(resolve => setTimeout(resolve, 250));

    console.log('Checking page status with wizard data:', wizardData);

    const incomplete = pages.filter(page => {
      const status = getPageStatus(page.id);
      console.log(`Page ${page.id} status:`, status);
      return status === 'empty' || status === 'draft';
    }).map(page => ({
      ...page,
      status: getPageStatus(page.id)
    }));

    console.log('Incomplete pages:', incomplete);
    setIncompletePagesList(incomplete);
    setPageStatusChecked(true);
  };

    if (!notaryDataLoading && !introLoading && wizardData) {
      checkPageStatus();
    }
  }, [wizardData, pages, getPageStatus, notaryDataLoading, introLoading]);

  // Get founders data from wizard context
  const founders = useMemo(() => wizardData.published?.founders?.founders || [], [wizardData]);

  // Memoize initial bank details
  const initialBankDetails = useMemo(() => ({
    notaryAndBankDetails: {
      chosenBank: data?.notaryAndBankDetails?.chosenBank || data?.chosenBank || ''
    }
  }), [data]);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Auto-save draft function
  const autoSaveDraft = useCallback(async (values) => {
    if (liveMode) {
      const result = await saveAsDraft(wizardName, 'notaryAndBank', values.notaryAndBankDetails);
      if (result.success) {
        updateWizardData('notaryAndBank', values.notaryAndBankDetails, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, wizardName, updateWizardData]);

  // AutoSave Component
  const AutoSave = ({ values }) => {
    const debouncedAutoSaveRef = useRef();

    useEffect(() => {
      debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
    }, []);

    useEffect(() => {
      if (liveMode) {
        debouncedAutoSaveRef.current(values);
        return () => debouncedAutoSaveRef.current.cancel();
      }
    }, [liveMode, values]);

    return null;
  };

  const formatNumber = (number, decimalPlaces = 2) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(number);
  };

  const Collapsible = ({ title, children, id }) => {
    const isExpanded = expandedSections[id];

    return (
      <div className="mb-1">
        <p className="mt-0 mb-0">
          <button
            type="button"
            className="flex items-center justify-between w-full p-5 text-large font-normal text-gray-900 bg-white hover:bg-gray-50"
            onClick={() => toggleSection(id)}
            aria-expanded={isExpanded}
            aria-controls={`accordion-${id}-body`}
          >
            <span>{title}</span>
            <svg
              className={`w-3 h-3 shrink-0 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </button>
        </p>
        <div
          id={`accordion-${id}-body`}
          className={!isExpanded ? 'hidden' : ''}
          aria-labelledby={`accordion-${id}-heading`}
        >
          <div className="px-5 py-4 bg-white">
            <p className="text-base text-gray-900">{children}</p>
          </div>
        </div>
      </div>
    );
  };

  const validationSchema = Yup.object({
    notaryAndBankDetails: Yup.object({
      chosenBank: Yup.string().required(t('Please enter your chosen bank'))
    })
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await saveData(wizardName, 'notaryAndBank', values.notaryAndBankDetails);
      if (result.success) {
        setNotification({ show: true, type: 'success', message: t('Data saved successfully') });
        updateWizardData('notaryAndBank', values.notaryAndBankDetails, false);
        setTimeout(() => navigate('/startup-wizard/final'), 1000);
      }
    } catch (error) {
      setNotification({ show: true, type: 'error', message: t('Error saving data') });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDraftSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await saveAsDraft(wizardName, 'notaryAndBank', values.notaryAndBankDetails);
      if (result.success) {
        setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
        updateWizardData('notaryAndBank', values.notaryAndBankDetails, true);
      }
    } catch (error) {
      setNotification({ show: true, type: 'error', message: t('Error saving draft') });
    } finally {
      setSubmitting(false);
    }
  };

  // Modified loading check
  const isLoading = notaryDataLoading || introLoading || !pageStatusChecked || !hasRefetched;

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  if (introError) {
    return <div>{t('common.error', { error: introError?.message })}</div>;
  }

  if (introData && !introData.disclaimerChecked) {
    return <DisclaimerCheck />;
  }

  // Only check incomplete pages after we're sure all data is loaded, checked, and refetched
  if (pageStatusChecked && hasRefetched && incompletePagesList && incompletePagesList.length > 0) {
    console.log('Rendering NotaryCheck with incomplete pages:', incompletePagesList);
    return <StartupWizardNotaryCheck incompletePages={incompletePagesList} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <DataStatusBanner status={dataStatus} />

      <Formik
        initialValues={initialBankDetails}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, touched, isSubmitting, submitForm }) => (
          <Form className="mt-8 space-y-8">
            <AutoSave values={values} />
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-6">{t('Notary')}</h2>
              <p className="text-gray-600 mb-6">
                {t('After drafting your Articles of Association and your Shareholders Agreement, the notary appointment is a legal requirement in Germany and therefore a crucial step in the process. Here is how it should be conducted from the perspective of the founders.')}
              </p>

              <div className="bg-white shadow rounded-lg p-6 mb-8">
                <h3 className="text-xl font-semibold mb-4">{t('Find a notary in your area')}</h3>
                <p className="text-gray-600 mb-4">
                  {t('Any notary is able to complete your registration. So chose one, that is easily accessible and who gives you a good feeling. Later when financing rounds become more complex it is recommendable to choose a notary that is more familiar with corporate law and complex transaction.')}
                </p>
                <a
                  href="https://www.notar.de/notarsuche/notarsuche"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:text-white bg-[#2A9D8F] hover:bg-[#238276] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                >
                  {t('Find a Notary on NOTAR.DE')}
                </a>
              </div>

              <h3 className="text-xl font-semibold mb-4">{t('This is what you will do with your Notary:')}</h3>
              <div className="space-y-0">

                <div className="bg-white shadow rounded-lg p-6 mb-8">
                  <h3 className="text-lg font-medium mb-4">
                    {t('StartupWizard.notaryDocuments.sectionTitle')}
                  </h3>

                  <div className="grid grid-cols-2 gap-6">
                    {/* Column Headers */}
                    <div className="bg-gray-50 rounded-t-lg">
                      <div className="p-3">
                        <h4 className="font-semibold text-gray-900">
                          {t('StartupWizard.notaryDocuments.germanHeader')}
                        </h4>
                        <p className="text-sm text-gray-600 mt-1">
                          {t('StartupWizard.notaryDocuments.germanDescription')}
                        </p>
                      </div>
                      <hr className="border-gray-200" />
                      <div className="p-4 space-y-4">
                        <div onClick={(e) => e.preventDefault()}>
                          <ShareholdersListGenerator />
                        </div>
                        <div onClick={(e) => e.preventDefault()}>
                          <ShareholdersAgreementGeneratorGerman />
                        </div>
                        <div onClick={(e) => e.preventDefault()}>
                          <ArticlesOfAssociationGeneratorGerman />
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 rounded-t-lg">
                      <div className="p-3">
                        <h4 className="font-semibold text-gray-900">
                          {t('StartupWizard.notaryDocuments.englishHeader')}
                        </h4>
                        <p className="text-sm text-gray-600 mt-1">
                          {t('StartupWizard.notaryDocuments.englishDescription')}
                        </p>
                      </div>
                      <hr className="border-gray-200" />
                      <div className="p-4 space-y-4">
                        <div className="h-[40px]" /> {/* Spacer for ShareholdersList alignment */}
                        <div onClick={(e) => e.preventDefault()}>
                          <ShareholdersAgreementGenerator />
                        </div>
                        <div onClick={(e) => e.preventDefault()}>
                          <ArticlesOfAssociationGenerator />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <Collapsible id="preparation" title={t('Preparation')}>
                    {t('Send the articles of association and the shareholders agreements to your notary. They will complete the documents and will coordinate the meeting with you and your fellow founders. Make sure that all details, including the share capital contributions are clearly outlined in the documents.')}
                  </Collapsible>

                  <Collapsible id="appointment" title={t('Appointment')}>
                    {t('At the notary, you will proof of identity (passport or ID) and formally sign the incorperation documents. The notary will first read the all document aloud to ensure that everyone understands and agrees with its content. This is a legal requirement in Germany to confirm the founders are fully aware of the commitments they are making.')}
                  </Collapsible>

                  <Collapsible id="signatures" title={t('Signatures')}>
                    {t('Each founder must sign the incorporation documents in front of the notary. The notary then certifies the documents, making them official.')}
                  </Collapsible>

                  <Collapsible id="nextSteps" title={t('Next Steps')}>
                    {t('Usually you will receive the provisionally notarized documents in electronic form (but ask your notary just to be sure) at the same day. With these documents you can proceed to open your business account at your bank of choice to pay in the share capital. Only when the share capital has been paid into your business account in accordance with the articles of association will the notary finalize the registration of your company for entry in the commercial register.')}
                  </Collapsible>
                </div>
              </div>
            </div>

            <div className="mt-12">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-6">{t('Open a bank account for your business')}</h2>
              <p className="text-gray-600 mb-6">
                {t('One of the most important services for your startup is the business account. A business account differs significantly from a personal account. Unlike a personal account, opening a business account is a much more complex process.')}
              </p>

              <div className="bg-white shadow rounded-lg p-6 mb-8">
                <h3 className="text-xl font-semibold mb-4">{t('Choose the right bank')}</h3>
                <p className="text-gray-600 mb-4">{t('Here are our most important criteria for selecting the right bank:')}</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  <li>{t('Direct access to the corporate client department: This ensures that you have a designated contact person who understands your business needs and can offer tailored advice and solutions.')}</li>
                  <li>{t('User-friendly online banking: A well-designed, easy-to-use online banking system is essential for efficiently managing your company\'s finances, making payments, and tracking cash flow.')}</li>
                  <li>{t('Associated credit department: Especially if you can show revenue, a working capital loan from your bank might help you avoid raising funds through additional financing rounds.')}</li>
                  <li>{t('Costs for transactions and account management: If your business processes a lot of transactions, these fees can add up.')}</li>
                </ul>
                <p className="mt-4 text-gray-600">
                  {t('Cooperative banks, like Volks- und Raiffeisenbanken, generally offer a solid package. In addition to excellent online banking, their corporate client services are exceptional. However, this level of service often comes at a higher price. In our opinion, though, it\'s worth the investment.')}
                </p>
              </div>

              <div className="bg-white shadow rounded-lg p-6 mb-8">
                <h3 className="text-xl font-semibold mb-4">{t('Create a business account')}</h3>
                <p className="text-gray-600 mb-4">
                  {t('You should allocate enough time for opening the account. Depending on whether you can provide all the necessary documents, this process will take at least two weeks, and in some exceptional cases, up to two months.')}
                </p>

                <div className="mb-6">
                  <label htmlFor="chosenBank" className="block text-sm font-medium text-gray-700 mb-2">
                    {t('Please enter the name of your chosen bank:')}
                  </label>
                  <Field
                    type="text"
                    name="notaryAndBankDetails.chosenBank"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F]"
                  />
                  {errors.notaryAndBankDetails?.chosenBank && touched.notaryAndBankDetails?.chosenBank && (
                    <p className="mt-2 text-sm text-red-600">{errors.notaryAndBankDetails.chosenBank}</p>
                  )}
                </div>

                <p className="text-gray-600">{t('Use the documents from the notary to approach your bank of choice:')}</p>
                <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-600">
                  <li>{t('The notarized founding documents (including the preliminary certificate of incorporation)')}</li>
                  <li>{t('Proof of identity for the shareholders and managing directors (passport or national ID)')}</li>
                  <li>{t('Sometimes a business plan or financial projections, depending on the bank\'s requirements')}</li>
                </ul>
              </div>

              <div className="bg-white shadow rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-4">{t('Pay, Register, Activate')}</h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">{t('Deposit the Initial Share Capital:')}</h4>
                    <p className="text-gray-600 mb-4">
                      {t('For companies like a GmbH, the shareholders must deposit the minimum share capital into the newly opened business account. The bank will issue a confirmation of the deposit, which is a crucial document for the next step.')}
                    </p>

                    {founders.length > 0 && (
                      <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                        <p className="font-medium text-gray-900 mb-2">{t('The following payments need to be made:')}</p>
                        <ul className="list-disc pl-6 space-y-2">
                          {founders.map((founder, index) => (
                            <li key={index} className="text-primary-color font-semibold">
                              {founder.fullName}: {formatNumber(founder.capitalInvested)} EUR
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">{t('Submit Confirmation to the Commercial Register:')}</h4>
                    <p className="text-gray-600">
                      {t('Once the share capital is deposited, you must send the confirmation from the bank to your notary, so they can submit it to the local commercial register. This finalizes the company\'s registration, and the company will then officially exist as a legal entity.')}
                    </p>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">{t('Activate Your Business Account:')}</h4>
                    <p className="text-gray-600">
                      {t('After completing the company registration, you can send the Handelsregisterauszug to your bank and your business account will be fully activated, allowing you to start conducting transactions.')}
                    </p>
                  </div>
                </div>
              </div>

              <ActionButtons
                onCancel={() => navigate('/startup-wizard')}
                onDraft={() => handleDraftSubmit(values, { setSubmitting: () => { } })}
                onSubmit={submitForm}
                isSubmitting={isSubmitting}
                currentPage="notaryAndBank"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

export default StartupWizardNotaryAndBank;