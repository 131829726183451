import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n'; // Import the i18n configuration
import Glossary from './Glossary'; // Import the Glossary component
import { TaskProvider } from './context/TaskContext'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Glossary>
      <TaskProvider>
        <App />
      </TaskProvider>
    </Glossary>
  // </React.StrictMode>
);