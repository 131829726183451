import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export default function Reports() {
  const { user } = useContext(AuthContext);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Reports</h1>
      <p>Welcome, {user?.first_name}!</p>
      <p>There are no reports yet. SAD.</p>
    </div>
  );
}
