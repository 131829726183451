import countryDataRaw from './countryData.json';

export const countryData = countryDataRaw;

export const getCountryByCode = (code) => countryData.find(country => country.code === code);
export const getCountryByName = (name) => countryData.find(country => country.name === name);
export const getCountriesBySubregion = (subregion) => countryData.filter(country => country.subregion === subregion);
export const getCountriesByRegion = (region) => countryData.filter(country => country.region === region);
export const getCountriesByEU = () => countryData.filter(country => country.EU);
export const getCountriesByGDPR = () => countryData.filter(country => country.GDPR);
export const getCountriesByNIS2 = () => countryData.filter(country => country.NIS2);
export const getCountriesByFISMA = () => countryData.filter(country => country.FISMA);
export const getCountriesByPIPEDA = () => countryData.filter(country => country.PIPEDA);