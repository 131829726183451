import { Navigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';

function PrivateRoute({ children }) {
  const { user, loading } = useContext(AuthContext);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setIsCheckingAuth(false);
    } else if (user !== null) {
      setIsCheckingAuth(false);
    }
  }, [user]);

  if (loading || isCheckingAuth) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/" />;
}

export default PrivateRoute;
