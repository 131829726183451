import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginSuccess.css'; // Make sure to create this CSS file
import { useTranslation } from 'react-i18next';

export default function LoginSuccess() {
  const [progress, setProgress] = useState(0);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    let timer;
    if (progress < 100) {
      timer = setTimeout(() => {
        if (progress < 70) {
          setProgress(progress + 1);
        } else if (progress === 70) {
          setTimeout(() => {
            setProgress(progress + 1);
          }, 1000);
        } else {
          setProgress(progress + 1);
        }
      }, 40);
    } else {
      setShowCheckmark(true);
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [progress, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        <h2 className="text-2xl font-bold mb-6">{t('login_successful')}</h2>
        {!showCheckmark ? (
          <>
            <div className="relative w-full h-6 mb-4 bg-transparent border border-primary-color rounded-full">
              <div
                className="absolute top-0 left-0 h-full bg-secondary-color rounded-full"
                style={{ width: `${progress}%`, transition: 'width 0.1s' }}
              ></div>
            </div>
            <p className="text-tertiary-color animate-blink">{t('loading_company_data')}</p>
          </>
        ) : (
          <div className="wrapper">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
