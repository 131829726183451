import { useState } from 'react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';

export const useFormSubmit = (nextPage) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    setSubmitting(true);
    setError(null);
  
    try {
      const response = await axios.post('/api/v1/wizards/know_yourself', data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      console.log('Form submitted successfully. Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Form submission error:', error);
      setError(error);
      throw error;
    } finally {
      setSubmitting(false);
    }
  };
  
  const handleDraftSubmit = async (data) => {
    setSubmitting(true);
    setError(null);
  
    try {
      const response = await axios.post('/api/v1/wizards/know_yourself/draft', data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      console.log('Draft saved successfully. Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Draft saving error:', error);
      setError(error);
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  return { handleSubmit, handleDraftSubmit, submitting, error };
}