import React, { createContext, useState, useContext, useEffect } from 'react';

export const TaskContext = createContext();

export function TaskProvider({ children }) {
  const [newTasksCount, setNewTasksCount] = useState(() => {
    const savedCount = sessionStorage.getItem('newTasksCount');
    return savedCount ? parseInt(savedCount, 10) : 0;
  });
  
  // Add state to track when the animation should play
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('newTasksCount', newTasksCount.toString());
  }, [newTasksCount]);

  const incrementNewTasks = () => {
    setNewTasksCount(prev => prev + 1);
    // Trigger animation
    setShouldAnimate(true);
    // Reset animation after it plays
    setTimeout(() => setShouldAnimate(false), 1000);
  };

  const resetNewTasks = () => {
    setNewTasksCount(0);
    setShouldAnimate(false);
  };

  return (
    <TaskContext.Provider value={{ newTasksCount, incrementNewTasks, resetNewTasks, shouldAnimate }}>
      {children}
    </TaskContext.Provider>
  );
}