import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const IubendaCookies = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Define Iubenda configuration per language
    const config = {
      de: {
        siteId: 3812585,
        cookiePolicyId: 94441897,
      },
      en: {
        siteId: 3812585,
        cookiePolicyId: 95287731,
      }
    };

    // Check for chosen language or use browser language as fallback
    const currentLang = i18n.language || navigator.language.slice(0, 2) || 'en';
    const currentConfig = config[currentLang] || config.en;

    // Initialize Iubenda with the appropriate configuration
    window._iub = window._iub || [];
    window._iub.csConfiguration = {
      siteId: currentConfig.siteId,
      cookiePolicyId: currentConfig.cookiePolicyId,
      lang: currentLang,
      storage: { useSiteId: true }
    };

    // Load required Iubenda scripts
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const scripts = [
      `https://cs.iubenda.com/autoblocking/${currentConfig.siteId}.js`,
      '//cdn.iubenda.com/cs/gpp/stub.js',
      '//cdn.iubenda.com/cs/iubenda_cs.js'
    ];

    const loadedScripts = scripts.map(loadScript);

    // Cleanup loaded scripts on unmount
    return () => {
      loadedScripts.forEach(script => {
        if (script && script.parentNode) {
          script.parentNode.removeChild(script);
        }
      });
    };
  }, [i18n.language]); // Re-run when language changes

  return null;
};

export default IubendaCookies;
