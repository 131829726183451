// src/constants/glossaryTerms.js

export const glossaryTerms = {
    'en': {
      'Pitch deck': 'A presentation used to showcase a business idea to potential investors.',
      'Ordinary resolutions': 'Decisions made by the shareholders of a company that require a simple or smaller majority vote, because they are routine.',
      'Extraordinary resolutions': 'Decisions made by the shareholders of a company that require a higher majority vote than ordinary resolutions, because they are more significant.',
      'Call-option': 'An agreement that gives the buyer (e.g. other existing shareholders) the preferential right to buy an asset (generally shares of the company) at a specified price within a specific time period.',
      'Handelsregister': 'The German commercial register is the public directory that contains information about companies and their legal representatives.',
    },
    'de': {
      'Pitch Deck': 'Eine Präsentation, die verwendet wird, um eine Geschäftsidee potenziellen Investoren vorzustellen.',
      'Ordentliche Beschlüsse': 'Entscheidungen, die von den Aktionären eines Unternehmens getroffen werden und eine einfache oder kleinere Mehrheitsentscheidung erfordern, weil sie routinemäßig sind.',
      'Außerordentliche Beschlüsse': 'Entscheidungen, die von den Aktionären eines Unternehmens getroffen werden und eine höhere Mehrheitsentscheidung als ordentliche Beschlüsse erfordern, weil sie bedeutender sind.',
      'Call-Option': 'Eine Vereinbarung, die dem Käufer (z. B. anderen bestehenden Aktionären) das Vorzugsrecht zum Kauf eines Vermögenswerts (in der Regel Aktien des Unternehmens) zu einem festgelegten Preis innerhalb eines bestimmten Zeitraums einräumt.',
      'Handelsregister': 'Das deutsche Handelsregister ist das öffentliche Verzeichnis, das Informationen über Unternehmen und ihre gesetzlichen Vertreter enthält.',
    },
  };