import React from 'react';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, Footer, AlignmentType } from 'docx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useWizard } from '../../wizard_components/WizardContext';
import {
    BOOLEAN_VALUES,
    convertStorageToDocument,
    convertUIToStorage
} from '../../../constants/translationMappings';

const ShareholdersAgreementGeneratorGerman = () => {
    const { t } = useTranslation();
    const { wizardData } = useWizard();

    const { published } = wizardData;
    const company = published?.company || {};

    // Convert the stored values to document-ready format
    const rawSha = published?.shareholdersAgreement || {};
    const sha = {
        stockOptionProgram: convertStorageToDocument(rawSha.stockOptionProgram || BOOLEAN_VALUES.NO, 'de'),
        stockOptionPercentage: rawSha.stockOptionPercentage || '10',
        shareholderBindingYears: rawSha.shareholderBindingYears || '2',
        callOptionOnExit: convertStorageToDocument(rawSha.callOptionOnExit || BOOLEAN_VALUES.NO, 'de'),
        controlInformationRights: convertStorageToDocument(rawSha.controlInformationRights || BOOLEAN_VALUES.NO, 'de'),
        shareholderInMarriage: convertStorageToDocument(rawSha.shareholderInMarriage || BOOLEAN_VALUES.NO, 'de')
    };

    const aoa = published?.articlesOfAssociation || {};
    const founders = published?.founders?.founders || [];

    // Initialize numbering configurations
    const numberingConfigs = [
        {
            reference: "decimalNumbering",
            levels: [
                {
                    level: 0,
                    format: "decimal",
                    text: "%1.",
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            indent: { left: 360, hanging: 360 },
                        },
                    },
                },
                {
                    level: 1,
                    format: "decimal",
                    text: "%1.%2.",
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            indent: { left: 720, hanging: 360 },
                        },
                    },
                },
                {
                    level: 2,
                    format: "decimal",
                    text: "%1.%2.%3.",
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            indent: { left: 1080, hanging: 360 },
                        },
                    },
                },
            ],
        },
    ];

    // Counter for alphabetical numbering references
    let alphabeticalNumberingCounter = 1;

    // Function to get a new alphabetical numbering reference
    function getAlphabeticalNumberingReference() {
        const reference = `alphabeticalNumbering${alphabeticalNumberingCounter++}`;
        numberingConfigs.push({
            reference,
            levels: [
                {
                    level: 0,
                    format: "lowerLetter",
                    text: "(%1)",
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            indent: { left: 720, hanging: 360 },
                        },
                    },
                },
                {
                    level: 1,
                    format: "lowerRoman",
                    text: "(%2)",
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            indent: { left: 1080, hanging: 360 },
                        },
                    },
                },
            ],
        });
        return reference;
    }

    const generateShareholdersAgreement = async () => {
        const { published } = wizardData;
        const company = published?.company || {};
        const sha = {
            stockOptionProgram: 'Ja',
            stockOptionPercentage: '10',
            shareholderBindingYears: '2',
            callOptionOnExit: 'Ja',
            controlInformationRights: 'Ja',
            shareholderInMarriage: 'Ja',
            ...published?.shareholdersAgreement
        };

        const aoa = published?.articlesOfAssociation || {};

        const founders = published?.founders?.founders || [];

        const currentDateTime = new Date();
        const formattedDateTime = currentDateTime.toLocaleString('de-DE');

        // Define sections with inclusion conditions
        const sections = [
            {
                title: "Virtuelles Mitarbeiterbeteiligungsprogramm",
                include: convertUIToStorage(sha.stockOptionProgram) === BOOLEAN_VALUES.YES,
                generator: generateVirtualEmployeeParticipationProgram,
            },
            {
                title: "Verfügung über Geschäftsanteile, Bezugsrechte",
                include: true,
                generator: generateDisposalOfShares,
            },
            {
                title: "Call-Option bei Ausscheiden eines Gründungsgesellschafters",
                include: convertUIToStorage(sha.callOptionOnExit) === BOOLEAN_VALUES.YES,
                generator: generateCallOptionOnDeparture,
            },
            {
                title: "Vorerwerbsrecht",
                include: true,
                generator: generatePreAcquisitionRight,
            },
            {
                title: "Mitveräußerungsrecht",
                include: true,
                generator: generateTagAlong,
            },
            {
                title: "Drag-Along",
                include: true,
                generator: generateDragAlong,
            },
            {
                title: "Kontroll- und Informationsrechte",
                include: convertUIToStorage(sha.controlInformationRights) === BOOLEAN_VALUES.YES,
                generator: generateControlAndInformationRights,
            },
            {
                title: "Güterstand",
                include: convertUIToStorage(sha.shareholderInMarriage) === BOOLEAN_VALUES.YES,
                generator: generateMatrimonialPropertyRegime,
            },
            {
                title: "Laufzeit, Kündigung",
                include: true,
                generator: generateTermAndTermination,
            },
            {
                title: "Beitritt zur Gesellschaftervereinbarung",
                include: true,
                generator: generateAccessionToShareholdersAgreement,
            },
            {
                title: "Sonstige Vereinbarungen",
                include: true,
                generator: generateOtherAgreements,
            },
            {
                title: "Schlussbestimmungen",
                include: true,
                generator: generateFinalProvisions,
            },
        ];

        // Assign section numbers and build sectionNumbers mapping
        let sectionNumber = 1;
        const sectionNumbers = {};

        for (const section of sections) {
            if (section.include) {
                sectionNumbers[section.title] = sectionNumber;
                sectionNumber++;
            }
        }

        // Generate document content
        const docContent = [
            ...generatePreamble(company, aoa),
        ];

        for (const section of sections) {
            if (section.include) {
                docContent.push(...section.generator(sha, sectionNumbers));
            }
        }

        // Create the document
        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "Normal",
                        name: "Normal",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            font: "Arial",
                            size: 22,
                        },
                        paragraph: {
                            spacing: { after: 200 },
                            indent: { left: 300 },
                        },
                    },
                    {
                        id: "Heading1",
                        name: "Heading 1",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 32,
                        },
                        paragraph: {
                            spacing: {
                                after: 300,
                            },
                            alignment: AlignmentType.CENTER,
                        },
                    },
                    {
                        id: "Heading2",
                        name: "Heading 2",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 28,
                        },
                        paragraph: {
                            spacing: {
                                before: 400,
                                after: 250,
                            },
                            indent: { left: 0 },
                        },
                    },
                    {
                        id: "Heading3",
                        name: "Heading 3",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 24,
                        },
                        paragraph: {
                            spacing: {
                                after: 250,
                            },
                            indent: { left: 0 },
                        },
                    },
                ],
            },
            numbering: {
                config: numberingConfigs,
            },
            sections: [{
                properties: {},
                children: docContent,
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Exportiert von Cenedril am ${formattedDateTime}`,
                                        font: "Arial",
                                        size: 22,
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
            }],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, "Gesellschaftervereinbarung.docx");
    };

    // Generate Preamble
    const generatePreamble = (company, aoa) => {
        return [
            new Paragraph({
                text: "Gesellschaftervereinbarung",
                heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
                text: "Präambel",
                heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
                text: `Die Gesellschaft ist eine nach dem Recht der Bundesrepublik Deutschland wirksam gegründete und bestehende Gesellschaft mit beschränkter Haftung. ${aoa.companyPurpose || '<Unternehmenszweck>'}`,
                style: "Normal",
            }),
            new Paragraph({
                text: `Die Gesellschaft wurde am ${company.foundingDate || '<Gründungsdatum>'} unter HRB ${company.hrbNumber || '<HRB-Nummer>'} gegründet. Die Bestandsgesellschafter sind die alleinigen Gesellschafter der Gesellschaft.`,
                style: "Normal",
            }),
            new Paragraph({
                text: `Die Parteien wollen ihre Rechte und Pflichten untereinander, neben den in der Satzung bestehenden Regelungen, im Rahmen dieser Gesellschaftervereinbarung ergänzen.`,
                style: "Normal",
            }),
            new Paragraph({
                text: `Dies vorausgeschickt, vereinbaren die Parteien was folgt:`,
                style: "Normal",
            }),
        ];
    };

    // Genenerate Section 1: Virtuelles Mitarbeiterbeteiligungsprogramm (Konditional)
    const generateVirtualEmployeeParticipationProgram = (sha, sectionNumbers) => {
        return [
            new Paragraph({
                text: "Virtuelles Mitarbeiterbeteiligungsprogramm",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Die Parteien vereinbaren hiermit die Einführung eines virtuellen Mitarbeiterbeteiligungsprogramms zur Beteiligung von Mitgliedern der Geschäftsführung, Mitarbeitern, Beiratsmitgliedern und/oder Beratern der Gesellschaft, gemäß den noch im Detail von den Gesellschaftern zu vereinbarenden Bestimmungen mit der Zustimmung einer einfachen Mehrheit der Stimmrechte der Gesellschafter („Gesellschaftermehrheit“). Die wirtschaftlichen Auswirkungen des virtuellen Mitarbeiterbeteiligungsprogramms in Höhe von bis zu ${sha.stockOptionPercentage} Prozent werden von den Gesellschaftern untereinander anteilig im Verhältnis zu den von ihnen gehaltenen Geschäftsanteilen an der Gesellschaft getragen. Die Parteien werden die Einzelheiten eines virtuellen Mitarbeiterbeteiligungsprogramms einvernehmlich auf Basis einer steueroptimierten Struktur für alle beteiligten Parteien mit Zustimmung der Investorenmehrheit festlegen.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

// Generate Section 2: Verfügung über Geschäftsanteile, Bezugsrecht
const generateDisposalOfShares = (sha, sectionNumbers) => {
    const hasCallOption = convertUIToStorage(sha.callOptionOnExit) === BOOLEAN_VALUES.YES;
    const preAcquisitionSectionNumber = sectionNumbers["Vorerwerbsrecht"] || 'X';
    const tagAlongSectionNumber = sectionNumbers["Mitveräußerungsrecht"] || 'X';
    const dragAlongSectionNumber = sectionNumbers["Drag-Along"] || 'X';
    const accessionSectionNumber = sectionNumbers["Beitritt zur Gesellschaftervereinbarung"] || 'X';
    const callOptionSectionNumber = sectionNumbers["Call-Option bei Ausscheiden eines Gründungsgesellschafters"] || 'X';

    // Helper function to build the references text
    const buildReferencesText = () => {
        const references = [
            hasCallOption ? `Ziffer ${callOptionSectionNumber} (Call-Option bei Ausscheiden eines Gründungsgesellschafters)` : null,
            `Ziffer ${preAcquisitionSectionNumber} (Vorerwerbsrecht)`,
            `Ziffer ${tagAlongSectionNumber} (Mitveräußerungsrecht)`,
            `Ziffer ${dragAlongSectionNumber} (Mitveräußerungspflicht)`,
            `Ziffer ${accessionSectionNumber} (Beitritt zur Gesellschaftervereinbarung)`
        ].filter(ref => ref !== null);

        return references.join(', ');
    };

    // Helper function for the second paragraph's text
    const buildSecondParagraphText = () => {
        if (hasCallOption) {
            return `Verfügt ein Gründungsgesellschafter innerhalb eines Zeitraums von ${sha.shareholderBindingYears} Jahren seit Beurkundung dieser Gesellschaftervereinbarung über die von ihm gehaltenen Geschäftsanteile der Gesellschaft, findet vorstehende Ziffer ${sectionNumbers["Verfügung über Geschäftsanteile, Bezugsrechte"]}.1, mit Ausnahme einer Verfügung gemäß nachstehender Ziffer ${callOptionSectionNumber} (Call-Option bei Ausscheiden eines Gründungsgesellschafters), keine Anwendung und sind die übrigen Gesellschafter nicht zur Erteilung ihrer Zustimmung zu der Verfügung verpflichtet.`;
        } else {
            return `Verfügt ein Gründungsgesellschafter innerhalb eines Zeitraums von ${sha.shareholderBindingYears} Jahren seit Beurkundung dieser Gesellschaftervereinbarung über die von ihm gehaltenen Geschäftsanteile der Gesellschaft, findet vorstehende Ziffer ${sectionNumbers["Verfügung über Geschäftsanteile, Bezugsrechte"]}.1 keine Anwendung und sind die übrigen Gesellschafter nicht zur Erteilung ihrer Zustimmung zu der Verfügung verpflichtet.`;
        }
    };

    return [
        new Paragraph({
            text: `Verfügung über Geschäftsanteile, Bezugsrecht`,
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: `Die Gesellschafter sind verpflichtet, ihre Zustimmung zu Verfügungen über Geschäftsanteile von der Gesellschaft gemäß den in der Satzung getroffenen Bestimmungen in der Gesellschafterversammlung zu erteilen, soweit ein Gesellschafter in Übereinstimmung mit den Bestimmungen dieser Gesellschaftervereinbarung, insbesondere mit ${buildReferencesText()}, über seine Geschäftsanteile verfügt.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: buildSecondParagraphText(),
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Die Gesellschafter sind zudem verpflichtet, ihre Zustimmung zu Verfügungen der Investoren über Geschäftsanteile der Gesellschaft an mit den Investoren jeweils im Sinne von § 15 AktG verbundene Unternehmen zu erteilen. Nachstehende Ziffern ${preAcquisitionSectionNumber} (Vorerwerbsrecht) und ${tagAlongSectionNumber} (Mitveräußerungsrecht) finden auf diese Verfügungen keine Anwendung.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Jeder Gesellschafter hat das Recht, aber nicht die Pflicht, an weiteren Kapitalerhöhungen der Gesellschaft pro rata entsprechend seiner Beteiligung am Stammkapital der Gesellschaft durch Übernahme eines entsprechend erforderlichen Prozentsatzes neu ausgegebener Geschäftsanteile an der Gesellschaft teilzunehmen, um seine anteilige Beteiligung am Stammkapital der Gesellschaft gemäß dieser Gesellschaftervereinbarung (in seiner jeweils gültigen Fassung) aufrechtzuerhalten ("Bezugsrecht").`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 3: Call-Option bei Ausscheiden eines Gründungsgesellschafters
const generateCallOptionOnDeparture = (sha, sectionNumbers) => {
    const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
    const accessionSectionNumber = sectionNumbers["Beitritt zur Gesellschaftervereinbarung"] || 'X';
    const preAcquisitionSectionNumber = sectionNumbers["Vorerwerbsrecht"] || 'X';
    const tagAlongSectionNumber = sectionNumbers["Mitveräußerungsrecht"] || 'X';

    return [
        new Paragraph({
            text: `Call-Option bei Ausscheiden eines Gründungsgesellschafters`,
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: 'Für den Fall, dass ein zwischen einem Gründungsgesellschafter oder einem Manager sowie der Gesellschaft geschlossener Anstellungs-, Dienst- oder Beratervertrag ("Manager-Vertrag") aus einem beliebigen Grund endet und damit der betreffende Gesellschafter oder Manager nicht mehr (i) hauptberuflich in der Unternehmensleitung der Gesellschaft oder (ii) als Berater der Gesellschaft tätig ist, ("Ausscheiden"), verpflichtet sich der betreffende Gesellschafter  ("Ausscheidender Gesellschafter") hiermit gegenüber den übrigen Gesellschaftern, sämtliche von ihm gehaltenen Geschäftsanteile an der Gesellschaft ("Call-Option-Geschäftsanteile") auf Verlangen der Mehrheit der Stimmrechte der übrigen Gesellschafter (wobei der Ausscheidende Gesellschafter kein Stimmrecht hat) in Textform an einen oder mehrere Gesellschafter, an einen oder mehrere Dritte(n) oder an die Gesellschaft (soweit die Gesellschaft über für den Erwerb eigener Anteile gemäß § 33 GmbH erforderliche Rücklagen verfügt) gemäß den folgenden Bestimmungen und, vorbehaltlich nachstehender Ziffer 3.2, ganz oder teilweise entsprechend den Vorgaben der Gesellschaftermehrheit zu verkaufen und abzutreten ("Call-Option"):',
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: 'Der Kaufpreis der Call-Option-Geschäftsanteile ("Call-Option-Kaufpreis") ist gemäß den nachfolgenden Regelungen und in Abhängigkeit von (i) dem Prozentwert der Call-Option-Geschäftsanteile, den der jeweils Ausscheidende Gesellschafter im Falle der Ausübung der Call-Option gemäß der zweiten Spalte der jeweiligen Tabelle in nachstehender Ziffer 3.1(b) zum Verkehrswert veräußert, ("Verkehrswert-Anteil") und (ii) dem Prozentwert der Call-Option-Geschäftsanteile, den der Ausscheidende Gesellschafter im Falle der Ausübung der Call-Option gemäß der dritten Spalte der jeweiligen Tabelle in nachstehender Ziffer 3.1(b) zum Buchwert veräußert ("Buchwert-Anteil"), zu berechnen.',
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Der Verkehrswert-Anteil und der Buchwert-Anteil berechnen sich für jeden Ausscheidenden Gesellschafter separat in Abhängigkeit von den seit Beurkundung dieser Gesellschaftervereinbarung ('Stichtag') bis zum Ausscheiden vergangenen Monate wie in Anlage 3.1(b) dargestellt.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "In den nachfolgenden Fällen setzt sich der Call-Option-Kaufpreis aus dem Verkehrswert des Verkehrswert-Anteils und dem Buchwert, höchstens jedoch 70% des Verkehrswertes, wenn dieser Betrag niedriger ist als der Buchwert, mindestens jedoch dem Nennwert, des Buchwert-Anteils der Call-Option-Geschäftsanteile zusammen:",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "der Manager-Vertrag wird durch den Ausscheidenden Gesellschafter oder den Manager aus wichtigem Grund gekündigt;",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "der Manager-Vertrag wird durch die Gesellschaft ordentlich gekündigt oder endet durch Zeitablauf gemäß den Bestimmungen des Manager-Vertrags; oder",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "der Ausscheidende Gesellschafter stirbt oder wird für einen Zeitraum von mehr als sechs (6) Monaten dauernd berufsunfähig im sozialversicherungsrechtlichen Sinne (gemeinsam 'Good Leaver').",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: 'Für den Fall, dass der Manager-Vertrag aus einem von dem Ausscheidenden Gesellschafter oder dem Manager zu vertretenden wichtigen Grund durch die Gesellschaft oder nach Vorliegen eines solchen Grundes durch den Ausscheidenden Gesellschafter oder dem Manager gekündigt oder durch den Ausscheidenden Gesellschafter oder dem Manager ordentlich gekündigt wird ("Bad Leaver"), entspricht der Call-Option-Kaufpreis – unabhängig von der Höhe des Verkehrswert-Anteils – regelmäßig dem Buchwert, höchstens jedoch 50 % des Verkehrswerts, wenn dieser Betrag niedriger ist als der Buchwert, mindestens jedoch dem Nennwert, der Call-Option-Geschäftsanteile.',
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: `Im Falle (i) eines Ausscheidens des Ausscheidenden Gesellschafters oder Managers als Good Leaver nach dem dritten (3.) Jahrestag des Stichtages oder (ii) der Veräußerung sämtlicher Geschäftsanteile an der Gesellschaft vor dem dritten (3.) Jahrestag des Stichtages besteht keine Verpflichtung des Ausscheidenden Gesellschafters zum Verkauf und zur Übertragung der Call-Option-Geschäftsanteile gemäß vorstehender Ziffer ${sectionNumbers["Call-Option bei Ausscheiden eines Gründungsgesellschafters"]}.1 und stehen die Call-Option-Geschäftsanteile vollständig dem Ausscheidenden Gesellschafter zu.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Der Verkehrswert der Call-Option-Geschäftsanteile ist unter Berücksichtigung der vom Institut der Wirtschaftsprüfer in Deutschland e.V. empfohlenen jeweils geltenden Grundsätze zur Unternehmensbewertung von dem Abschlussprüfer der Gesellschaft zu ermitteln, es sei denn, die Gesellschafter verzichten auf diese Ermittlung. Der Buchwert der Call-Option-Geschäftsanteile entspricht ihrem Anteil am Eigenkapital der Gesellschaft im Sinne von § 266 Absatz 3 A. HGB (ohne Berücksichtigung etwaiger Zuführungen in die Kapitalrücklage durch die Investoren) zum Zeitpunkt des Ausscheidens. Für den Fall, dass Streitigkeiten zwischen den Gesellschaftern im Hinblick auf den Verkehrswert und/oder den Buchwert der Call-Option-Geschäftsanteile entstehen, gilt nachstehende Ziffer ${preAcquisitionSectionNumber}.2 insoweit entsprechend.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Sollte im Einzelfall rechtskräftig festgestellt werden, dass eine in dieser Ziffer für Bemessung des Call-Option-Kaufpreises enthaltene Regelung nichtig oder unzumutbar ist, gilt stattdessen der jeweils niedrigste zulässige Kaufpreis als Call-Option-Kaufpreis vereinbart.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Call-Option kann nur in einem Zeitraum von sechs (6) Wochen nach dem Ausscheiden auf Verlangen der Investoren in Textform gegenüber dem Ausscheidenden Gesellschafter ausgeübt werden (Call-Option Ausübung).",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Die in vorstehender Ziffer ${sectionNumbers["Call-Option bei Ausscheiden eines Gründungsgesellschafters"]}.1 erklärte Verkaufs- und Abtretungsverpflichtung des Ausscheidenden Gesellschafters gilt ausdrücklich nach dessen Versterben weiter. Etwaigen Rechtsnachfolgern des Ausscheidenden Gesellschafters sind entsprechend nachstehender Ziffer ${accessionSectionNumber} (Beitritt zur Gesellschaftervereinbarung) insbesondere die Verpflichtungen aus dieser Ziffer ${sectionNumbers["Call-Option bei Ausscheiden eines Gründungsgesellschafters"]} aufzuerlegen.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Nachstehende Ziffern ${preAcquisitionSectionNumber} (Vorerwerbsrecht) und ${tagAlongSectionNumber} (Mitveräußerungsrecht) finden auf Übertragungen von Call-Option-Geschäftsanteilen gemäß den Regelungen dieser Ziffer keine Anwendung.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Wenn und soweit die Call-Option-Geschäftsanteile gemäß den vorgenannten Bestimmungen ganz oder teilweise entsprechend den Vorgaben der Gesellschafter an die Gesellschaft verkauft und abgetreten werden, so ist der Call-Option-Kaufpreis in drei (3) gleichen Raten durch die Gesellschaft zu zahlen. Die erste Rate wird einen (1) Monat nach Abtretung der Call-Option-Geschäftsanteile zur Zahlung fällig. Jede weitere Rate ist drei (3) Monate nach Fälligkeit der vorhergehenden Rate fällig. Jede Rate ist jährlich mit zwei (2) Prozentpunkten über dem Basiszinssatz ab dem Datum der Call-Option Ausübung zu verzinsen. Die Zinsen werden gleichzeitig mit der entsprechenden Rate gezahlt. Die Gesellschaft hat, mit Zustimmung der Gesellschaftermehrheit, das Recht, den Call-Option-Kaufpreis jederzeit vollständig oder teilweise vor dem Fälligkeitsdatum zu zahlen. Vom Tag der Call-Option Ausübung bis zum Zeitpunkt der vollständigen Zahlung des Call-Option-Kaufpreises gemäß dieser Ziffer ruhen die den Call-Option Geschäftsanteilen zuzuordnenden Stimmrechte.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 4: Vorerwerbsrecht
const generatePreAcquisitionRight = (sha, sectionNumbers) => {
    const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
    const accessionSectionNumber = sectionNumbers["Beitritt zur Gesellschaftervereinbarung"] || 'X';

    return [
        new Paragraph({
            text: `Vorerwerbsrecht`,
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Für den Fall, dass ein Gesellschafter ('Veräußerungswilliger Gesellschafter') beabsichtigt, seine Geschäftsanteile an der Gesellschaft ganz oder teilweise an einen Dritten (einschließlich eines anderen Gesellschafters und der Gesellschaft) ('Erwerber') zu veräußern ('Veräußerer-Geschäftsanteile'), sind die anderen Gesellschafter der Gesellschaft jeweils anteilig im Verhältnis ihrer Beteiligung am Stammkapital an der Gesellschaft untereinander zum Erwerb der Veräußerer-Geschäftsanteile nach Maßgabe der folgenden Bestimmungen berechtigt ('Vorerwerbsrecht'):",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Der Veräußerungswillige Gesellschafter hat den übrigen Gesellschaftern (einschließlich des Erwerbers, wenn dieser ein Gesellschafter ist) ('Vorerwerbsberechtigte') sowie der Geschäftsführung der Gesellschaft folgende Angaben in Textform mitzuteilen ('Veräußerungsanzeige'):",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Name / Firma und Sitz bzw. Adresse des Veräußerungswilligen Gesellschafters,",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "Name / Firma und Sitz bzw. Adresse des Erwerbers,",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "Kaufpreis bzw. andere Gegenleistung für die Veräußerer-Geschäftsanteile,",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "Fälligkeit des Kaufpreises bzw. der sonstigen Gegenleistung,",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "Anzahl und Nominalbeträge der Veräußerer-Geschäftsanteile, deren Veräußerung beabsichtigt ist, und",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "ggf. Gewährleistungen und Garantien, die der Veräußerungswillige Gesellschafter übernimmt.",
            numbering: { reference: alphabeticalNumberingReference, level: 1 },
        }),
        new Paragraph({
            text: "Jeder Vorerwerbsberechtigte kann sein anteiliges Vorerwerbsrecht nur ganz und innerhalb eines (1) Monats ab Zugang der Veräußerungsanzeige ('Ausübungsfrist') sowie nur durch Erklärung in Textform gegenüber der Geschäftsführung der Gesellschaft ausüben.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Nach Ablauf der Ausübungsfrist hat die Geschäftsführung der Gesellschaft unverzüglich dem Veräußerungswilligen Gesellschafter und den Vorerwerbsberechtigten das Ergebnis der Ausübung der jeweiligen Vorerwerbsrechte sowie die (vorläufige) Aufteilung der Veräußerer-Geschäftsanteile unter den ankaufswilligen Vorerwerbsberechtigten in Textform mitzuteilen ('Ausübungsmitteilung').",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Übt ein Vorerwerbsberechtigter sein Recht auf anteiligen Erwerb der Veräußerer-Geschäftsanteile nicht innerhalb der Ausübungsfrist gemäß vorstehender Ziffer 4.1(b) aus, haben die übrigen Vorerwerbsberechtigten, jeweils anteilig im Verhältnis ihrer Beteiligung am Stammkapital der Gesellschaft (ohne Berücksichtigung der Geschäftsanteile der ihre Vorerwerbsrechte nicht ausübenden Gesellschafter), das weitere Recht, den auf diesen Vorkaufsberechtigten entfallenden Teil der Veräußerer-Geschäftsanteile zu erwerben ('Erweitertes Vorerwerbsrecht'). Das Erweiterte Vorerwerbsrecht kann nur vollständig und innerhalb von 2 (zwei) Wochen nach Zugang der Ausübungsmitteilung bei den übrigen Vorerwerbsberechtigten ('Erweiterte Ausübungsfrist') durch Erklärung in Textform gegenüber der Geschäftsführung der Gesellschaft ausgeübt werden. Vorstehende Ziffer 4.1(c) gilt entsprechend.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Im Falle der form- und fristgerechten Ausübung sämtlicher Vorerwerbsrechte gemäß vorstehenden Ziffern 4.1(a) bis 4.1(d) sind der Veräußerungswillige Gesellschafter und die ankaufwilligen Vorerwerbsberechtigten verpflichtet, unverzüglich miteinander einen notariellen Geschäftsanteilskauf- und Übertragungsvertrag über sämtliche Veräußerer-Geschäftsanteile entsprechend der in der bzw. den Ausübungsmitteilung(en) aufgeführten Aufteilung sowie zu dem in der Veräußerungsanzeige genannten Kaufpreis und zu den dort aufgeführten sonstigen Bedingungen abzuschließen, soweit die sonstigen Bedingungen (insbesondere Garantien, Haftungsbegrenzung, Verjährung) üblichen Vertragsstandards entsprechen.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: `Für den Fall, dass die Vorerwerbsrechte gemäß vorstehenden Ziffern 4.1(a) bis 4.1(d) nicht form- oder fristgerecht oder nicht vollständig für sämtliche Veräußerer-Geschäftsanteile und, im Falle der Ausübung eines Mitveräußerungsrechts gemäß nachstehender Ziffer 5.1, für sämtliche Mitveräußerer-Geschäftsanteile (wie in nachstehender Ziffer 5.1 definiert) ausgeübt worden sind, ist der Veräußerungswillige Gesellschafter berechtigt, unter Beachtung von nachstehenden Ziffern ${accessionSectionNumber} (Beitritt zur Gesellschaftervereinbarung) sämtliche Veräußerer-Geschäftsanteile innerhalb einer Frist von weiteren zwei (2) Monaten nach Ablauf der Erweiterten Ausübungsfrist zu den in der Veräußerungsanzeige genannten Bedingungen an den Erwerber zu veräußern.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 5: Mitveräußerungsrecht
const generateTagAlong = (sha, sectionNumbers) => {
    const preAcquisitionSectionNumber = sectionNumbers["Vorerwerbsrecht"] || 'X';
    const callOptionSectionNumber = sectionNumbers["Call-Option bei Ausscheiden eines Gründungsgesellschafters"] || 'X';
    const accessionSectionNumber = sectionNumbers["Beitritt zur Gesellschaftervereinbarung"] || 'X';

    return [
        new Paragraph({
            text: `Mitveräußerungsrecht`,
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Beabsichtigt ein Veräußerungswilliger Gesellschafter die Veräußerung seiner Veräußerer-Geschäftsanteile, kann jeder Vorerwerbsberechtigte nach Erhalt der Veräußerungsanzeige unter Verzicht auf sein jeweiliges Vorerwerbsrecht ('Mitveräußerer') verlangen, dass der Veräußerungswillige Gesellschafter auch die von diesem Mitveräußerer gehaltenen Geschäftsanteile an den Erwerber ganz oder teilweise ('Mitveräußerer-Geschäftsanteile') zu den in der Veräußerungsanzeige genannten Bedingungen mitverkauft und überträgt ('Mitveräußerungsrecht'). Das Mitveräußerungsrecht ist innerhalb von zwei (2) Wochen nach Zugang der Veräußerungsanzeige bei dem Mitveräußerer und nur durch Erklärung in Textform gegenüber der Geschäftsführung der Gesellschaft auszuüben. Die Geschäftsführung der Gesellschaft hat dem Veräußerungswilligen Gesellschafter sowie den übrigen Gesellschaftern die Ausübung eines Mitveräußerungsrechts unverzüglich in Textform mitzuteilen. Durch die Ausübung des Mitveräußerungsrechts verzichtet der Mitveräußerer auf die Ausübung seines Vorerwerbsrechts.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Vorstehende Ziffern ${preAcquisitionSectionNumber}.1 und ${preAcquisitionSectionNumber}.2 finden im Hinblick auf die Mitveräußerer-Geschäftsanteile entsprechende Anwendung.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Ist der Veräußerungswillige Gesellschafter unter Beteiligung der Mitveräußerer gemäß vorstehenden Ziffern 5.3 und 5.4 zu der Veräußerung von Veräußerer-Geschäftsanteilen und Mitveräußerer-Geschäftsanteilen an den Erwerber berechtigt und liegen die Voraussetzungen der vorstehenden Ziffer ${accessionSectionNumber} (Beitritt zur Gesellschaftervereinbarung) für diese Veräußerung vor, sind Übertragungen von Geschäftsanteilen gemäß dieser Ziffer 5 nach vorstehender Ziffer ${sectionNumbers["Verfügung über Geschäftsanteile, Bezugsrechte"]}.1, vorbehaltlich vorstehender Ziffer ${sectionNumbers["Verfügung über Geschäftsanteile, Bezugsrechte"]}.2, zu genehmigen.`,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 6: Mitveräußerungspflicht
const generateDragAlong = (sha, sectionNumbers) => {
    return [
        new Paragraph({
            text: `Mitveräußerungspflicht`,
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Alle Gesellschafter sind verpflichtet, sämtliche von ihnen gehaltenen Geschäftsanteile an der Gesellschaft an einen oder mehrere Dritte(n) zu verkaufen und zu übertragen ('Gesamtveräußerung'), wenn von den Gesellschaftern mit der Mehrheit von 75 % (fünfundsiebzig Prozent) der abgegebenen Stimmen die Veräußerung sämtlicher Geschäftsanteile beschlossen wird.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 7: Kontroll- und Informationsrechte
const generateControlAndInformationRights = (sha, sectionNumbers) => {
    const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
    const finalProvisionsSectionNumber = sectionNumbers["Schlussbestimmungen"] || 'X';
    return [
        new Paragraph({
            text: "Kontroll- und Informationsrechte",
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Darüber hinaus stehen den Gesellschaftern die folgenden Informationsrechte zu:",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Gesellschafter erhalten von der Gesellschaft monatlich, jeweils bis zum Ablauf des 20. (zwanzigsten) Kalendertages des Folgemonats, für den Vormonat einen Statusbericht in der von den Investoren geforderten Form sowie bis spätestens zum 15. November eines jeden Geschäftsjahres einen Geschäftsplan in der von den Gesellschaftern geforderten Form für das Folgejahr. Dem Statusbericht sind zusätzlich für den jeweiligen Berichtsmonat eine betriebswirtschaftliche Auswertung (BWA), eine Summen- und Saldenliste sowie eine Kreditoren- und Debitorenliste beizufügen.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: `Die Gesellschaft ist verpflichtet, die Gesellschafter über Änderungen der Zwölf-Monats-Planung sowie eines gemäß vorstehender Ziffer ${sectionNumbers["Kontroll- und Informationsrechte"]}.1(a) übergebenen Geschäftsplans unverzüglich schriftlich zu informieren. Darüber hinaus ist die Gesellschaft verpflichtet, die Zwölf-Monats-Planung und/oder den Geschäftsplan unverzüglich anzupassen und die neue Version den Gesellschaftern unverzüglich sowohl schriftlich als auch als Datensatz (Textdokumente als Word-Datei und Tabellen als Excel-Datei) zu überlassen.`,
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: `Die Gesellschaft hat innerhalb von drei (3) Monaten nach Ablauf eines jeden Geschäftsjahres seinen Jahresabschluss nach den Bestimmungen für große Kapitalgesellschaften zu erstellen, durch einen Wirtschaftsprüfer prüfen zu lassen und nebst Prüfungsbericht und Bestätigungsvermerk unverzüglich nach Erstellung des Prüfungsberichts an die Gesellschafter zu übermitteln. Dies gilt auch für die jährliche ESG-Berichterstattung gemäß Ziffer ${finalProvisionsSectionNumber}.3, welche die Gesellschaft an die Gesellschafter zu den wichtigsten nachteiligen Nachhaltigkeitsauswirkungen (sogenanntes PAI-Statement) zu übermitteln hat.`,
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Die Gesellschaft hat die Gesellschafter unverzüglich über alle Maßnahmen, die über den Rahmen des üblichen Geschäftsverkehrs hinausgehen, schriftlich zu informieren. Insbesondere wird die Gesellschaft die Gesellschafter unverzüglich informieren, wenn die Gesellschaft davon Kenntnis erhält, dass ein Wechsel der Kontrolle über die Gesellschaft durch Erwerb einer Mehrheitsbeteiligung im Sinne von § 16 AktG oder einer anderweitigen Kontrollmöglichkeit gemäß § 290 Absatz 2 HGB bevorsteht oder eingetreten ist. Ferner wird die Gesellschaft die Gesellschafter unverzüglich über geplante Umstrukturierungen, Umwandlungsmaßnahmen oder den Abschluss von Unternehmensverträgen im Sinne der §§ 291 ff. AktG informieren. Die Gesellschaft wird die Gesellschafter im Falle eines geplanten Börsengangs insbesondere über einen für die Gesellschaft gestellten Antrag auf Zulassung zum Handel an einer nationalen, internationalen oder transnationalen Börse informieren.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Die Gesellschaft hat die Gesellschafter unverzüglich schriftlich über alle Vorfälle zu informieren, die eine wesentliche Verschlechterung der wirtschaftlichen Situation von der Gesellschaft bewirken können.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Die Gesellschafter sind berechtigt, jederzeit sämtliche, auf die Erfüllung der unter dieser Ziffer 9 genannten Informationsrechte bezogene Unterlagen von der Gesellschaft einzusehen und Zugang zu den Geschäftsräumen und dem Management der Gesellschaft zu erhalten. Die Gesellschafter können sich bei der Wahrnehmung ihrer Informations- und Kontrollrechte Dritter bedienen.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Die Gesellschaft wird im Rahmen der vertraglichen Informations- und Berichtspflicht abzugebende Meldungen, die Übersendung von Unterlagen und Informationen sowie den gesamten sonstigen Schriftverkehr auf elektronischem Wege über eine gesicherte Internet-Verbindung vornehmen, sofern die Gesellschafter dies verlangen.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Die von der Gesellschaft geschuldeten Informationen, Unterlagen und Auskünfte sind sowohl schriftlich als auch in Form eines Datensatzes (Textdokumente in Word, Tabellen in Excel) zur Verfügung zu stellen. Für von Dritten stammende Unterlagen gilt dies nur, wenn sie der Gesellschaft selbst als Datensatz vorliegen.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Die Parteien sind verpflichtet, darauf hinzuwirken, dass die Geschäftsführer der Gesellschaft die vorstehenden Informationsrechte von den Gesellschaftern zu jeder Zeit in Übereinstimmung mit den hier getroffenen Regelungen erfüllen. Sie sind insbesondere verpflichtet, gegebenenfalls in Gesellschafterversammlungen der Gesellschaft für einen entsprechenden Gesellschafterversammlungsbeschluss zu stimmen, aufgrund dessen den Geschäftsführern der Gesellschaft die Anweisung erteilt werden soll, die Informationsrechte der Gesellschafter zu erfüllen.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 8: Ehelicher Güterstand
const generateMatrimonialPropertyRegime = (sha, sectionNumbers) => {
    const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
    return [
        new Paragraph({
            text: "Ehelicher Güterstand",
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Leben verheiratete Gesellschafter im Güterstand",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "der Zugewinngemeinschaft, ist durch Ehevertrag zu vereinbaren dass, der Gesellschafter den Beschränkungen des § 1365 BGB nicht unterliegt.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "(i) der Zugewinngemeinschaft und ist nicht vereinbart, dass seine Geschäftsanteile an der Gesellschaft dann, wenn dieser Güterstand auf andere Weise als durch den Tod eines Ehegatten beendet wird, nicht dem Zugewinnausgleich unterliegen, oder (ii) der Gütergemeinschaft, und ist die Beteiligung an der Gesellschaft im Ehevertrag nicht zum Vorbehaltsgut des Gesellschafters erklärt und dies im Güterrechtsregister eingetragen, so können die Gesellschafter die Geschäftsanteile des betreffenden Gesellschafter nach den Bestimmungen des Gesellschaftsvertrages einziehen, falls (x) die Ehe des betreffenden Gesellschafters geschieden wird und (y) die Geschäftsanteile des Gesellschafters an der Gesellschaft aus diesem Grund freiwillig oder zwangsweise an die Ehegattin des Gesellschafters übertragen werden oder die Zwangsvollstreckung in diese Geschäftsanteile an der Gesellschaft betrieben wird. Die Gesellschafter stimmen dieser Einziehung bereits hiermit zu.",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
    ];
};

// Generate Section 9: Laufzeit, Kündigung
const generateTermAndTermination = (sha, sectionNumbers) => {
    return [
        new Paragraph({
            text: "Laufzeit, Kündigung",
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Die Gesellschaftervereinbarung endet, wenn nur noch einer der Gesellschafter an der Gesellschaft beteiligt ist, spätestens jedoch nach Ablauf von 15 (fünfzehn) Jahren, gerechnet ab dem Tag der Beurkundung dieser Gesellschaftervereinbarung. Scheidet ein Gesellschafter aus der Gesellschaft, gleich aus welchem Rechtsgrund, aus, enden, soweit in dieser Gesellschaftervereinbarung nicht ausdrücklich anders geregelt, seine Rechte und Pflichten aus dieser Gesellschaftervereinbarung mit Wirksamwerden des Ausscheidens als Gesellschafter der Gesellschaft.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Gesellschaftervereinbarung kann bis zum Ende ihrer Laufzeit nicht ordentlich gekündigt werden. Das Recht der Parteien zur Kündigung aus wichtigem Grund bleibt hiervon unberührt.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 10: Beitritt zur Gesellschaftervereinbarung
const generateAccessionToShareholdersAgreement = (sha, sectionNumbers) => {
    const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
    const callOptionSectionNumber = sectionNumbers["Call-Option bei Ausscheiden eines Gründungsgesellschafters"] || 'X';
    const preAcquisitionSectionNumber = sectionNumbers["Vorerwerbsrecht"] || 'X';
    const tagAlongSectionNumber = sectionNumbers["Mitveräußerungsrecht"] || 'X';
    const dragAlongSectionNumber = sectionNumbers["Drag-Along"] || 'X';
    return [
        new Paragraph({
            text: "Beitritt zur Gesellschaftervereinbarung",
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Die Gesellschafter verpflichten sich untereinander, ihre etwaigen Rechtsnachfolger sowie gegebenenfalls weitere, in die Gesellschaft als Gesellschafter eintretende Parteien",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "zum Eintritt in die Rechte und Pflichten dieser Gesellschaftervereinbarung zu verpflichten, oder – falls der Beitritt von Rechtsnachfolgern oder weiteren Gesellschaftern zu dieser Gesellschaftervereinbarung nicht gewollt oder nicht durchsetzbar sein sollte –",
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: `die Wirksamkeit der Übertragung an einen Rechtsnachfolger oder eine Ausgabe neuer Geschäftsanteile an der Gesellschaft an einen neuen Gesellschafter von der Fortdauer der in der Satzung von der Gesellschaft sowie der in dieser Gesellschaftervereinbarung vorgesehenen Erlöspräferenzen und Verwässerungsschutzrechte der Investoren sowie dem Abschluss einer Gesellschaftervereinbarung zwischen den Parteien und dem Rechtsnachfolger oder dem neu eintretenden Gesellschafter abhängig zu machen, den Investoren die in den vorstehenden Ziffern ${callOptionSectionNumber} (Call-Option bei Ausscheiden eines Gründungsgesellschafters), ${preAcquisitionSectionNumber} (Vorerwerbsrecht), ${tagAlongSectionNumber} (Mitveräußerungsrecht) und ${dragAlongSectionNumber} (Mitveräußerungspflicht) geregelten Rechte in wenigstens dem dort vorgesehenen Umfang einräumt.`,
            numbering: { reference: alphabeticalNumberingReference, level: 0 },
        }),
        new Paragraph({
            text: "Für den Fall, dass ein Gesellschafter seine Geschäftsanteile an der Gesellschaft an ein mit dem jeweiligen Gesellschafter verbundenes Unternehmen im Sinne der §§ 15 ff. AktG überträgt, bieten die jeweils übrigen Parteien bereits hiermit unwiderruflich dem jeweiligen verbundenen Unternehmen den Beitritt zu dieser Gesellschaftervereinbarung in der im Zeitpunkt des Beitritts geltenden Fassung an, wobei das jeweilige verbundene Unternehmen vollumfänglich in die Rechte und Pflichten des übertragenden Gesellschafters eintritt. Die Annahme des vorstehenden Angebots durch das jeweilige verbundene Unternehmen ist in notarieller Form gegenüber der Gesellschaft zu erklären. Die übrigen Parteien ermächtigen die Gesellschaft hiermit unwiderruflich zur Entgegennahme der Annahmeerklärung (unwiderrufliche Empfangsvollmacht).",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 11: Sonstige Vereinbarungen
const generateOtherAgreements = (sha, sectionNumbers) => {
    return [
        new Paragraph({
            text: "Sonstige Vereinbarungen",
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Die Parteien stimmen darin überein, dass sämtliche Rechte und Pflichten der Parteien aus dieser Gesellschaftervereinbarung im Verhältnis der Parteien untereinander bereits mit Abschluss dieser Gesellschaftervereinbarung gelten sollen.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Im Verhältnis der Parteien untereinander gehen die Regelungen dieser Gesellschaftervereinbarung den Bestimmungen der Satzung, der Geschäftsordnungen für die Geschäftsführung der Gesellschaft sowie allen übrigen Vereinbarungen zwischen den Parteien vor.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Gesellschafter stimmen darin überein, dass mit Abschluss dieser Gesellschaftervereinbarung zwischen ihnen eine Innen-Gesellschaft bürgerlichen Rechts (§§ 705 ff BGB) ohne eigenes Gesellschaftsvermögen zustande kommt, und dass diese Gesellschaftervereinbarung deren Gesellschaftsvertrag darstellt. Die Gesellschafter stellen klar, dass die Geschäftsanteile an der Gesellschaft weiterhin in ihrem jeweiligen Alleineigentum stehen und keine Gesamthand an den Geschäftsanteilen an der Gesellschaft begründet wird.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Für den Fall, dass im Zusammenhang mit der Durchführung einer Kapitalmaßnahme die Gesellschafter mit einer Mehrheit von mindestens 95,0 % (fünfundneunzig Prozent) der abgegebenen Stimmen dem Abschluss einer von der Geschäftsführung der Gesellschaft vorgelegten Änderungs- und/oder Ergänzungsvereinbarung zu dieser Gesellschaftervereinbarung zugestimmt haben, sind sämtliche Parteien verpflichtet, die entsprechende Änderungs- und/oder Ergänzungsvereinbarung zu dieser Gesellschaftervereinbarung abzuschließen und entsprechende Willenserklärungen formwirksam abzugeben.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Parteien verpflichten sich, jederzeit und ohne besondere Gegenleistung alle Erklärungen in gehöriger Form abzugeben und entgegenzunehmen, in der Gesellschafterversammlung der Gesellschaft sämtliche Beschlüsse zu fassen sowie sämtliche Maßnahmen zu ergreifen, die erforderlich sind, um die in dieser Gesellschaftervereinbarung niedergelegten Regelungen wirksam durchzuführen und zu erfüllen.",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Bestimmungen dieser Gesellschaftervereinbarung gelten sinngemäß entsprechend im Falle einer Umwandlung von Gesellschaft (Formwechsel, Verschmelzung, Spaltung).",
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
    ];
};

// Generate Section 12: Schlussbestimmungen
const generateFinalProvisions = (sha, sectionNumbers) => {
    return [
        new Paragraph({
            text: "Schlussbestimmungen",
            heading: HeadingLevel.HEADING_2,
            numbering: { reference: "decimalNumbering", level: 0 },
        }),
        new Paragraph({
            text: "Abtretung von Rechten",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Parteien sind nicht berechtigt, Ansprüche oder sonstige Rechte aus dieser Gesellschaftervereinbarung ohne Zustimmung der jeweils anderen Parteien zu übertragen, zu verpfänden oder in sonstiger Weise zu belasten.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Kostentragung",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Soweit diese Gesellschaftervereinbarung nicht ausdrücklich etwas anderes bestimmt, werden sämtliche Gebühren, Abgaben, Rechnungsprüfungskosten und sonstige Kosten im Zusammenhang mit dem Abschluss, der Änderung dieser Gesellschaftervereinbarung von der Gesellschaft getragen.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Schriftform",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: `Änderungen dieser Gesellschaftervereinbarung, einschließlich ihrer Anlagen, bedürfen, soweit gesetzlich keine strengere Form vorgesehen ist, der Schriftform. Dies gilt auch für die Änderung dieser Ziffer ${sectionNumbers["Schlussbestimmungen"]}.3.`,
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Gerichtsstand",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Sämtliche Rechtsstreitigkeiten zwischen den Parteien aus und im Zusammenhang mit dieser Gesellschaftervereinbarung oder ihren Anlagen sollen von den ordentlichen deutschen Gerichten entschieden werden. Ausschließlicher Gerichtsstand ist, soweit gesetzlich zulässig, der Sitz der Gesellschaft.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Bankarbeitstage und Textform",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "'Bankarbeitstage' sind Handelstage im Sinne von § 47 WpHG.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "'Textform' ist die gesetzliche Form gemäß § 126b BGB, d.h. insbesondere Brief, Telefax oder Email.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Mitteilungen",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Nach dieser Gesellschaftervereinbarung notwendige Aufforderungen, Mitteilungen, abzugebende Erklärungen oder andere Nachrichten sind jeweils an die Parteien zu richten oder an diejenige Person oder Anschrift, die jeweils von einer Partei bestimmt wird, es sei denn, in dieser Gesellschaftervereinbarung ist ausdrücklich etwas anderes geregelt.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Vertraulichkeit",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Die Parteien verpflichten sich, den Inhalt dieser Gesellschaftervereinbarung einschließlich sämtlicher Anlagen gegenüber Dritten geheim und vertraulich zu behandeln, es sei denn, es handelt sich um einen zur Berufsverschwiegenheit Verpflichteten oder die betreffenden Tatsachen sind öffentlich bekannt oder ihre öffentliche Bekanntmachung ist gesetzlich vorgeschrieben. In diesem Fall sind die Parteien verpflichtet, sich gegenseitig im Voraus zu unterrichten und die öffentlichen Bekanntmachungen auf den gesetzlich oder behördlicherseits vorgeschriebenen Inhalt zu beschränken.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Bekanntmachungen",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Jegliche öffentliche Bekanntmachung über die Beteiligung der Parteien an Gesellschaft und an dieser Gesellschaftervereinbarung ist zwischen den Parteien zuvor gemeinsam abzustimmen.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
        new Paragraph({
            text: "Salvatorische Klausel",
            heading: HeadingLevel.HEADING_3,
            numbering: { reference: "decimalNumbering", level: 1 },
        }),
        new Paragraph({
            text: "Sollte eine Bestimmung dieser Gesellschaftervereinbarung ganz oder teilweise nichtig, unwirksam oder nicht durchsetzbar sein oder werden, oder sollte eine an sich notwendige Regelung nicht enthalten sein, werden die Wirksamkeit und die Durchsetzbarkeit aller übrigen Bestimmungen dieser Gesellschaftervereinbarung nicht berührt. Anstelle der nichtigen, unwirksamen oder nicht durchsetzbaren Bestimmung oder zur Ausfüllung der Regelungslücke werden die Parteien eine rechtlich zulässige Regelung vereinbaren, die so weit wie möglich dem entspricht, was die Parteien gewollt haben oder nach dem Sinn und Zweck dieser Gesellschaftervereinbarung vereinbart haben würden, wenn sie die Unwirksamkeit oder die Regelungslücke erkannt hätten. Beruht die Nichtigkeit einer Bestimmung auf einem darin festgelegten Maß der Leistung oder der Zeit (Frist oder Termin), so werden die Parteien eine Bestimmung mit einem dem ursprünglichen Maß am nächsten kommenden rechtlich zulässigen Maß vereinbaren. Es ist der ausdrückliche Wille der Parteien, dass diese salvatorische Klausel keine bloße Beweislastumkehr zur Folge hat, sondern § 139 BGB insgesamt abbedungen ist.",
            numbering: { reference: "decimalNumbering", level: 2 },
        }),
    ];
};

    // Finally, the component renders the button that triggers the document generation
    return (
        <button
            onClick={generateShareholdersAgreement}
            className="text-white px-4 py-2 rounded hover:text-white bg-[#2A9D8F] hover:bg-[#238276] flex items-center text-sm"
        >
            <img src={process.env.PUBLIC_URL + '/ux_icons/download.svg'} alt="icon" className="h-5 w-5 mr-2 fill-current text-white" />
            Gesellschaftervereinbarung (deutsch)
        </button>
    );
};

export default ShareholdersAgreementGeneratorGerman;
