import React, { useEffect, useState } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';

export default function RegistrationSuccess() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const paymentStatus = searchParams.get('payment_status');
    const token = localStorage.getItem('authToken');
    const retryPayment = searchParams.get('retry');
    
    if (retryPayment === 'true' && token) {
      handleRetryPayment();
      return;
    }
    
    if (paymentStatus === 'failed' || paymentStatus === 'canceled') {
      if (token) {
        axios.post('/api/v1/stripe/handle_payment_cancellation', null, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          localStorage.setItem('paymentPending', 'true');
          setStatus('failed');
        })
        .catch(error => {
          console.error('Cancellation error:', error);
          if (error.response?.status === 401) {
            // Token is invalid
            localStorage.removeItem('authToken');
            localStorage.setItem('paymentPending', 'true');
            navigate('/login', { 
              state: { returnTo: '/registration-success?retry=true' }
            });
          } else {
            setStatus('failed');
          }
        });
      } else {
        localStorage.setItem('paymentPending', 'true');
        navigate('/login', { 
          state: { returnTo: '/registration-success?retry=true' }
        });
      }
    } else if (paymentStatus === 'succeeded') {
      localStorage.removeItem('authToken');
      localStorage.removeItem('paymentPending');
      setStatus('success');
    } else {
      setStatus('pending');
    }
  }, [searchParams, navigate]);

  const handleRetryPayment = async () => {
    const token = localStorage.getItem('authToken');
    
    if (!token) {
      localStorage.setItem('paymentPending', 'true');
      navigate('/login', { 
        state: { returnTo: '/registration-success?retry=true' }
      });
      return;
    }

    try {
      const response = await axios.post(
        '/api/v1/stripe/create_checkout_session',
        {
          success_url: `${window.location.origin}/registration-success?payment_status=succeeded&token=${token}`,
          cancel_url: `${window.location.origin}/registration-success?payment_status=failed&token=${token}`,
          tier: 'founder'
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      window.location.href = response.data.url;
    } catch (err) {
      if (err.response?.status === 401) {
        localStorage.setItem('paymentPending', 'true');
        navigate('/login', { 
          state: { returnTo: '/registration-success?retry=true' }
        });
      } else {
        setError(t('errors.payment_retry_failed'));
      }
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'success':
        return (
          <>
            <h2 className="text-2xl font-bold mb-4">{t('registration_successful')}</h2>
            <p className="text-gray-600 mb-6">
              {t('registration_success_message')}
            </p>
            <Link 
              to="/login" 
              className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color btn-link"
            >
              {t('go_to_login')}
            </Link>
          </>
        );
      
      case 'failed':
        return (
          <>
            <h2 className="text-2xl font-bold mb-4">{t('payment_failed')}</h2>
            <p className="text-gray-600 mb-6">
              {t('payment_failed_message')}
            </p>
            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md">
                {error}
              </div>
            )}
            <button
              onClick={handleRetryPayment}
              className="block w-full rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color"
            >
              {t('retry_payment')}
            </button>
          </>
        );
      
      default:
        return (
          <>
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-color mx-auto mb-4" />
            <p className="text-gray-600">
              {t('processing_registration')}
            </p>
          </>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {renderContent()}
      </div>
    </div>
  );
}