import React from 'react';
import PropTypes from 'prop-types';
import './Checkmark.css';

const Checkmark = ({ id, name, label, description, checked, onChange }) => {
  return (
    <div className="checkmark-item">
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={id} className="font-medium text-gray-900">
          {label}
        </label>
        {description && (
          <p id={`${id}-description`} className="text-gray-500 text-xs">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

Checkmark.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkmark;
