import React from 'react';
import { useTranslation } from 'react-i18next';

const DataStatusBanner = ({ status }) => {
  const { t } = useTranslation();

  const getBannerContent = () => {
    switch (status) {
      case 'published':
        return {
          className: "bg-green-100 text-green-700",
          icon: "fill-green-500",
          message: t('This is the last saved and published version.')
        };
      case 'draft':
        return {
          className: "bg-yellow-100 text-yellow-800",
          icon: "fill-yellow-500",
          message: t('This is a draft version. Some of the information on this page might not have been published yet.')
        };
      case 'new':
        return {
          className: "bg-gray-100 text-gray-600",
          icon: "fill-gray-400",
          message: t('There is no prior version of this page. Please manually save or save as draft below.')
        };
      default:
        return null;
    }
  };

  const bannerContent = getBannerContent();

  if (!bannerContent) return null;

  return (
    <div className="data-status-banner mt-4 mb-4">
      <span className={`inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium ${bannerContent.className}`}>
        <svg className={`h-1.5 w-1.5 ${bannerContent.icon}`} viewBox="0 0 6 6" aria-hidden="true">
          <circle cx={3} cy={3} r={3} />
        </svg>
        {bannerContent.message}
      </span>
    </div>
  );
};

export default DataStatusBanner;