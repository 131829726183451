import React from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon } from '@heroicons/react/20/solid';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const getPageName = (name) => {
    const withoutHyphens = name.split('-').join(' ');
    const split = withoutHyphens.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    const capitalized = split.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
    return t(`Breadcrumbs.${name}`, capitalized);
  };

  return (
    <nav aria-label="Breadcrumb" className="border-b fixed top-0 right-0 left-[256px] border-gray-200 bg-white z-40">
      <div className="flex w-full max-w-screen-xl items-center px-4 sm:px-6 lg:px-8">
        <ol role="list" className="flex space-x-4">
          <li className="flex">
            <div className="flex items-center">
              <Link to="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;

            return (
              <li key={name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="h-full w-6 flex-shrink-0 text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={routeTo}
                    className={`ml-4 text-sm font-medium ${
                      isLast ? 'text-gray-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                    aria-current={isLast ? 'page' : undefined}
                  >
                    {getPageName(name)}
                  </Link>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;