import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import animationData from '../assets/stop-animation.json';
import Lottie from 'lottie-react';
import { useRef, useEffect, useState } from 'react';

const StartupWizardFinalCheck = ({ incompletePages }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lottieRef = useRef();
  const [direction, setDirection] = useState(1);
  const pauseDuration = 1000; // 1 second pause at each end

  useEffect(() => {
    if (lottieRef.current) {
      const anim = lottieRef.current;
      
      const animate = () => {
        if (direction === 1) {
          anim.setSpeed(0.5); // Slower speed
          anim.playSegments([0, 30], true);
          setTimeout(() => {
            setDirection(-1);
          }, (30 / 0.3) * (1000/60) + pauseDuration); // Calculate time to complete forward animation + pause
        } else {
          anim.setSpeed(-0.5); // Negative for reverse, same slow speed
          anim.playSegments([30, 0], true);
          setTimeout(() => {
            setDirection(1);
          }, (30 / 0.3) * (1000/60) + pauseDuration); // Calculate time to complete reverse animation + pause
        }
      };

      // Start animation
      animate();

      // Set up listener for direction changes
      const intervalId = setInterval(() => {
        animate();
      }, ((30 / 0.3) * (1000/60) + pauseDuration) * 2); // Total time for one complete cycle

      return () => clearInterval(intervalId);
    }
  }, [direction]);

  return (
    <div className="min-h-[50vh] flex items-center justify-center p-4">
      <div className="max-w-3xl w-full bg-red-100 border border-red-300 rounded-lg p-8 text-center">
        <div className="w-32 h-32 mx-auto mb-4">
          <Lottie 
            lottieRef={lottieRef}
            animationData={animationData}
            loop={false}
            autoplay={false}
          />
        </div>
        
        <div className="flex items-center justify-center mb-6">
          <h2 className="text-xl font-semibold text-red-800">
            {t('wizard.final_check.cannot_access')}
          </h2>
        </div>

        <p className="text-red-800 text-lg mb-6">
          {t('wizard.final_check.complete_save_all')}
        </p>

        <div className="bg-white rounded-lg p-6 mb-6">
          <h4 className="font-semibold text-gray-900 mb-4">
            {t('wizard.final_check.sections_need_attention')}
          </h4>
          <ul className="text-left space-y-3">
            {incompletePages.map((page) => (
              <li key={page.id} className="flex items-center">
                <span className="w-3 h-3 rounded-full mr-3 flex-shrink-0"
                      style={{ 
                        backgroundColor: page.status === 'empty' ? '#EF4444' : '#F59E0B'
                      }} />
                <span className="text-gray-700">
                  {page.name} - {' '}
                  <span className="text-sm text-gray-500">
                    {page.status === 'empty' 
                      ? t('wizard.final_check.no_data_entered') 
                      : t('wizard.final_check.draft_needs_save')}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>

        <button
          onClick={() => navigate('/startup-wizard')}
          className="inline-flex justify-center rounded-md bg-red-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          {t('wizard.final_check.go_to_overview')}
        </button>
      </div>
    </div>
  );
};

export default StartupWizardFinalCheck;