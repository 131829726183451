import React from 'react';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, Footer } from 'docx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useWizard } from '../../wizard_components/WizardContext';
import {
    BOOLEAN_VALUES,
    MEETING_LOCATIONS,
    EXTRAORDINARY_RESOLUTIONS,
    convertStorageToDocument,
    convertUIToStorage
} from '../../../constants/translationMappings';

const ArticlesOfAssociationGenerator = () => {
    const { t } = useTranslation();
    const { wizardData } = useWizard();

    const generateArticlesOfAssociation = async () => {
        const { published } = wizardData;
        const company = published?.company || {};

        // Convert the stored values to document-ready format
        const rawAoa = published?.articlesOfAssociation || {};
        const aoa = {
            companyPurpose: rawAoa.companyPurpose || 'The purpose of the company is to engage in lawful business activities.',
            operationRestriction: convertStorageToDocument(rawAoa.operationRestriction || BOOLEAN_VALUES.NO, 'en'),
            terminationDate: rawAoa.terminationDate || '',
            financialYearDeviation: convertStorageToDocument(rawAoa.financialYearDeviation || BOOLEAN_VALUES.NO, 'en'),
            financialYearStandard: convertStorageToDocument(rawAoa.financialYearStandard || BOOLEAN_VALUES.YES, 'en'),
            financialYearStart: rawAoa.financialYearStart || '',
            shareholderMeetingNotice: rawAoa.shareholderMeetingNotice || '4',
            shareholderMeetingQuorum: rawAoa.shareholderMeetingQuorum || '50',
            meetingLocations: (rawAoa.meetingLocations || [
                MEETING_LOCATIONS.STATUTORY_SEAT,
                MEETING_LOCATIONS.REMOTE,
                MEETING_LOCATIONS.ANYWHERE
            ]).map(location => convertStorageToDocument(location, 'en')),
            chairmanAppointmentQuorum: rawAoa.chairmanAppointmentQuorum || '50',
            ordinaryResolutionThreshold: rawAoa.ordinaryResolutionThreshold || '50',
            extraordinaryResolutionThreshold: rawAoa.extraordinaryResolutionThreshold || '75',
            extraordinaryResolutions: (rawAoa.extraordinaryResolutions || Object.values(EXTRAORDINARY_RESOLUTIONS))
                .map(resolution => convertStorageToDocument(resolution, 'en')),
            shareholderTermination: convertStorageToDocument(rawAoa.shareholderTermination || BOOLEAN_VALUES.YES, 'en')
        };

        const founders = published?.founders?.founders || [];

        const currentDateTime = new Date();
        const formattedDateTime = currentDateTime.toLocaleString('de-DE');

        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "Normal",
                        name: "Normal",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            font: "Arial", // Apply a global font style (e.g., Arial)
                            size: 22, // 11pt font size (multiply pt by 2 to get the value)
                        },
                        paragraph: {
                            spacing: { after: 200 }, // Space after paragraphs
                            indent: { left: 1200 }
                        },
                    },
                    {
                        id: "Heading1",
                        name: "Heading 1",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 32, // 16pt font size
                        },
                        paragraph: {
                            spacing: {
                                after: 300,
                            },
                        },
                    },
                    {
                        id: "Heading2",
                        name: "Heading 2",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 28, // 14pt font size
                        },
                        paragraph: {
                            spacing: {
                                before: 400,
                                after: 250,
                            },
                        },
                    },
                    {
                        id: "Heading3",
                        name: "Heading 3",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 24, // 12pt font size
                        },
                        paragraph: {
                            spacing: {
                                after: 250,
                            },
                        },
                    },
                ],
            },
            numbering: {
                config: [
                    {
                        reference: "romanNumbering", // For main sections (I, II, III)
                        levels: [
                            {
                                level: 0,
                                format: "upperRoman",
                                text: "%1.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                        tabStops: [{ position: 720, type: "left" }],
                                    },
                                },
                            },
                        ],
                    },
                    {
                        reference: "decimalNumbering", // For subsections (1, 2, 3)
                        levels: [
                            {
                                level: 0,
                                format: "decimal",
                                text: "%1.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                    },
                                },
                            },
                            {
                                level: 1,
                                format: "decimal",
                                text: "%1.%2.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                    },
                                },
                            },
                            {
                                level: 2,
                                format: "decimal",
                                text: "%1.%2.%3.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                    },
                                },
                            },
                        ],


                    },
                ],
            },

            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        text: "Articles of Association",
                        heading: HeadingLevel.HEADING_1,
                    }),
                    ...generateGeneralProvisions(company, aoa),
                    ...generateShareCapital(company, aoa, founders),
                    ...generateManagementAndRepresentation(aoa),
                    ...generateShareholdersMeetingsAndResolutions(aoa),
                    ...generateShares(aoa),
                    ...generateTermination(aoa),
                    ...generateSuccessionUponDeath(aoa),
                    ...generateRedemptionOfShares(aoa),
                    ...generateCompensation(aoa),
                    ...generateAnnualAccounts(aoa),
                    ...generateNonCompeteObligation(),
                    ...generateIncorporationExpenses(),
                    ...generateFinalProvisions(),
                ],
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Exported from Cenedril at ${formattedDateTime}`,
                                        font: "Arial",
                                        size: 22,
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
            }],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, "Articles_of_Association.docx");
    };

    // Generates the general provisions section
    const generateGeneralProvisions = (company, aoa) => {
        return [
            new Paragraph({
                text: "General Provisions",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Name",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `The business name of the company is: ${company.companyName}`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Seat",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `The statutory seat of the company is ${company.place}.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Purpose",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Purpose of the company is ${aoa.companyPurpose}.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "The company may conduct all business, establish branches and participate in other companies that appear necessary or useful to directly or indirectly serve its object.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Term, Financial Year",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            ...(convertUIToStorage(aoa.operationRestriction) === BOOLEAN_VALUES.NO ? [
                new Paragraph({
                    text: "The company is established for an indefinite period. Ordinary termination is excluded.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ] : [
                new Paragraph({
                    text: `The company is established for a limited period and ends on ${aoa.terminationDate}.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ]),
            ...(convertUIToStorage(aoa.financialYearDeviation) === BOOLEAN_VALUES.NO ? [
                new Paragraph({
                    text: "The company's financial year corresponds to the calendar year. The first financial year is a short financial year.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ] : convertUIToStorage(aoa.financialYearStandard) === BOOLEAN_VALUES.YES ? [
                new Paragraph({
                    text: "The company's financial year starts on July 1st every year and ends on June 30th. The first financial year is a short financial year.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ] : [
                new Paragraph({
                    text: `The company's financial year starts on ${aoa.financialYearStart}. The first financial year is a short financial year.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ]),
            new Paragraph({
                text: "Announcements",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Announcements of the company are exclusively published in the German Federal Gazette.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateShareCapital = (company, aoa, founders) => {

        // Helper function to calculate cumulative shares up to a given index
        const getShareNumberUpToIndex = (index) => {
            return founders.slice(0, index + 1).reduce((sum, founder) =>
                sum + (founder.sharePercentage * company.shareCapital / 100), 0);
        };

        return [
            new Paragraph({
                text: "Share Capital",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Share Capital and Shares",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `The share capital of the company amounts to EUR ${company.shareCapital}.00.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "The capital contributions for the shares have been paid in half.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `The share capital of the company is divided into ${company.shareCapital} shares each with a nominal amount of EUR 1.00 with serial numbers 1 to ${company.shareCapital}.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Of this share capital:",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            ...founders.map((founder, index) =>
                new Paragraph({
                    text: `${founder.fullName}, a total of ${founder.sharePercentage * company.shareCapital / 100} shares with a nominal value of EUR 1.00 (in words: one euro) each (shares no. ${index === 0 ? '1' : Math.floor(getShareNumberUpToIndex(index - 1)) + 1} to ${Math.floor(getShareNumberUpToIndex(index))})`,
                })
            ),
        ];
    };

    const generateManagementAndRepresentation = (aoa) => {
        return [
            new Paragraph({
                text: "Management and Representation",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Managing Directors & Representation",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({ 
                text: "The company has one or more managing directors.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "If only one managing director is appointed, he represents the company alone. If several managing directors are appointed, the company is represented jointly by two (2) managing directors or by one managing director together with a person vested with commercial power of representation.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "The managing directors are appointed and dismissed by the shareholders' meeting. By resolution of the shareholders' meeting the shareholders may grant to one or several managing directors the authority to represent the company individually and/or to represent the company in legal transactions with himself or as representative of a third party (exemption from the restrictions imposed by section 181 German Civil Code (Bürgerliches Gesetzbuch – BGB)).", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({
                text: "Management",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({ 
                text: "The managing directors shall manage the business of the company with the due care and diligence of a prudent and conscientious businessman in accordance with statutory laws, these articles of association, their respective service agreement, the rules of procedure for the management board (if any), in each case as amended from time to time, and the resolutions of the shareholders’ meeting.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "The shareholders’ meeting may adopt rules of procedure for the management board or amend or terminate existing rules of procedure for the management board. The rules of procedure may provide for certain transactions and measures to require the approval of the shareholders’ meeting.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "The managing directors shall submit a list of shareholders signed by them to the commercial register immediately after each change in the persons of the shareholders or the scope of their participation becomes effective, unless the notary public is obliged to submit such list pursuant to section 40 para. German Limited Liability Company Act. The managing directors are to be notified of such changes in writing and with proof thereof. Corresponding documents in original or certified copy are generally to be submitted as proof. Section 35 of the Land Register Act shall apply mutatis mutandis to proof of succession. After the amended list of shareholders has been registered with the commercial register, the managing directors shall immediately send a copy of the current list of shareholders to all shareholders.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "Section 8 shall apply mutatis mutandis for the liquidator(s) of the company.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
        ];
    };

    const generateShareholdersMeetingsAndResolutions = (aoa) => {
        return [
            new Paragraph({
                text: "Shareholders' Meetings and Resolutions",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Shareholders' Meetings",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Shareholders' meetings are convened by the managing directors. Each shareholder is to be invited to a shareholders' meeting at least in text form, stating place, date, time and agenda with a notice period of at least ${aoa.shareholderMeetingNotice} week(s).`, numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `A shareholders' meeting shall have a quorum, if shareholders representing alone or jointly more than ${aoa.shareholderMeetingQuorum}% of the shares are present or represented.`, numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `Shareholders' meetings can take place at the following locations: ${aoa.meetingLocations.join(", ")}.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `The shareholders' meeting elects a chairman with a ${aoa.chairmanAppointmentQuorum}% majority of the votes cast.`, numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Shareholders' Resolutions",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Unless these articles of association or mandatory law provide for a higher majority, all shareholders' resolutions are passed with a ${aoa.ordinaryResolutionThreshold}% majority of the votes cast.`, numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `The following matters require a resolution by the shareholders' meeting and thereby require a majority of ${aoa.extraordinaryResolutionThreshold}%:`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            ...aoa.extraordinaryResolutions.map(resolution =>
                new Paragraph({
                    text: resolution,
                    bullet: { level: 0 }
                })
            ),
        ];
    };

    const generateShares = (aoa) => {
        return [
            new Paragraph({
                text: "Shares",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Disposition of Shares",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Any direct or indirect disposition of a share or parts of a share in the company requires the consent of the shareholders' meeting by way of shareholders. Shareholders willing to sell and/or purchase shares in the company are not entitled to participate in the voting.", numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "A disposition within the meaning of Section 11.1 is any transfer, pledge or other encumbrance, establishment of a usufruct, implementation and termination of a trust relationship, a sub-participation, a silent partnership, profit participation or voting trust agreement as well as any other legal transactions and measures, in particular in accordance with the German Transformation Act, equaling a transfer from an economical perspective. This also includes the indirect disposal of shares in the company, e.g. by disposing of shares in the shareholder itself.", numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateTermination = (aoa) => {
        if (convertUIToStorage(aoa.shareholderTermination) === BOOLEAN_VALUES.YES) {
            return [
                new Paragraph({
                    text: "Termination",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "Any shareholder may terminate their association with the company with six months' notice to the end of a financial year. Notice of cancellation must be given by registered letter to the company. The right to terminate for good cause remains unaffected.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        }
        return [
            new Paragraph({
                text: "If there is good cause for termination of the shareholder's relationship with the company, the company can decide by a majority resolution to exclude the shareholder in question. The shareholder in question must be compensated in accordance with the provisions set forth in Section 15 (Compensation).",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateSuccessionUponDeath = (aoa) => {
        return [
            new Paragraph({
                text: "Succession upon Death",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "In case a shareholder dies, the company shall be continued with his/her legal successors or legatees regardless of their identity and without prejudice to the following rights of the company. The provisions of any agreement concluded between the shareholders and the Company with respect to their shareholdings in the company remain unaffected.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "In case of death of a shareholder his or her statutory or chosen successors are obliged to evidence their title in inherited or bequeathed shares vis-á-vis the company by presentation of an inheritance certificate or official evidence of inheritance in accordance with section 35 Land Register Act and give notice without delay to the company and the other shareholders of the succession of the shares in written form.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "If the shareholders, based on a shareholders’ resolution passed with a simple majority, request that the successors do so, the successors have to assign the shares of the deceased shareholder to a trustee of their choice, provided such an assignment is permissible for tax reasons.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateRedemptionOfShares = (aoa) => {
        return [
            new Paragraph({
                text: "Redemption of Shares",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "A share of a shareholder or a part thereof may be redeemed with his consent at any time (consensual redemption).",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "A share of a shareholder or a part thereof may be redeemed without his consent (“Compulsory Redemption”) only if:",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "insolvency proceedings or similar proceedings pursuant to foreign law have been filed against the assets of the shareholder or if the commencement of such proceedings is denied for insufficiency of assets or if the shareholder has to provide information on his financial status and assets; or",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "the share of the shareholder has been pledged or otherwise become subject of foreclosure and the seizure or other enforcement measure, as the case may be, was not levied within two (2) months after its beginning and in any event before the realization of the share; or",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "the shareholder has given reason to be excluded from the Company for good cause (wichtiger Grund), in particular if there is a good cause in the meaning of section 140 in connection with section 133 German Commercial Code arising in the person of the shareholder justifying his exclusion or the shareholder breaches material obligations arising out of an agreement existing amongst the shareholders; or",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "the successors of a deceased shareholder do not transfer the inherited shares within the period set out in Section 13.2 above to a shareholder as trustee – insofar as such transfer would have been possible for tax reasons without adverse financial consequences for the heir(s)   – and the shareholders’ meeting has requested the heir(s) to transfer under the threat of redemption in writing after the lapse of the period set out in Section 13.2 above; or",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "the shareholder disposes of his shares contrary to Section 11.1; or",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "upon written request by the company or a shareholder, the respective shareholder does not immediately,, at the latest within six (6) months from receiving such request, prove that the shareholder has agreed by way of marriage agreement (Ehevertrag) that in case he lives in the marital property regime of community of acquisitions (Zugewinngemeinschaft) the shareholder is not subject to the restrictions of section 1365 BGB and that his shares in the Company are not subject to any compensation with respect to the community of acquisitions (Zugewinngemeinschaft) if the marital status is terminated other than by death of the spouse (Zugewinnausgleich); or",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "spouses of married shareholders who live under the statutory matrimonial property regime do not consent to the sale of shares of the shareholders pursuant to section 1365 BGB as far as such consent is required;",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "the shareholders have entered into an agreement on the possibility of a redemption of shares and the conditions for the redemption are fulfilled accordingly or the shareholder does not fulfill his obligation to transfer his shares after being requested to do so by the shareholders' resolution in accordance with Section 14.8;",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "a direct or indirect participation of the shareholder in the company’s share capital is partially or in total transferred to one or more third parties without the prior consent of the shareholders’ meeting (change of participation structure on shareholder’s level by transfer of shares or issue of new shares); or",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "the shareholder declares his exit (Austritt) from the company in written form – regardless of the reasons or terminates for good cause (notice of extraordinary termination).",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "If a share is held by several shareholders jointly, its redemption according to Section 14.2 is also permitted in case the prerequisites according to Section 14.2 only apply to one of such shareholders.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "The shareholders’ meeting resolves upon the redemption pursuant to section 10.5.13, whereas in deviation from Section 10.3. a majority of 75 % is required. In case of a Compulsory Redemption pursuant to Section 14.2, the shareholder affected has no voting right in this decision-making. The management board declares the redemption to the affected shareholders. A separate declaration is not necessary if the shareholder concerned is present or represented in the shareholders’ meeting resolving upon the redemption.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "The redemption becomes effective upon receipt of the declaration on the redemption resolution by the shareholder concerned or in case a separate declaration according to Section 14.4 is not necessary upon the resolution on the redemption has been passed. Payment of the compensation pursuant to Section 15 is not a prerequisite for the effectiveness of the redemption. In any case, the voting rights of the shares to be redeemed are suspended as from the day of the resolution upon redemption until the shareholder's departure comes into effect.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Shares in the company held by the company can at any time (without the payment of any compensation) be redeemed by a shareholders’ resolution according to Section 14.4.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Within the resolution on redemption the shareholders entitled to vote shall also determine whether (i) the share capital of the Company shall be decreased accordingly, (ii) the nominal amounts of the other shareholders shall be increased pro-rata or (iii) one or several new shares shall be created with the result that the total nominal amount of all shares in the Company equals the registered share capital. The reissuance (Neubildung) of a redeemed share shall be permitted. It shall be effected by unanimous shareholders’ resolution.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Instead of redemption of shares the shareholders’ meeting may, with a majority of 75% of the votes cast – excluding the votes of the departing shareholder – resolve that the respective shares shall be transferred to the company, as far as is permissible pursuant to section 33 Limited Liability Companies Act or to a third party (including other shareholders) determined by the shareholders’ meeting (compulsory transfer), also in the way that the shares are partially redeemed and partially assigned to the company or a third party. The amount of the compensation for the transfer of the respective shares shall be determined pursuant to Section 15 below.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateCompensation = (aoa) => {
        return [
            new Paragraph({
                text: "Compensation",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "A Shareholder or his successor shall receive compensation for the redemption of his shares in accordance with the following provisions.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "In cases of a compulsory redemption according to Section 14.2.1 through (and including) Section 14.2.10, the compensation equals an aggregate amount equal to the book value of the redeemed shares at the cutoff date. Hidden reserves or goodwill must not be taken into account for the calculation of the book value unless the Company and the respective shareholder have agreed otherwise. Cut-off date, if the redemption takes effect at the balance sheet date, is the effective date of the redemption, and in all other cases the previous balance sheet date.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "In the event that, in a particular case, it is finally ruled that the compensation provision according to Section 15.2 is invalid, then the lowest permissible compensation amount is to be paid.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "In all other cases of redemption, the compensation equals the fair market value of the redeemed share on the cut-off date.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "For the purposes of this Section 15 the fair market value is calculated according to the principles of the Institute of Public Auditors in Germany, Incorporated Association for the Performance of Business Valuations (statement of the IDW’s main technical committee IDW S1) as amended or according to the principles replacing these. The book value is calculated from the nominal capital plus open reserves, plus annual net income and profit carried forward and minus annual deficit and loss carried forward.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Changes of the financial statements of the company due to external tax audits or for other reasons (with the exception of a successful challenge of a shareholders’ resolution approving the financial statements) which take place after the effective date for the calculation of the compensation do not have any effect on the amount of the compensation.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "The compensation for redemption is to be paid in three equal installments. The first installment is due and payable within six (6) months after the resolution on the redemption has been passed. The following installments become due and payable each one (1) year after the preceding installment became due and payable. The respective outstanding amount of the compensation for redemption is subject to interest at a rate of two percentage points p.a. above the base interest rate. Interest is due and payable together with the respective installments. The company is, at any time, entitled to partially or completely pay the compensation prior to maturity and to set-off against payments which will become due in the future.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "If, insofar as compensation payments would violate section 30 para. 1 or section 64 sentence 3 German Limited Liability Companies Act, compensation payments with respect to the principal amount is deferred bearing interest at the above rate; interest payments are deferred without bearing interest.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "The resigning shareholder is not entitled to request any security from the company for the outstanding compensation payments including interest.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Disputes concerning the amount of the compensation to be determined in accordance with these provisions are finally and bindingly settled by a certified public accountant as an arbitrator’s expert. Section 319 BGB remains unaffected. If the company and the shareholder concerned by the redemption cannot agree on an arbitrator’s expert within one (1) month upon request of either of the two parties, the arbitrator’s expert is, upon request of one of the two parties, nominated by the Institute of Public Auditors in Germany, Incorporated Association. The company and the shareholder concerned by the redemption shall bear the costs of the proceeding according to sections 91 et. seqq. German Code of Civil Procedure.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "The Shareholders are aware that the compensation provided for above in Section 15.2 may be significantly less than it would be if calculated according to the fair market value of the Company’s assets. All shareholders nevertheless agree to comply with these rules and hereby mutually waive in advance already hereby any claims for any additional compensation. In the event that the aforesaid waiver should prove unenforceable and the compensation payable to the withdrawing shareholder is held to be too low, the compensation payable to the withdrawing shareholder shall be adjusted in such a manner that it shall amount to no more than 70% of the actual fair market value of the redeemed shares.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Sections 15.1 through and including 15.11 apply mutatis mutandis in case the shareholders’ meeting requests the transfer of shares to the Company or to another third party pursuant to 14.8 instead of redemption, whereas the respective transferee shall be obliged to pay the compensation as consideration for the shares to be transferred.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),

        ];
    };


    const generateAnnualAccounts = (aoa) => {
        return [
            new Paragraph({
                text: "Annual Accounts",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Preparation, Audit",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "The management of the company is obliged to prepare, within the statutory time limit after each financial year, the annual accounts (balance sheet together with profit and loss account and annex) as well as – if required by statutory law or resolved by the shareholders' meeting – a management report according to the regulations of the German Commercial Code.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Approval, Use of Profits, Allocation of Assets",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "The shareholders' meeting approves the annual accounts prepared by the managing directors and decides on the use of the annual profit. The distribution of profits is carried out pro rata based on the shares held by each shareholder, unless otherwise agreed by the shareholders.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateNonCompeteObligation = () => {
        return [
            new Paragraph({
                text: "Non-Compete Obligation",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Non-Compete Obligation",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "The shareholders are not subject to a non-competition clause in the business area of the company for the duration of their position as shareholders. It is permissible to agree non-competition clauses in a separate written agreement (in particular in the employment contracts of the managing directors) in individual cases or in general.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateIncorporationExpenses = () => {
        return [
            new Paragraph({
                text: "Incorporation Expenses",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Incorporation Expenses",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "The costs arising in connection with the incorporation, such as the costs of this deed and registration thereof in the Commercial Register, including the costs of legal advice shall be borne by the Company up to a total amount of EUR 2,500.00.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateFinalProvisions = () => {
        return [
            new Paragraph({
                text: "Final Provisions",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Severability Clause",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Should individual provisions of these articles of association be or become void, ineffective or unenforceable in whole or in part, then the validity of the remaining provisions of the articles of association are not affected thereby.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Applicable Law, Place of Jurisdiction, Binding Character",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "These articles of association are exclusively subject to the laws of the Federal Republic of Germany.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "To the extent permissible by law, the exclusive place of jurisdiction for all disputes arising from or in connection with these articles of association or regarding their validity is the statutory seat of the Company.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Only the German version of these articles of association is legally binding. This english version merely constitutes a convenient translation.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    return (
        <button
            onClick={generateArticlesOfAssociation}
            className="text-white px-4 py-2 rounded hover:text-white bg-[#2A9D8F] hover:bg-[#238276] flex items-center text-sm"
        >
            <img src={process.env.PUBLIC_URL + '/ux_icons/download.svg'} alt="icon" className="h-5 w-5 mr-2 fill-current text-white" />
            Articles of Association (english)
        </button>
    );
};

export default ArticlesOfAssociationGenerator;
