import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { useTranslation } from 'react-i18next';
import {
  DocumentChartBarIcon,
  BellAlertIcon,
  ShieldCheckIcon,
  LockClosedIcon,
  ChevronRightIcon,
  ClipboardIcon,
  ClipboardDocumentCheckIcon,
  UserIcon,
  BuildingOfficeIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/24/outline';
import { Popover } from 'flowbite-react';

const CircularProgress = ({ published, draft, total }) => {
  const publishedPercentage = (published / total) * 100;
  const draftPercentage = (draft / total) * 100;
  const totalPercentage = publishedPercentage + draftPercentage;

  return (
    <div className="relative w-16 h-16">
      <svg className="w-full h-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200" strokeWidth="3"></circle>
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-yellow-400"
          strokeWidth="3"
          strokeDasharray="100"
          strokeDashoffset={100 - totalPercentage}
          strokeLinecap="round">
        </circle>
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-green-500"
          strokeWidth="3"
          strokeDasharray="100"
          strokeDashoffset={100 - publishedPercentage}
          strokeLinecap="round">
        </circle>
      </svg>
      <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <span className="text-center text-sm font-bold text-green-500">{Math.round(publishedPercentage)}%</span>
      </div>
    </div>
  );
};

const CompanyDashboard = () => {
  const [companyData, setCompanyData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [imageError, setImageError] = useState(false);
  const { t, i18n } = useTranslation();
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    fetchCompanyData();
    fetchTasks();
  }, []);

  const fetchCompanyData = async () => {
    try {
      const userDataResponse = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      setCompanyData(userDataResponse.data.entity);
      setUserData(userDataResponse.data); // Store user data
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const [tasksResponse, usersResponse] = await Promise.all([
        axios.get('/api/v1/tasks', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
        }),
        axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
        })
      ]);

      const tasks = tasksResponse.data;
      const users = usersResponse.data.entity.users;

      // Create a map of user IDs to names
      const userMap = users.reduce((map, user) => {
        map[user.id] = `${user.first_name} ${user.last_name}`;
        return map;
      }, {});

      // Map status numbers to string representations
      const statusMap = {
        0: 'backlog',
        1: 'current',
        2: 'approval',
        3: 'done'
      };

      const activities = tasks.flatMap(task => [
        {
          type: 'creation',
          task: task,
          date: new Date(task.created_at),
          user: userMap[task.created_by_id] || 'Unknown'
        },
        {
          type: 'assignment',
          task: task,
          date: new Date(task.assignment_date),
          user: userMap[task.assigned_by_id] || 'Unknown',
          assignedTo: userMap[task.assigned_to_id] || 'No one',
          approvalBy: userMap[task.approved_by_id] || 'No one'
        },
        ...(task.status_changes_history || []).map(change => ({
          type: 'status_change',
          task: task,
          date: new Date(change.changed_at),
          user: userMap[change.changed_by_id] || 'Unknown',
          fromStatus: change.from_status,
          toStatus: change.to_status
        }))
      ]);

      console.log('Activities:', activities);

      // Sort activities by date, newest first
      activities.sort((a, b) => b.date - a.date);

      // Limit to 20 most recent activities
      setActivities(activities.slice(0, 10));
      setTasks(tasks);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return t('Good morning');
    if (hour < 18) return t('Good afternoon');
    return t('Good evening');
  };

  const TaskStatusIcon = ({ task, userData }) => {
    const isAssigned = task.assigned_to_id === userData?.id;
    const needsApproval = task.approved_by_id === userData?.id;

    let icon, text, textColor;
    if (isAssigned && needsApproval) {
      icon = (
        <>
          <ClipboardIcon className="h-5 w-5 text-blue-500 mr-1" aria-hidden="true" />
          <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
        </>
      );
      text = t('Assigned to you and needs your approval');
      textColor = 'text-blue-500';
    } else if (isAssigned) {
      icon = <ClipboardIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />;
      text = t('Assigned to you');
      textColor = 'text-blue-500';
    } else if (needsApproval) {
      icon = <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />;
      text = t('Needs your approval');
      textColor = 'text-green-500';
    }

    return (
      <Popover
        content={
          <div className="p-3 bg-white rounded-lg shadow-lg max-w-xs">
            <p className={`text-sm ${textColor} text-center whitespace-normal break-words`}>{text}</p>
          </div>
        }
        arrow={false}
        trigger="hover"
        placement="top"
      >
        <div className="flex items-center cursor-help">
          {icon}
        </div>
      </Popover>
    );
  };

  const UserAvatar = ({ user }) => {
    if (!user) return null;
    return user.avatar_url ? (
      <img
        src={user.avatar_url}
        alt={`${user.first_name} ${user.last_name}`}
        className="h-6 w-6 rounded-full mr-2  bg-secondary-color object-cover object-center"
      />
    ) : (
      <div className="h-6 w-6 rounded-full bg-gray-300 flex items-center justify-center mr-2  bg-secondary-color object-cover object-center">
        <span className="text-xs font-medium text-gray-700">
          {user.first_name[0]}{user.last_name[0]}
        </span>
      </div>
    );
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const diff = now - new Date(date);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));

    if (days > 7) {
      return new Date(date).toLocaleDateString(i18n.language, { day: '2-digit', month: '2-digit', year: '2-digit' });
    } else if (days > 0) {
      return `${days} ${days === 1 ? t('day ago') : t('days ago')}`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? t('hour ago') : t('hours ago')}`;
    } else {
      return `${minutes} ${minutes === 1 ? t('minute ago') : t('minutes ago')}`;
    }
  };

  function UserInitials({ firstName, lastName }) {
    const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
    return (
      <div className="h-14 w-14 rounded-full bg-secondary-color flex items-center justify-center">
        <span className="text-white font-bold text-xl">{initials}</span>
      </div>
    );
  }

  const getAreaIcon = (area) => {
    switch (area) {
      case 'cybersecurity':
        return <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'data_privacy':
        return <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'organization':
        return <BuildingOfficeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      default:
        return <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
    }
  };

  return (
    <div>
      {/* Header */}
      <div className="bg-white shadow fixed top-0 right-0 left-[256px] z-30 border-b border-gray-200">
        <div className="mx-auto w-full max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-35">
            <div className="flex items-center mt-20 mb-8">
              {userData?.avatar_url && !imageError ? (
                <img
                  className="h-14 w-14 rounded-full  bg-secondary-color object-cover object-center"
                  src={userData.avatar_url}
                  alt={`${userData.first_name} ${userData.last_name}`}
                  onError={() => setImageError(true)}
                />
              ) : (
                <UserInitials firstName={userData?.first_name} lastName={userData?.last_name} />
              )}
              <div className="ml-4 flex flex-col">
                <h1 className="text-2xl font-bold text-gray-900 leading-tight">
                  {getGreeting()}, {userData?.first_name}
                </h1>
                <p className="text-sm text-gray-500 mt-1">{companyData?.name}</p>
              </div>
            </div>
            <button
              type="button"
              disabled
              className="inline-flex items-center mt-10 px-6 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 opacity-50 cursor-not-allowed"
            >
              <img src={`${process.env.PUBLIC_URL}/ux_icons/link.svg`} alt="icon" className="h-4 w-4 mr-3 fill-current text-white" />
              {t('Share Company data (coming soon)')}
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-40">
        {/* Overview Cards */}
        <h2 className="text-lg leading-6 font-medium text-gray-900 pb-2">{t('Overview')}</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
          {/* Latest Report Card */}
          <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
            <div className="p-5 flex-grow">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <DocumentChartBarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">{t('Latest Report')}</dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">{t('Not available yet')}</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                  {t('View all')}
                </a>
              </div>
            </div>
          </div>

          {/* Most Pressing Alert Card */}
          <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
            <div className="p-5 flex-grow">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <BellAlertIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">{t('Most Pressing Alert')}</dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">{t('Not available yet')}</div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                  {t('View all')}
                </a>
              </div>
            </div>
          </div>

          {/* Cybersecurity and Data Privacy Cards */}
          <div className="grid grid-cols-2 gap-5">
            {/* Cybersecurity Card */}
            <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
              <div className="p-5 flex-grow flex flex-col justify-center">
                <dl className="flex flex-col items-center">
                  <dt className="text-sm font-medium text-gray-500 mb-2">{t('Cybersecurity')}</dt>
                  <dd>
                    <CircularProgress published={30} draft={20} total={100} />
                  </dd>
                </dl>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                    {t('View details')}
                  </a>
                </div>
              </div>
            </div>

            {/* Data Privacy Card */}
            <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
              <div className="p-5 flex-grow flex flex-col justify-center">
                <dl className="flex flex-col items-center">
                  <dt className="text-sm font-medium text-gray-500 mb-2">{t('Data Privacy')}</dt>
                  <dd>
                    <CircularProgress published={10} draft={5} total={100} />
                  </dd>
                </dl>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                    {t('View details')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* To Do's Table */}
        <h2 className="text-lg leading-6 font-medium text-gray-900 mt-12 pb-0">{t("To Do's")}</h2>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Area')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Task')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Assigned by')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Assignment date')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {tasks.length > 0 ? (
                      tasks.filter(task => {
                        console.log('Task:', task);
                        console.log('User ID:', userData?.id);

                        const isAssignedTo = task.assigned_to_id === userData?.id;
                        const isApprovalBy = task.approved_by_id === userData?.id;

                        console.log('Is Assigned To:', isAssignedTo);
                        console.log('Is Approval By:', isApprovalBy);
                        console.log('Task Status:', task.status);

                        if (isApprovalBy && task.status === 'approval') {
                          return true;
                        }
                        if (isAssignedTo && (task.status === 'backlog' || task.status === 'current')) {
                          return true;
                        }
                        return false;
                      }).map((task) => (
                        <tr key={task.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {getAreaIcon(task.area)}
                              <span className="ml-2 text-sm text-gray-900">{t(`areas.${task.area}`)}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <TaskStatusIcon task={task} userData={userData} />
                              <span className="ml-2 text-sm text-gray-900">{task.name}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <UserAvatar user={task.assigned_by} />
                              <span className="text-sm text-gray-900">{task.assigned_by_name}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatTimeAgo(task.assignment_date)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan="4">
                          {t('No tasks available')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Recent Activity Table */}
        <h2 className="text-lg leading-6 font-medium text-gray-900 mt-12 pb-0">{t('Recent activity in your organisation')}</h2>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Area')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Activity')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Timestamp')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {activities.length > 0 ? (
                      activities.map((activity, index) => (
                        <tr key={`${activity.type}-${activity.task.id}-${index}`}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {getAreaIcon(activity.task.area)}
                              <span className="ml-2 text-sm text-gray-900">{t(`areas.${activity.task.area}`)}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-normal">
                            <span className="text-sm text-gray-900">
                              {activity.type === 'creation'
                                ? t('taskCreationActivity', { user: activity.user, taskName: activity.task.name })
                                : activity.type === 'assignment'
                                  ? t('assignmentChangeActivity', {
                                    user: activity.user,
                                    taskName: activity.task.name,
                                    assignedTo: activity.assignedTo || t('NoOne'),
                                    approvalBy: activity.approvalBy || t('NoOne')
                                  })
                                  : t('statusChangeActivity', {
                                    user: activity.user,
                                    taskName: activity.task.name,
                                    fromStatus: t(`status.${activity.fromStatus}`),
                                    toStatus: t(`status.${activity.toStatus}`)
                                  })
                              }
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatTimeAgo(activity.date)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan="3">
                          {t('No recent activity')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDashboard;