import React from 'react';
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

const DocumentGenerator = ({ data, generalInfo, pages }) => {
  const { t } = useTranslation();

  const formatValue = (value) => {
    if (Array.isArray(value)) {
      return value.map(item => {
        if (typeof item === 'object') {
          return Object.entries(item)
            .map(([key, val]) => `${key}: ${val}`)
            .join(', ');
        }
        return item;
      }).join(', ');
    } else if (typeof value === 'object') {
      return Object.entries(value)
        .map(([key, val]) => `${key}: ${formatValue(val)}`)
        .join(', ');
    }
    return value.toString();
  };

  const generateContent = () => {
    console.log('Generating content...');
    
    const content = [
      new Paragraph({
        text: t('Know Yourself Summary'),
        heading: HeadingLevel.HEADING_1,
      }),
      new Paragraph({
        text: `${t('Company')}: ${generalInfo['Company Name']}`,
        heading: HeadingLevel.HEADING_2,
      }),
    ];

    pages.forEach(page => {
      content.push(
        new Paragraph({
          text: t(page.name),
          heading: HeadingLevel.HEADING_2,
        })
      );

      page.fields.forEach(field => {
        if (data[field] !== undefined) {
          content.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: `${t(`KnowYourselfWizard.${field}`)}: `,
                  bold: true,
                }),
                new TextRun(formatValue(data[field])),
              ],
            })
          );
        }
      });
    });

    return content;
  };

  const generateDocx = async () => {
    console.log('Generating DOCX...');
    try {
      const doc = new Document({
        sections: [{
          properties: {},
          children: generateContent()
        }]
      });

      const blob = await Packer.toBlob(doc);
      saveAs(blob, "know-yourself-summary.docx");
      console.log('DOCX generated successfully');
    } catch (error) {
      console.error('Error generating DOCX:', error);
    }
  };

  const generatePDF = async () => {
    console.log('Generating PDF...');
    try {
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage();
      const { width, height } = page.getSize();
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

      const content = generateContent();
      let yOffset = height - 50;
      const fontSize = 12;
      const lineHeight = 15;

      content.forEach(item => {
        if (item instanceof Paragraph) {
          const isHeading = item.heading !== undefined;
          const text = item.children ? item.children.map(child => child.text).join('') : item.text;
          
          page.drawText(text, {
            x: 50,
            y: yOffset,
            size: isHeading ? fontSize + 4 : fontSize,
            font: isHeading ? boldFont : font,
          });
          
          yOffset -= isHeading ? lineHeight + 10 : lineHeight;

          if (yOffset < 50) {
            page = pdfDoc.addPage();
            yOffset = height - 50;
          }
        }
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      saveAs(blob, 'know-yourself-summary.pdf');
      console.log('PDF generated successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div>
      <button onClick={generateDocx} className="mr-2 px-4 py-2 bg-blue-500 text-white rounded">
        {t('Download as DOCX')}
      </button>
      <button onClick={generatePDF} className="px-4 py-2 bg-green-500 text-white rounded">
        {t('Download as PDF')}
      </button>
    </div>
  );
};

export default DocumentGenerator;