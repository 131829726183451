import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPage = () => {
  const { i18n } = useTranslation();
  const policyId = i18n.language === 'de' ? '94441897' : '95287731';
  const previousLanguage = useRef(i18n.language);

  useEffect(() => {
    // Check if language has changed
    if (previousLanguage.current !== i18n.language) {
      window.location.reload();
    }

    // Update the previous language reference
    previousLanguage.current = i18n.language;
  }, [i18n.language]);

  useEffect(() => {
    // Load iubenda script
    const script = document.createElement('script');
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
    document.body.appendChild(script);

    // Function to retry loading the content
    const loadContent = () => {
      if (window.iubenda) {
        window.iubenda.load();
      } else {
        setTimeout(loadContent, 100);
      }
    };

    script.onload = loadContent;

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [i18n.language]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Privacy Policy Card */}
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <a 
            href={`https://www.iubenda.com/privacy-policy/${policyId}`} 
            className="iubenda-white no-brand iubenda-embed iub-body-embed" 
            title={i18n.language === 'de' ? "Datenschutzerklärung" : "Privacy Policy"}
          >
          </a>
        </div>

        {/* Cookie Policy Card */}
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <a 
            href={`https://www.iubenda.com/privacy-policy/${policyId}/cookie-policy`} 
            className="iubenda-white no-brand iubenda-embed iub-body-embed" 
            title={i18n.language === 'de' ? "Cookie-Richtlinie" : "Cookie Policy"}
          >
          </a>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;