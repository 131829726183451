import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
  ShieldExclamationIcon,
  ArrowLeftOnRectangleIcon,
  Square3Stack3DIcon,
  Bars3Icon,
  XMarkIcon,
  CreditCardIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/outline';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MobileNavigation() {
  const { user, logout } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.language) {
      i18n.changeLanguage(user.language);
    }
  }, [user, i18n]);

  const navigation = [
    { name: 'sidebar.dashboard', to: '/dashboard', icon: HomeIcon, disabled: false },
    {
      name: 'sidebar.tasks',
      to: '/tasks',
      icon: BriefcaseIcon,
      disabled: false,
    },
    { name: 'sidebar.reports', to: '/reports', icon: ChartPieIcon, disabled: true },
    { name: 'sidebar.alerts', to: '/alerts', icon: ShieldExclamationIcon, disabled: true },
  ];

  if (user?.entity_type === 'Company') {
    navigation.push({ name: 'sidebar.wizards', to: '/wizards', icon: Square3Stack3DIcon, disabled: false });
  }

  if (user?.entity_type === 'Portfolio') {
    navigation.push({ name: 'sidebar.portfolio', to: '/portfolio', icon: DocumentDuplicateIcon, disabled: false });
  }

  if (user?.role === 'admin' || user?.role === 'owner') {
    navigation.push({ name: 'sidebar.team', to: '/team', icon: UsersIcon, disabled: false });
  }

  navigation.push({ name: 'sidebar.settings', to: '/settings', icon: AdjustmentsHorizontalIcon, disabled: false });

  if (user?.role === 'owner') {
    navigation.push({ 
      name: 'sidebar.billing', 
      to: '/billing', 
      icon: CreditCardIcon, 
      disabled: false 
    });
  }

  navigation.push({ name: 'sidebar.logout', to: '#', icon: ArrowLeftOnRectangleIcon, action: () => setOpen(true), noActive: true });

  const handleLogout = () => {
    logout();
    navigate('/logout-success');
  };

  return (
    <>
      <div className="md:hidden">
        {/* Mobile navbar */}
        <div className="w-full bg-primary-color flex items-center justify-between px-4 py-3 fixed top-0 left-0 z-40">
          <img className="h-10" src="/LogoOneColor_white.svg" alt="Cenedril Logo" />
          <button onClick={() => setSidebarOpen(!sidebarOpen)} className="text-white focus:outline-none">
            {sidebarOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>

        {/* Mobile sidebar */}
        <div
          className={classNames(
            'fixed top-0 left-0 z-30 w-full h-full bg-primary-color transform transition-transform duration-300 ease-in-out pt-16',
            sidebarOpen ? 'translate-x-0' : '-translate-x-full'
          )}
        >
          <nav className="flex flex-1 flex-col mt-6">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="space-y-1" style={{ paddingLeft: '10px' }}>
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        to={item.to}
                        className={({ isActive }) =>
                          classNames(
                            item.noActive ? 'text-white hover:bg-secondary-color hover:bg-opacity-50' :
                            isActive ? 'bg-secondary-color text-white' : 'text-white hover:bg-secondary-color hover:bg-opacity-50',
                            'sidebar-link group flex gap-x-3 rounded-md p-2 text-lg font-semibold leading-6'
                          )
                        }
                        onClick={item.action}
                      >
                        <item.icon
                          className={classNames(
                            'h-8 w-8 shrink-0',
                            { 'text-white': item.current, 'text-white group-hover:text-white': !item.current }
                          )}
                          aria-hidden="true"
                        />
                        {t(item.name)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="mt-auto">
                <a
                  href="#"
                  className="sidebar-link flex items-center gap-x-4 px-6 py-3 text-lg font-semibold leading-6 text-white hover:bg-secondary-color hover:text-white"
                >
                  <img
                    className="h-10 w-10 rounded-full bg-secondary-color"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">{user?.first_name} {user?.last_name}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                        {t('logoutConfirmation')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-base text-gray-500">{t('logoutConfirmationText')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleLogout}
                    >
                      {t('logoutConfirm')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-base font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
