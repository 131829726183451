// src/pages/PortfolioDashboard.js
import React, { useState, useEffect } from 'react';
import axios from '../axios';

const PortfolioDashboard = () => {
  const [companyKey, setCompanyKey] = useState('');
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchPortfolioCompanies();
  }, []);

  const fetchPortfolioCompanies = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/portfolio_companies', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleAddCompany = async () => {
    try {
      await axios.post('/api/v1/live_data/add_company', { company_key: companyKey }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      setCompanyKey('');
      fetchPortfolioCompanies();
    } catch (error) {
      console.error('Error adding company:', error);
    }
  };

  return (
    <div>
      <h1>Portfolio Dashboard</h1>
      <input
        type="text"
        value={companyKey}
        onChange={(e) => setCompanyKey(e.target.value)}
        placeholder="Enter Company Key"
      />
      <button onClick={handleAddCompany}>Add Company</button>
      <h2>Connected Companies</h2>
      <ul>
        {companies.map((company) => (
          <li key={company.name}>
            {company.name} - {company.website} - {company.country}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PortfolioDashboard;
