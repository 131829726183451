import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { CreditCardIcon, UserGroupIcon, CalendarIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import Notification from '../components/Notification';

export default function BillingPage() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const fetchSubscriptionData = async () => {
    try {
      const response = await axios.get('/api/v1/stripe/subscription_details', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setSubscriptionData(response.data);
    } catch (err) {
      setError(t('billing.loadError'));
    }
  };

  const handlePortalAccess = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        '/api/v1/stripe/create_portal_session',
        {},
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
        }
      );

      window.location.href = response.data.url;
    } catch (err) {
      setError(t('billing.portalAccessError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-10">
      {error && (
        <Notification
          show={!!error}
          setShow={() => setError(null)}
          type="error"
          message={error}
        />
      )}

      {subscriptionData && (
        <div className="grid grid-cols-1 gap-6 mb-8 lg:grid-cols-3">
          {/* Subscription Info Card */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <UserGroupIcon className="h-8 w-8 text-primary-color mb-3" />
              <h3 className="text-base font-semibold">{t('billing.subscriptionDetails')}</h3>
              <dl className="mt-4 space-y-3">
                <div>
                  <dt className="text-xs text-gray-500">{t('billing.plan')}</dt>
                  <dd className="text-base font-medium">{subscriptionData.plan_name}</dd>
                </div>
                <div>
                  <dt className="text-xs text-gray-500">{t('billing.quantity')}</dt>
                  <dd className="text-base font-medium">
                    {subscriptionData.quantity} x €{subscriptionData.unit_price} = €{(subscriptionData.quantity * subscriptionData.unit_price).toFixed(2)} / {t('billing.month')}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {/* Next Payment Card */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <CalendarIcon className="h-8 w-8 text-primary-color mb-3" />
              <h3 className="text-base font-semibold">{t('billing.nextPayment')}</h3>
              <dl className="mt-4 space-y-3">
                <div>
                  <dt className="text-xs text-gray-500">{t('billing.nextBillingDate')}</dt>
                  <dd className="text-base font-medium">
                    {new Date(subscriptionData.current_period_end * 1000).toLocaleDateString()}
                  </dd>
                </div>
                <div>
                  <dt className="text-xs text-gray-500">{t('billing.estimatedTotal')}</dt>
                  <dd className="text-base font-medium">€{subscriptionData.next_invoice_total}</dd>
                </div>
              </dl>
            </div>
          </div>

          {/* Payment Method Card */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <CreditCardIcon className="h-8 w-8 text-primary-color mb-3" />
              <h3 className="text-base font-semibold">{t('billing.paymentMethod')}</h3>
              {subscriptionData.payment_method ? (
                <dl className="mt-4 space-y-3">
                  {subscriptionData.payment_method.type === 'card' ? (
                    <>
                      <div>
                        <dt className="text-xs text-gray-500">{t('billing.card')}</dt>
                        <dd className="text-base font-medium">•••• {subscriptionData.payment_method.last4}</dd>
                      </div>
                      <div>
                        <dt className="text-xs text-gray-500">{t('billing.expires')}</dt>
                        <dd className="text-base font-medium">
                          {subscriptionData.payment_method.exp_month}/{subscriptionData.payment_method.exp_year}
                        </dd>
                      </div>
                    </>
                  ) : subscriptionData.payment_method.type === 'link' ? (
                    <p className="text-base font-medium">{t('billing.linkPaymentMethod')}</p>
                  ) : (
                    <p className="text-base font-medium">{t('billing.unsupportedPaymentMethod')}</p>
                  )}
                </dl>
              ) : (
                <p className="mt-4 text-base text-gray-500">{t('billing.noPaymentMethod')}</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Stripe Portal Access Card */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex flex-col items-left">
            <DocumentTextIcon className="h-8 w-8 text-primary-color mb-3" />
            <h2 className="text-base font-semibold text-gray-900 pb-0 mb-2">
              {t('billing.manageTitle')}
            </h2>
            <p className="text-base text-gray-600 text-left">
              {t('billing.expandedDescription')}
            </p>
            <button
              onClick={handlePortalAccess}
              disabled={isLoading}
              className="mt-4 w-full inline-flex justify-center items-center px-4 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-primary-color hover:bg-primary-color/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color disabled:opacity-50"
            >
              {isLoading ? t('billing.accessing') : t('billing.goToPortal')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}