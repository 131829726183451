import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import EmployeeCard from '../../../components/wizard_components/EmployeeCard';
import EmployeeCardAdd from '../../../components/wizard_components/EmployeeCardAdd';
import OfficeCard from '../../../components/wizard_components/OfficeCard';
import OfficeCardAdd from '../../../components/wizard_components/OfficeCardAdd';
import { useUserData } from '../../../hooks/useUserData';
import { useSchema } from '../../../hooks/useSchema';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';

const KnowYourselfWizardTeam = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, loading: userDataLoading } = useUserData();
  const { schema, loading: schemaLoading } = useSchema();
  const { handleSubmit, handleDraftSubmit, error: submitError } = useFormSubmit('/know-yourself/investment');
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [dataStatus, setDataStatus] = useState('new');

  // Extract company information
  const companyYearFounded = userData?.entity?.year_founded || new Date().getFullYear();
  const companyCountry = userData?.entity?.country || '';

  const [initialValues, setInitialValues] = useState({
    founders: [
      { firstName: '', lastName: '', role: '', email: '', yearInCompany: companyYearFounded }
    ],
    keyTeamMembers: [],
    responsibilities: {
      generalManagement: '',
      operations: '',
      legal: '',
      finance: '',
      productsServices: '',
      hrRecruiting: '',
      marketing: '',
      sales: '',
      it: '',
    },
    offices: [
      { location: 'Remote Employees', country: companyCountry, employees: 0 },
      { location: 'Office 1', country: companyCountry, employees: 0 }
    ],
  });

  useEffect(() => {
    if (userData?.entity?.know_yourself_response) {
      const { published, draft } = userData.entity.know_yourself_response;
      const relevantDraftData = {
        founders: draft?.founders || [],
        keyTeamMembers: draft?.keyTeamMembers || [],
        responsibilities: draft?.responsibilities || {},
        offices: draft?.offices || []
      };
      const relevantPublishedData = {
        founders: published?.founders || [],
        keyTeamMembers: published?.keyTeamMembers || [],
        responsibilities: published?.responsibilities || {},
        offices: published?.offices || []
      };
  
      const isDataEmpty = (data) => {
        return Object.keys(data).length === 0 || 
               Object.values(data).every(value => 
                 value === '' || 
                 (Array.isArray(value) && value.length === 0) ||
                 (typeof value === 'object' && Object.keys(value).length === 0)
               );
      };
  
      const updateOffices = (offices) => {
        return offices.map(office => ({
          ...office,
          country: office.country || companyCountry
        }));
      };
  
      const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);
  
      if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
        setDataStatus('draft');
        setInitialValues({
          ...relevantDraftData,
          founders: relevantDraftData.founders.map(founder => ({ ...founder, yearInCompany: companyYearFounded })),
          offices: updateOffices(relevantDraftData.offices)
        });
      } else if (!isDataEmpty(relevantPublishedData)) {
        setDataStatus('published');
        setInitialValues({
          ...relevantPublishedData,
          founders: relevantPublishedData.founders.map(founder => ({ ...founder, yearInCompany: companyYearFounded })),
          offices: updateOffices(relevantPublishedData.offices)
        });
      } else {
        setDataStatus('new');
        setInitialValues({
          founders: [{ firstName: '', lastName: '', role: '', email: '', yearInCompany: companyYearFounded }],
          keyTeamMembers: [],
          responsibilities: {
            generalManagement: '',
            operations: '',
            legal: '',
            finance: '',
            productsServices: '',
            hrRecruiting: '',
            marketing: '',
            sales: '',
            it: '',
          },
          offices: [
            { location: 'Remote Employees', country: companyCountry, employees: 0 },
            { location: 'Office 1', country: companyCountry, employees: 0 }
          ],
        });
      }
    }
  }, [userData, companyYearFounded, companyCountry]);

  if (userDataLoading || schemaLoading) {
    return <div>{t('Loading...')}</div>;
  }

  const validationSchema = Yup.object({
    founders: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(t('First Name is required')),
        lastName: Yup.string().required(t('Last Name is required')),
        role: Yup.string().required(t('Role is required')),
        email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
        yearInCompany: Yup.number().required(t('Year is required'))
      })
    ).min(1, t('At least one founder is required')),
    keyTeamMembers: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(t('First Name is required')),
        lastName: Yup.string().required(t('Last Name is required')),
        role: Yup.string().required(t('Role is required')),
        email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
        yearInCompany: Yup.number().required(t('Year is required'))
      })
    ),
    offices: Yup.array().of(
      Yup.object().shape({
        location: Yup.string().required(t('Location is required')),
        country: Yup.string().required(t('Country is required')),
        employees: Yup.number().min(0, t('Number of employees cannot be negative')).required(t('Number of employees is required'))
      })
    ).min(2, t('At least two offices are required')),
    responsibilities: Yup.object().shape({
      generalManagement: Yup.string().required(t('Required')),
      operations: Yup.string().required(t('Required')),
      legal: Yup.string().required(t('Required')),
      finance: Yup.string().required(t('Required')),
      productsServices: Yup.string().required(t('Required')),
      hrRecruiting: Yup.string().required(t('Required')),
      marketing: Yup.string().required(t('Required')),
      sales: Yup.string().required(t('Required')),
      it: Yup.string().required(t('Required')),
    })
  });

  const preprocessFormData = (values) => {
    console.log("Pre-processing form data:", JSON.stringify(values, null, 2));
    const processedValues = JSON.parse(JSON.stringify(values));
    processedValues.offices = processedValues.offices.map(office => ({
      ...office,
      country: typeof office.country === 'object' ? office.country.name : office.country
    }));
    console.log("Processed form data:", JSON.stringify(processedValues, null, 2));
    return processedValues;
  };

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const processedValues = preprocessFormData(values);
      await handleSubmit({ responses: processedValues });
      setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
      setDataStatus('published');
      await handleDraftSubmit({ responses: processedValues });

      setTimeout(() => {
        navigate('/know-yourself/investment');
      }, 1000);
    } catch (error) {
      setNotification({ 
        show: true, 
        type: 'error', 
        message: 'Failed to save data', 
        errors: [error.message] 
      });
    } finally {
      setSubmitting(false);
    }
  };
  
  const onDraftSubmit = async (values, { setSubmitting }) => {
    try {
      const processedValues = {
        ...values,
        offices: values.offices.map(office => ({
          ...office,
          country: office.country || companyCountry
        }))
      };
      await handleDraftSubmit({ responses: processedValues });
      setNotification({ show: true, type: 'success', message: 'Draft saved successfully!' });
      setDataStatus('draft');
    } catch (error) {
      setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Notification
        show={notification.show}
        setShow={(show) => setNotification({ ...notification, show })}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />
      <Progress />
      <div className="data-status-banner mt-4 mb-4">
        {dataStatus === 'published' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is the last saved and published version.')}
          </span>
        )}
        {dataStatus === 'draft' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is a draft version. Some of the information on this page might not have been published yet.')}
          </span>
        )}
        {dataStatus === 'new' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('There is no prior version of this page. Please manually save or save as draft below.')}
          </span>
        )}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isSubmitting, validateForm }) => {
          const handleSubmit = async (e) => {
            e.preventDefault();
            const errors = await validateForm(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values, { setSubmitting: () => {} });
            } else {
              const errorMessages = Object.entries(errors).flatMap(([field, message]) => 
                typeof message === 'string' ? [`${t(`KnowYourselfWizard.${field}`)}: ${message}`] : []
              );
              setNotification({
                show: true,
                type: 'error',
                message: 'Failed to save data due to validation errors:',
                errors: errorMessages
              });
            }
          };

          return (
            <Form onSubmit={handleSubmit}>
          
          {/* Founders section */}
          <div className="mb-6">
            <h2 className="text-primary-color">{t('Team')}</h2>
            <h3 className="mt-6 text-primary-color">{t('Founders')}</h3>
            <p className="text-gray-600">{t('Please provide the information for all founders of your organization.')}</p>
            <div className="grid grid-cols-1 m:grid-cols-2 lg:grid-cols-2 gap-4">
            {values.founders.map((founder, index) => (
                <EmployeeCard
                  key={index}
                  employee={founder}
                  index={index}
                  type="founder"
                  companyYearFounded={companyYearFounded}
                  onRemove={() => {
                    if (values.founders.length > 1) {
                      const newFounders = values.founders.filter((_, i) => i !== index);
                      setFieldValue('founders', newFounders);
                    }
                  }}
                  setFieldValue={setFieldValue}  // Pass the original setFieldValue function
                />
              ))}
              <EmployeeCardAdd 
                onAdd={() => {
                  setFieldValue('founders', [
                    ...values.founders,
                    { firstName: '', lastName: '', role: '', email: '', yearInCompany: companyYearFounded }
                  ]);
                }} 
                type="founder" 
              />
            </div>
            <ErrorMessage name="founders" component="div" className="text-red-500" />
          </div>
  
          {/* Key Team Members section */}
          <div className="mb-6">
            <h3 className="text-primary-color mt-8">{t('Additional Key Team Members')}</h3>
            <p className="text-gray-600">{t('Please provide the information for additional key team members of your organization.')}</p>
            <div className="grid grid-cols-1 m:grid-cols-2 lg:grid-cols-2 gap-4">
              {values.keyTeamMembers.map((member, index) => (
                <EmployeeCard
                  key={index}
                  employee={member}
                  index={index}
                  type="keyTeamMember"
                  onRemove={() => {
                    const newKeyTeamMembers = values.keyTeamMembers.filter((_, i) => i !== index);
                    setFieldValue('keyTeamMembers', newKeyTeamMembers);
                  }}
                  setFieldValue={setFieldValue}
                />
              ))}
              <EmployeeCardAdd 
                onAdd={() => {
                  setFieldValue('keyTeamMembers', [
                    ...values.keyTeamMembers,
                    { firstName: '', lastName: '', role: '', email: '', yearInCompany: new Date().getFullYear() }
                  ]);
                }} 
                type="keyTeamMember" 
              />
            </div>
            <ErrorMessage name="keyTeamMembers" component="div" className="text-red-500" />
          </div>
  
          {/* Responsibilities section */}
          <div className="mb-6">
            <h3 className="text-primary-color mt-8">{t('Responsibilities')}</h3>
            <p className="text-gray-600">{t('Please state who of your founders and key team members is primarily responsible for the following areas.')}</p>
            <div className="grid grid-cols-2 gap-4">
              {Object.keys(values.responsibilities).map((responsibility, index) => (
                <div key={index}>
                  <label className="block text-sm font-medium text-gray-700">{t(responsibility)}</label>
                  <Field as="select" name={`responsibilities.${responsibility}`} className="mt-1 block w-full border-gray-300 rounded-md">
                    <option value="">{t('Select team member')}</option>
                    {[...values.founders, ...values.keyTeamMembers].map((person, i) => (
                      <option key={i} value={`${person.firstName} ${person.lastName}`}>{`${person.firstName} ${person.lastName}`}</option>
                    ))}
                  </Field>
                  <ErrorMessage name={`responsibilities.${responsibility}`} component="div" className="text-red-500" />
                </div>
              ))}
            </div>
          </div>
  
          {/* Offices section */}
          <div className="mb-6">
            <h3 className="text-primary-color mt-8">{t('Offices')}</h3>
            <p className="text-gray-600">{t('Please provide information on all your offices and the number of employees working there, as well as the number of employees working remotely.')}</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              {values.offices.map((office, index) => (
                <OfficeCard
                  key={index}
                  office={office}
                  index={index}
                  onRemove={() => {
                    if (values.offices.length > 2) {
                      const newOffices = values.offices.filter((_, i) => i !== index);
                      setFieldValue('offices', newOffices);
                    }
                  }}
                  setFieldValue={setFieldValue}
                  companyCountry={companyCountry}
                />
              ))}
              <OfficeCardAdd 
                onAdd={() => {
                  setFieldValue('offices', [
                    ...values.offices,
                    { location: `Office ${values.offices.length}`, country: companyCountry, employees: 0 }
                  ]);
                }}
              />
            </div>
            <ErrorMessage name="offices" component="div" className="text-red-500" />
          </div>

          {submitError && <div className="error text-red-500">{submitError}</div>}

          <div className="mt-6 flex items-center justify-end gap-x-6">
                <button 
                  type="button" 
                  className="text-sm text-gray-900"
                  onClick={() => navigate('/know-yourself/product')}
                >
                  {t('cancel')}
                </button>
                <button
                  type="button"
                  onClick={() => onDraftSubmit(values, { setSubmitting: () => {} })}
                  disabled={isSubmitting}
                  className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  {t('Save as Draft')}
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                >
                  {isSubmitting ? t('saving') : t('save')}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default KnowYourselfWizardTeam;