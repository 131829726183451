// src/components/wizard_components/Checkthumbs.js
import React from 'react';
import PropTypes from 'prop-types';
import { FaQuestionCircle } from 'react-icons/fa';
import './Checkthumbs.css'; // Ensure the CSS file is imported

const Checkthumbs = ({ name, options, value = [], onChange, onQuestionClick }) => {
  return (
    <div className="checkthumbs">
      {options.map((option) => (
        <div key={option.value} className="relative">
          <label 
            className={`checkthumb ${value.includes(option.value) ? 'selected' : ''}`} 
            style={{
              backgroundImage: `url(${value.includes(option.value) ? option.svg.active : option.svg.default})`
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundImage = `url(${option.svg.hover})`}
            onMouseLeave={(e) => e.currentTarget.style.backgroundImage = `url(${value.includes(option.value) ? option.svg.active : option.svg.default})`}
          >
            <input
              type="checkbox"
              name={name}
              value={option.value}
              checked={value.includes(option.value)}
              onChange={() => {
                const newValue = value.includes(option.value)
                  ? value.filter(val => val !== option.value)
                  : [...value, option.value];
                onChange(newValue);
              }}
              className="hidden"
            />
            <div className="checkthumb-content">
              <span className="text">{option.label}</span>
            </div>
          </label>
          {onQuestionClick && (
            <FaQuestionCircle 
              className={`absolute top-[15px] right-[16px] text-gray-600 hover:text-gray-900 cursor-pointer ${value.includes(option.value) ? 'text-white' : ''}`}
              onClick={() => onQuestionClick(option.value)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

Checkthumbs.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      svg: PropTypes.shape({
        default: PropTypes.string.isRequired,
        hover: PropTypes.string.isRequired,
        active: PropTypes.string.isRequired,
      }).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onQuestionClick: PropTypes.func, // Make this prop optional
};

export default Checkthumbs;
