import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const steps = [
  { id: 'KnowYourselfProgress.section1', name: 'KnowYourselfProgress.industry', route: '/know-yourself/industry' },
  { id: 'KnowYourselfProgress.section2', name: 'KnowYourselfProgress.market', route: '/know-yourself/market' },
  { id: 'KnowYourselfProgress.section3', name: 'KnowYourselfProgress.businessModel', route: '/know-yourself/business-model' },
  { id: 'KnowYourselfProgress.section4', name: 'KnowYourselfProgress.productsServices', route: '/know-yourself/product' },
  { id: 'KnowYourselfProgress.section5', name: 'KnowYourselfProgress.team', route: '/know-yourself/team' },
  { id: 'KnowYourselfProgress.section6', name: 'KnowYourselfProgress.funding', route: '/know-yourself/investment' }
];

const Progress = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {location.pathname === step.route ? (
              <a
                href={step.route}
                className="flex flex-col border-l-4 border-primary-color py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-10 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-xs font-medium text-primary-color">{t(step.id)}</span>
                <span className="text-xs font-bold text-black">{t(step.name)}</span>
              </a>
            ) : (
              <a
                href={step.route}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-6 md:pl-0 md:pt-4"
              >
                <span className="text-xs font-medium text-gray-500 group-hover:text-primary-color">{t(step.id)}</span>
                <span className="text-xs font-bold text-gray-500 group-hover:text-gray-700">{t(step.name)}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Progress;
