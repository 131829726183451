import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { PaintBrushIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

const CircularProgress = ({ published, draft, total }) => {
  const publishedPercentage = (published / total) * 100;
  const draftPercentage = (draft / total) * 100;
  const totalPercentage = publishedPercentage + draftPercentage;

  return (
    <div className="relative w-10 h-10">
      <svg className="w-full h-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        {/* Background Circle */}
        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200" strokeWidth="3"></circle>
        {/* Draft Progress Circle */}
        <circle 
          cx="18" 
          cy="18" 
          r="16" 
          fill="none" 
          className="stroke-current text-yellow-400" 
          strokeWidth="3" 
          strokeDasharray="100" 
          strokeDashoffset={100 - totalPercentage} 
          strokeLinecap="round">
        </circle>
        {/* Published Progress Circle */}
        <circle 
          cx="18" 
          cy="18" 
          r="16" 
          fill="none" 
          className="stroke-current text-green-500" 
          strokeWidth="3" 
          strokeDasharray="100" 
          strokeDashoffset={100 - publishedPercentage} 
          strokeLinecap="round">
        </circle>
      </svg>
      <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <span className="text-center text-xs font-bold text-green-500">{Math.round(publishedPercentage)}%</span>
      </div>
    </div>
  );
};

const TotalProgressBar = ({ published, draft, total }) => {
  const publishedPercentage = (published / total) * 100;
  const draftPercentage = (draft / total) * 100;
  const totalCompletedPercentage = publishedPercentage + draftPercentage;
  const remainingPercentage = 100 - totalCompletedPercentage;

  return (
    <div className="space-y-3">
      <div className="flex justify-between mb-2">
        <div className="inline-block py-0.5 px-1.5 bg-green-50 border border-green-200 text-xs font-medium text-green-600 rounded-lg">
          Published: {publishedPercentage.toFixed(1)}%
        </div>
        <div className="inline-block py-0.5 px-1.5 bg-yellow-50 border border-yellow-200 text-xs font-medium text-yellow-600 rounded-lg">
          Draft: {draftPercentage.toFixed(1)}%
        </div>
        <div className="inline-block py-0.5 px-1.5 bg-gray-50 border border-gray-200 text-xs font-medium text-gray-600 rounded-lg">
          Remaining: {remainingPercentage.toFixed(1)}%
        </div>
      </div>
      <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden" role="progressbar" aria-valuenow={totalCompletedPercentage} aria-valuemin="0" aria-valuemax="100">
        <div className="absolute top-0 left-0 h-full bg-yellow-400 transition-all duration-500" style={{ width: `${totalCompletedPercentage}%` }}></div>
        <div className="absolute top-0 left-0 h-full bg-green-600 transition-all duration-500" style={{ width: `${publishedPercentage}%` }}></div>
      </div>
    </div>
  );
};

const ISMSOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [expandedSections, setExpandedSections] = useState({});
  const [pageStates, setPageStates] = useState({});

  const toggleSection = (sectionId) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  const sections = useMemo(() => [
    {
      id: "introduction",
      name: "Introduction",
      pages: [
        { id: "welcome", name: "Welcome" },
        { id: "what-do-i-need", name: "What do I need?" },
        { id: "references-and-materials", name: "References and additional materials" },
        { id: "core-aspects", name: "Core aspects of information security" },
      ]
    },
    {
      id: "organizational-context-and-scope",
      name: "Organizational Context and Scope",
      pages: [
        { id: "organization-security-perspective", name: "Organization from a security perspective" },
        { id: "understanding-organization-context", name: "Understanding the organization and its context" },
        { id: "external-context", name: "External context of the organization" },
        { id: "internal-context", name: "Internal context of the organization" },
        { id: "stakeholder-needs", name: "Understanding the needs and expectations of interested parties" },
        { id: "stakeholder-requirements", name: "What are your stakeholder requirements regarding information security?" },
        { id: "prioritize-requirements", name: "How do you prioritize your stakeholders requirements regarding information security?" },
        { id: "getting-ready", name: "Getting ready for the Information Security Management System" },
      ]
    },
    {
      id: "leadership-commitment",
      name: "Leadership Commitment",
      pages: [
        { id: "leadership", name: "Leadership" },
        { id: "top-management-responsibilities", name: "Top-Management and team responsibilities" },
        { id: "commitment-charta", name: "Signing Charta for commitment and taking over responsibility" },
        { id: "security-policy", name: "Information Security Policy" },
        { id: "next-steps", name: "Next Steps: communication and review" },
      ]
    },
    {
      id: "risk-policy",
      name: "Risk Policy",
      pages: [
        { id: "risks", name: "Risks" },
        { id: "risk-actions", name: "Actions to address risks and opportunities" },
        { id: "risk-policy", name: "Information security risk policy" },
        { id: "risk-evaluation-criteria", name: "Risk evaluation criteria scoring system" },
        { id: "risk-impact-criteria", name: "Risk impact criteria" },
        { id: "protection-classification", name: "Protection Classification / Valuation criteria" },
        { id: "risk-acceptance", name: "Risk acceptance" },
        { id: "risk-policy-review", name: "Risk policy review" },
      ]
    },
    {
      id: "risk-assessment",
      name: "Risk Assessment",
      pages: [
        { id: "risk-assessment", name: "Information security risk assessment" },
        { id: "dream-team", name: "Assemble a dream team" },
      ]
    },
    {
      id: "risk-identification",
      name: "Risk Identification",
      pages: [
        { id: "protection-targets", name: "What are we protecting against?" },
        { id: "primary-assets-processes", name: "What are we protecting? Identification of primary assets: processes" },
        { id: "risk-events", name: "Identification of risk events" },
        { id: "primary-assets-subprocesses", name: "Identification of primary assets: subprocesses" },
        { id: "primary-assets-information", name: "Identification of primary assets: information" },
        { id: "supporting-assets", name: "Identification of supporting assets" },
        { id: "asset-list", name: "List of all primary and supporting assets" },
        { id: "threats", name: "Threats to your assets" },
        { id: "vulnerabilities", name: "Vulnerabilities to your assets" },
        { id: "risk-scenarios", name: "Risk scenarios" },
      ]
    },
    {
      id: "risk-analysis",
      name: "Risk Analysis",
      pages: [
        { id: "analyzing-risks", name: "Analyzing the risks" },
        { id: "impact-assessment", name: "Assessment of impact of consequences" },
        { id: "incident-likelihood", name: "Assessment of incident likelihood" },
        { id: "risk-level-determination", name: "Risk level determination" },
      ]
    },
    {
      id: "risk-evaluation",
      name: "Risk Evaluation",
      pages: [
        { id: "risk-evaluation", name: "Risk evaluation" },
        { id: "risk-prioritization", name: "Risk prioritization and risk owners" },
        { id: "prioritized-risk-list", name: "Prioritized list of risks" },
      ]
    },
    {
      id: "risk-treatment",
      name: "Risk Treatment",
      pages: [
        { id: "risk-treatment", name: "Risk treatment" },
        { id: "risk-avoidance", name: "Risk avoidance" },
        { id: "risk-retention", name: "Full risk retention" },
        { id: "risk-controls", name: "Risk controls for risk modification" },
        { id: "risk-sharing", name: "Risk sharing" },
        { id: "treatment-effects", name: "The assumed effects of your risk treatment" },
        { id: "statement-of-applicability", name: "Statement of Applicability" },
      ]
    },
    {
      id: "security-objectives",
      name: "Information Security Objectives",
      pages: [
        { id: "security-objectives", name: "Information Security Objectives" },
      ]
    },
    {
      id: "implementation",
      name: "Implementation",
      pages: [
        { id: "implement-controls", name: "Implement risk control measures" },
      ]
    },
    {
      id: "operation",
      name: "Operation",
      pages: [
        { id: "operative-documentation", name: "Plan operative documentation measures" },
      ]
    },
    {
      id: "performance-evaluation",
      name: "Performance Evaluation",
      pages: [
        { id: "performance-evaluation", name: "Plan performance evaluation dates" },
      ]
    },
  ], []);

    // Generate random states for all pages on component mount
    useEffect(() => {
      const statuses = ['empty', 'draft', 'complete'];
      const initialStates = {};
      sections.forEach(section => {
        section.pages.forEach(page => {
          initialStates[page.id] = statuses[Math.floor(Math.random() * statuses.length)];
        });
      });
      setPageStates(initialStates);
    }, [sections]);
  
    const getStepStatus = useCallback((pageId) => {
      return pageStates[pageId] || 'empty';
    }, [pageStates]);
  
    const renderStepIcon = useCallback((status) => {
      switch (status) {
        case 'complete':
          return (
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-600">
              <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          );
        case 'draft':
          return (
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-yellow-600">
              <PaintBrushIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          );
        default:
          return (
            <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300">
              <span className="text-gray-500 text-xl"></span>
            </span>
          );
      }
    }, []);
  
    const getSectionProgress = useCallback((pages) => {
      let published = 0;
      let draft = 0;
      pages.forEach(page => {
        const status = getStepStatus(page.id);
        if (status === 'complete') published++;
        else if (status === 'draft') draft++;
      });
      return { published, draft, total: pages.length };
    }, [getStepStatus]);
  
    const getTotalProgress = useCallback(() => {
      let published = 0;
      let draft = 0;
      let total = 0;
      sections.forEach(section => {
        const progress = getSectionProgress(section.pages);
        published += progress.published;
        draft += progress.draft;
        total += progress.total;
      });
      return { published, draft, total };
    }, [sections, getSectionProgress]);
  
    const totalProgress = getTotalProgress();
  
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">{t('ISMS.overview')}</h1>
        <div className="mb-8">
          <TotalProgressBar 
            published={totalProgress.published} 
            draft={totalProgress.draft} 
            total={totalProgress.total} 
          />
        </div>
        <div className="space-y-4">
          {sections.map((section) => {
            const progress = getSectionProgress(section.pages);
            return (
              <div key={section.id} className="bg-white shadow rounded-lg overflow-hidden">
                <button
                  className="w-full text-left px-6 py-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100"
                  onClick={() => toggleSection(section.id)}
                >
                  <div className="flex items-center">
                    <CircularProgress 
                      published={progress.published} 
                      draft={progress.draft} 
                      total={progress.total} 
                    />
                    <span className="text-lg font-medium ml-4">{section.name}</span>
                  </div>
                  {expandedSections[section.id] ? (
                    <ChevronDownIcon className="h-5 w-5" />
                  ) : (
                    <ChevronRightIcon className="h-5 w-5" />
                  )}
                </button>
                {expandedSections[section.id] && (
                  <div>
                    {section.pages.map((page) => {
                      const status = getStepStatus(page.id);
                      return (
                        <div key={page.id} className="px-6 py-4 border-t border-gray-200 flex items-center justify-between">
                          <div className="flex items-center">
                            {renderStepIcon(status)}
                            <span className="ml-4">{page.name}</span>
                          </div>
                          <div>
                            <button
                              onClick={() => navigate(`/isms/${section.id}/${page.id}`)}
                              className="text-primary-color hover:text-secondary-color mr-4"
                              aria-label={t("ISMS.edit")}
                            >
                              <PencilSquareIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => {/* Handle delete */}}
                              className={`text-red-600 hover:text-red-800 ${status === 'empty' ? 'opacity-0 cursor-default' : ''}`}
                              aria-label={t("ISMS.delete")}
                              disabled={status === 'empty'}
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  
  export default ISMSOverview;