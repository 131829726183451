import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useTheme } from '@mui/material/styles';
import { getCountriesBySubregion } from '../constants/countryData';

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const MarketMap = ({ subregions }) => {
  const theme = useTheme();
  
  const getCountriesForSubregions = (subregions) => {
    return subregions.flatMap(subregion => 
      getCountriesBySubregion(subregion)
    ).map(country => country.codeNumeric);
  };

  const highlightedCountries = getCountriesForSubregions(subregions);

  return (
    <div className="rounded-lg overflow-hidden" style={{ position: 'relative', width: '100%', height: '0', paddingBottom: '50%' }}>
      <ComposableMap 
        projection="geoMercator"
        projectionConfig={{ 
          scale: 120,
          center: [0, -25]
        }}
        style={{ backgroundColor: '#e8f8fa' }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isHighlighted = highlightedCountries.includes(geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={isHighlighted ? "#2A9D8F" : '#D6D6DA'}
                  stroke="#FFFFFF"
                  strokeWidth={0.5}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default MarketMap;