// src/axios.js
import axios from 'axios';

axios.defaults.withCredentials = true;

const baseURL = process.env.REACT_APP_API_URL

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

export default axios;
