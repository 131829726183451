import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SparklesIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

// Define the structure of instructions using translation keys
export const wizardInstructionsData = {
  transparency_register: [
    {
      id: 'identification',
      titleKey: 'WizardInstructions.transparencyRegister.identification.title',
      contentKey: 'WizardInstructions.transparencyRegister.identification.content'
    },
    {
      id: 'userAccount',
      titleKey: 'WizardInstructions.transparencyRegister.userAccount.title',
      contentKey: 'WizardInstructions.transparencyRegister.userAccount.content'
    },
    {
      id: 'registration',
      titleKey: 'WizardInstructions.transparencyRegister.registration.title',
      contentKey: 'WizardInstructions.transparencyRegister.registration.content'
    },
    {
      id: 'verification',
      titleKey: 'WizardInstructions.transparencyRegister.verification.title',
      contentKey: 'WizardInstructions.transparencyRegister.verification.content'
    },
    {
      id: 'maintenance',
      titleKey: 'WizardInstructions.transparencyRegister.maintenance.title',
      contentKey: 'WizardInstructions.transparencyRegister.maintenance.content'
    }
  ],
  business_registration: [
    {
      id: 'documents',
      titleKey: 'WizardInstructions.businessRegistration.documents.title',
      contentKey: 'WizardInstructions.businessRegistration.documents.content'
    },
    {
      id: 'tradeOffice',
      titleKey: 'WizardInstructions.businessRegistration.tradeOffice.title',
      contentKey: 'WizardInstructions.businessRegistration.tradeOffice.content'
    },
    {
      id: 'fees',
      titleKey: 'WizardInstructions.businessRegistration.fees.title',
      contentKey: 'WizardInstructions.businessRegistration.fees.content'
    },
    {
      id: 'license',
      titleKey: 'WizardInstructions.businessRegistration.license.title',
      contentKey: 'WizardInstructions.businessRegistration.license.content'
    },
    {
      id: 'taxOffice',
      titleKey: 'WizardInstructions.businessRegistration.taxOffice.title',
      contentKey: 'WizardInstructions.businessRegistration.taxOffice.content'
    },
    {
      id: 'otherAuthorities',
      titleKey: 'WizardInstructions.businessRegistration.otherAuthorities.title',
      contentKey: 'WizardInstructions.businessRegistration.otherAuthorities.content'
    }
  ]
};

const InstructionStep = ({ titleKey, contentKey, isOpen, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-purple-100 rounded-lg">
      <button
        onClick={onClick}
        className="w-full px-4 py-3 flex items-center justify-between text-left"
      >
        <span className="font-medium text-sm text-gray-900">{t(titleKey)}</span>
        <ChevronDownIcon 
          className={`w-5 h-5 text-purple-500 transition-transform ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && (
        <div className="px-4 pb-3 text-sm text-gray-700 whitespace-pre-line">
          {t(contentKey)}
        </div>
      )}
    </div>
  );
};

const WizardInstructions = ({ section }) => {
  const { t } = useTranslation();
  const instructions = wizardInstructionsData[section];
  const [openStepId, setOpenStepId] = useState(instructions?.[0]?.id);

  if (!instructions) return null;

  return (
    <div className="bg-purple-50 rounded-lg p-4 mt-4">
      <div className="flex items-center gap-2 mb-4">
        <SparklesIcon className="w-5 h-5 text-purple-600" />
        <p className="text-purple-600 font-semibold text-base">
          {t('wizardInstructions')}
        </p>
      </div>
      <div className="space-y-2">
        {instructions.map((instruction) => (
          <InstructionStep
            key={instruction.id}
            titleKey={instruction.titleKey}
            contentKey={instruction.contentKey}
            isOpen={openStepId === instruction.id}
            onClick={() => setOpenStepId(
              openStepId === instruction.id ? null : instruction.id
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default WizardInstructions;