import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const TermsPage = () => {
  const { i18n, t } = useTranslation();
  const previousLanguage = useRef(i18n.language);

  useEffect(() => {
    if (previousLanguage.current !== i18n.language) {
      window.location.reload();
    }
    previousLanguage.current = i18n.language;
  }, [i18n.language]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <h1 className="text-3xl font-bold mb-6">{t('terms.title')}</h1>

          <section>
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section1')}</h2>
            <p>{t('terms.section1_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section2')}</h2>
            <p>{t('terms.section2_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section3')}</h2>
            <p>{t('terms.section3_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section4')}</h2>
            <p>{t('terms.section4_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section5')}</h2>
            <p>{t('terms.section5_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section6')}</h2>
            <p>{t('terms.section6_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section7')}</h2>
            <p>{t('terms.section7_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section8')}</h2>
            <p>{t('terms.section8_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section9')}</h2>
            <p>{t('terms.section9_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section10')}</h2>
            <p>{t('terms.section10_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section11')}</h2>
            <p>{t('terms.section11_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section12')}</h2>
            <p>{t('terms.section12_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section13')}</h2>
            <p>{t('terms.section13_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section14')}</h2>
            <p>{t('terms.section14_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section15')}</h2>
            <p>{t('terms.section15_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section16')}</h2>
            <p>{t('terms.section16_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section17')}</h2>
            <p>{t('terms.section17_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.section18')}</h2>
            <p>{t('terms.section18_text')}</p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold mb-0 pb-1">{t('terms.contact')}</h2>
            <p>{t('terms.contact_text')}</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
