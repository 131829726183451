import React from 'react';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, Footer, AlignmentType } from 'docx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useWizard } from '../../wizard_components/WizardContext';
import {
    BOOLEAN_VALUES,
    convertStorageToDocument,
    convertUIToStorage
} from '../../../constants/translationMappings';

const ShareholdersAgreementGenerator = () => {
    const { t } = useTranslation();
    const { wizardData } = useWizard();

        const { published } = wizardData;
        const company = published?.company || {};

        // Convert the stored values to document-ready format
        const rawSha = published?.shareholdersAgreement || {};
        const sha = {
            stockOptionProgram: convertStorageToDocument(rawSha.stockOptionProgram || BOOLEAN_VALUES.NO, 'en'),
            stockOptionPercentage: rawSha.stockOptionPercentage || '10',
            shareholderBindingYears: rawSha.shareholderBindingYears || '2',
            callOptionOnExit: convertStorageToDocument(rawSha.callOptionOnExit || BOOLEAN_VALUES.NO, 'en'),
            controlInformationRights: convertStorageToDocument(rawSha.controlInformationRights || BOOLEAN_VALUES.NO, 'en'),
            shareholderInMarriage: convertStorageToDocument(rawSha.shareholderInMarriage || BOOLEAN_VALUES.NO, 'en')
        };

        const aoa = published?.articlesOfAssociation || {};
        const founders = published?.founders?.founders || [];

        // Initialize numbering configurations
        const numberingConfigs = [
            {
                reference: "decimalNumbering",
                levels: [
                    {
                        level: 0,
                        format: "decimal",
                        text: "%1.",
                        alignment: AlignmentType.LEFT,
                        style: {
                            paragraph: {
                                indent: { left: 360, hanging: 360 },
                            },
                        },
                    },
                    {
                        level: 1,
                        format: "decimal",
                        text: "%1.%2.",
                        alignment: AlignmentType.LEFT,
                        style: {
                            paragraph: {
                                indent: { left: 720, hanging: 360 },
                            },
                        },
                    },
                    {
                        level: 2,
                        format: "decimal",
                        text: "%1.%2.%3.",
                        alignment: AlignmentType.LEFT,
                        style: {
                            paragraph: {
                                indent: { left: 1080, hanging: 360 },
                            },
                        },
                    },
                ],
            },
        ];

        // Counter for alphabetical numbering references
        let alphabeticalNumberingCounter = 1;

        // Function to get a new alphabetical numbering reference
        function getAlphabeticalNumberingReference() {
            const reference = `alphabeticalNumbering${alphabeticalNumberingCounter++}`;
            numberingConfigs.push({
                reference,
                levels: [
                    {
                        level: 0,
                        format: "lowerLetter",
                        text: "(%1)",
                        alignment: AlignmentType.LEFT,
                        style: {
                            paragraph: {
                                indent: { left: 720, hanging: 360 },
                            },
                        },
                    },
                    {
                        level: 1,
                        format: "lowerRoman",
                        text: "(%2)",
                        alignment: AlignmentType.LEFT,
                        style: {
                            paragraph: {
                                indent: { left: 1080, hanging: 360 },
                            },
                        },
                    },
                ],
            });
            return reference;
        }

        const generateShareholdersAgreement = async () => {
            const { published } = wizardData;
            const company = published?.company || {};
            const sha = {
                stockOptionProgram: 'Yes',
                stockOptionPercentage: '10',
                shareholderBindingYears: '2',
                callOptionOnExit: 'Yes',
                controlInformationRights: 'Yes',
                shareholderInMarriage: 'Yes',
                ...published?.shareholdersAgreement
            };

            const aoa = published?.articlesOfAssociation || {};

            const founders = published?.founders?.founders || [];

            const currentDateTime = new Date();
            const formattedDateTime = currentDateTime.toLocaleString('de-DE');

            // Define sections with inclusion conditions
            const sections = [
                {
                    title: "Virtual employee participation program",
                    include: convertUIToStorage(sha.stockOptionProgram) === BOOLEAN_VALUES.YES,
                    generator: generateVirtualEmployeeParticipationProgram,
                },
                {
                    title: "Disposal of shares, subscription rights",
                    include: true,
                    generator: generateDisposalOfShares,
                },
                {
                    title: "Call option on the departure of a founding shareholder",
                    include: convertUIToStorage(sha.callOptionOnExit) === BOOLEAN_VALUES.YES,
                    generator: generateCallOptionOnDeparture,
                },
                {
                    title: "Pre-acquisition right",
                    include: true,
                    generator: generatePreAcquisitionRight,
                },
                {
                    title: "Tag Along",
                    include: true,
                    generator: generateTagAlong,
                },
                {
                    title: "Drag-Along",
                    include: true,
                    generator: generateDragAlong,
                },
                {
                    title: "Control and information rights",
                    include: convertUIToStorage(sha.controlInformationRights) === BOOLEAN_VALUES.YES,
                    generator: generateControlAndInformationRights,
                },
                {
                    title: "Matrimonial property regime",
                    include: convertUIToStorage(sha.shareholderInMarriage) === BOOLEAN_VALUES.YES,
                    generator: generateMatrimonialPropertyRegime,
                },
                {
                    title: "Term, termination",
                    include: true,
                    generator: generateTermAndTermination,
                },
                {
                    title: "Accession to the shareholders' agreement",
                    include: true,
                    generator: generateAccessionToShareholdersAgreement,
                },
                {
                    title: "Other agreements",
                    include: true,
                    generator: generateOtherAgreements,
                },
                {
                    title: "Final provisions",
                    include: true,
                    generator: generateFinalProvisions,
                },
            ];

            // Assign section numbers and build sectionNumbers mapping
            let sectionNumber = 1;
            const sectionNumbers = {};

            for (const section of sections) {
                if (section.include) {
                    sectionNumbers[section.title] = sectionNumber;
                    sectionNumber++;
                }
            }

            // Generate document content
            const docContent = [
                ...generatePreamble(company, aoa),
            ];

            for (const section of sections) {
                if (section.include) {
                    docContent.push(...section.generator(sha, sectionNumbers));
                }
            }

            // Create the document
            const doc = new Document({
                styles: {
                    paragraphStyles: [
                        {
                            id: "Normal",
                            name: "Normal",
                            basedOn: "Normal",
                            quickFormat: true,
                            run: {
                                font: "Arial",
                                size: 22,
                            },
                            paragraph: {
                                spacing: { after: 200 },
                                indent: { left: 300 },
                            },
                        },
                        {
                            id: "Heading1",
                            name: "Heading 1",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                bold: true,
                                size: 32,
                            },
                            paragraph: {
                                spacing: {
                                    after: 300,
                                },
                                alignment: AlignmentType.CENTER,
                            },
                        },
                        {
                            id: "Heading2",
                            name: "Heading 2",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                bold: true,
                                size: 28,
                            },
                            paragraph: {
                                spacing: {
                                    before: 400,
                                    after: 250,
                                },
                                indent: { left: 0 },
                            },
                        },
                        {
                            id: "Heading3",
                            name: "Heading 3",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                bold: true,
                                size: 24,
                            },
                            paragraph: {
                                spacing: {
                                    after: 250,
                                },
                                indent: { left: 0 },
                            },
                        },
                    ],
                },
                numbering: {
                    config: numberingConfigs,
                },
                sections: [{
                    properties: {},
                    children: docContent,
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: `Exported from Cenedril at ${formattedDateTime}`,
                                            font: "Arial",
                                            size: 22,
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },
                }],
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, "Shareholder_Agreement.docx");
        };

        // Generate Preamble
        const generatePreamble = (company, aoa) => {
            return [
                new Paragraph({
                    text: "Shareholders' Agreement",
                    heading: HeadingLevel.HEADING_1,
                }),
                new Paragraph({
                    text: "Preamble",
                    heading: HeadingLevel.HEADING_2,
                }),
                new Paragraph({
                    text: `The Company is a limited liability company validly established and existing under the laws of the Federal Republic of Germany. ${aoa.companyPurpose || '<Company Purpose>'}`,
                    style: "Normal",
                }),
                new Paragraph({
                    text: `The company was founded on ${company.foundingDate || '<Founding Date>'} under HRB ${company.hrbNumber || '<HRB Number>'}. The existing shareholders are the sole shareholders of the company.`,
                    style: "Normal",
                }),
                new Paragraph({
                    text: `The parties wish to supplement their rights and obligations to each other, in addition to the existing provisions in the articles of association, within the framework of this shareholders' agreement.`,
                    style: "Normal",
                }),
                new Paragraph({
                    text: `Having said this, the parties agree as follows:`,
                    style: "Normal",
                }),
            ];
        };

        // Generate Section 1: Virtual Employee Participation Program (Conditional)
        const generateVirtualEmployeeParticipationProgram = (sha, sectionNumbers) => {
            return [
                new Paragraph({
                    text: `Virtual employee participation program`,
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: `The parties hereby agree on the introduction of a virtual employee participation program for the participation of members of the management, employees, advisory board members and/or consultants of the company, in accordance with the provisions still to be agreed in detail by the shareholders with the approval of a simple majority of the voting rights of the shareholders (“shareholder majority”). The economic effects of the virtual employee participation program in the amount of up to ${sha.stockOptionPercentage} percent shall be borne by the shareholders among themselves pro rata in proportion to the shares they hold in the company. The parties will mutually agree on the details of a virtual employee participation programme to be set up on the basis of a tax-optimized structure for all parties involved with the consent of the majority of investors.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 2: Disposal of Shares, Subscription Rights
        const generateDisposalOfShares = (sha, sectionNumbers) => {
            const hasCallOption = convertUIToStorage(sha.callOptionOnExit) === BOOLEAN_VALUES.YES;
            const preAcquisitionSectionNumber = sectionNumbers["Pre-acquisition right"] || 'X';
            const tagAlongSectionNumber = sectionNumbers["Tag Along"] || 'X';
            const dragAlongSectionNumber = sectionNumbers["Drag-Along"] || 'X';
            const accessionSectionNumber = sectionNumbers["Accession to the shareholders' agreement"] || 'X';
            const callOptionSectionNumber = sectionNumbers["Call option on the departure of a founding shareholder"] || 'X';
        
            // Helper function to build the references text
            const buildReferencesText = () => {
                const references = [
                    hasCallOption ? `Section ${callOptionSectionNumber} (call option on the departure of a founding shareholder)` : null,
                    `Section ${preAcquisitionSectionNumber} (pre-acquisition right)`,
                    `Section ${tagAlongSectionNumber} (tag-along right)`,
                    `Section ${dragAlongSectionNumber} (drag-along)`,
                    `Section ${accessionSectionNumber} (accession to the shareholders' agreement)`
                ].filter(ref => ref !== null);
        
                return references.join(', ');
            };
        
            // Helper function for the second paragraph's text
            const buildSecondParagraphText = () => {
                if (hasCallOption) {
                    return `If a founding shareholder disposes of the shares in the company held by him within a period of ${sha.shareholderBindingYears} years from the notarization of this shareholders' agreement, the above Section ${sectionNumbers["Disposal of shares, subscription rights"]}.1 shall not apply, with the exception of a disposal in accordance with Section ${callOptionSectionNumber} below (call option upon the withdrawal of a founding shareholder), and the other shareholders shall not be obliged to give their consent to the disposal.`;
                } else {
                    return `If a founding shareholder disposes of the shares in the company held by him within a period of ${sha.shareholderBindingYears} years from the notarization of this shareholders' agreement, the above Section ${sectionNumbers["Disposal of shares, subscription rights"]}.1 shall not apply, and the other shareholders shall not be obliged to give their consent to the disposal.`;
                }
            };
        
            return [
                new Paragraph({
                    text: `Disposal of shares, subscription rights`,
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: `The shareholders are obliged to give their consent to the disposal of shares by the company in accordance with the provisions set out in the Articles of Association at the shareholders' meeting, insofar as a shareholder disposes of his shares in accordance with the provisions of this shareholders' agreement, in particular ${buildReferencesText()}.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: buildSecondParagraphText(),
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `The shareholders are also obliged to give their consent to disposals by the investors of shares in the company to companies affiliated with the investors within the meaning of Section 15 AktG. Sections ${preAcquisitionSectionNumber} (pre-acquisition right) and ${tagAlongSectionNumber} (tag-along right) below do not apply to these disposals.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `Each shareholder has the right, but not the obligation, to participate in further capital increases of the company pro rata in accordance with his participation in the share capital of the company by acquiring a correspondingly required percentage of newly issued shares in the company in order to maintain his pro rata participation in the share capital of the company in accordance with this shareholders' agreement (as amended from time to time) ("subscription right").`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 3: Call Option on the Departure of a Founding Shareholder (Conditional)
        const generateCallOptionOnDeparture = (sha, sectionNumbers) => {
            const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
            const accessionSectionNumber = sectionNumbers["Accession to the shareholders' agreement"] || 'X';
            const preAcquisitionSectionNumber = sectionNumbers["Pre-acquisition right"] || 'X';
            const tagAlongSectionNumber = sectionNumbers["Tag Along"] || 'X';

            return [
                new Paragraph({
                    text: `Call option on the departure of a founding shareholder`,
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: 'In the event that an employment, service or consulting agreement (“Manager Agreement”) entered into between a Founding Shareholder or a Manager and the Company terminates for any reason and the relevant Shareholder or Manager ceases to be (i) a full-time manager of the Company or (ii) a consultant to the Company (“Exiting Shareholder”), the relevant Shareholder (“Exiting Shareholder”) hereby undertakes vis-à-vis the other Shareholders to transfer all shares held by him in the Company (“Call Option Shares”) at the request of the majority of the voting rights of the remaining shareholders (whereby the Withdrawing Shareholder has no voting rights) in text form to one or more shareholders, to one or more third parties or to the Company (insofar as the Company has reserves required for the acquisition of treasury shares pursuant to Section 33 GmbH) in accordance with the following provisions and, subject to Section 3.2, in whole or in part in accordance with the requirements of the majority of shareholders (“call option”):',
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: 'The purchase price of the Call Option Shares (“Call Option Purchase Price”) shall be determined in accordance with the following provisions and depending on (i) the percentage value of the Call Option Shares that the respective Exiting Shareholder sells at fair market value in the event of the exercise of the Call Option in accordance with the second column of the respective table in section 3.1(b) below at market value (“market value share”) and (ii) the percentage value of the call option shares that the withdrawing shareholder sells at book value (“book value share”) if the call option is exercised in accordance with the third column of the respective table in section 3.1(b) below.',
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "The fair market value share and the book value share are calculated separately for each withdrawing shareholder depending on the months elapsed since the notarization of this shareholders' agreement (“effective date”) until the withdrawal as shown in Annex 3.1(b).",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "In the following cases, the call option purchase price is made up of the market value of the market value share and the book value, but no more than 70% of the market value if this amount is lower than the book value, but at least the nominal value, of the book value share of the call option shares:",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "the manager contract is terminated by the departing shareholder or the manager for good cause;",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "the Manager Agreement is duly terminated by the Company or expires in accordance with the terms of the Manager Agreement; or",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "the departing shareholder dies or becomes permanently unable to work for a period of more than six (6) months within the meaning of social insurance law (jointly “Good Leaver”).",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: 'In the event that the manager contract is terminated by the company for good cause for which the departing shareholder or manager is responsible or is terminated by the departing shareholder or manager after such cause has arisen (“bad leaver”), the call option purchase price - irrespective of the amount of the fair market value share - generally corresponds to the book value, but no more than 50% of the fair market value if this amount is lower than the book value, but no less than the nominal value, of the call option shares.',
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: `In the event of (i) the Exiting Shareholder or Manager leaving the Company as a Good Leaver after the third (3rd) anniversary of the Effective Date or (ii) the sale of all shares in the Company before the third (3rd) anniversary of the Effective Date, the Exiting Shareholder shall have no obligation to sell and transfer the Call Option Shares pursuant to Section ${sectionNumbers["Call option on the departure of a founding shareholder"]}.1 above and the Exiting Shareholder shall be entitled to the Call Option Shares in full.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `The market value of the call option shares is to be determined by the company's auditor, taking into account the applicable principles for company valuation recommended by the Institut der Wirtschaftsprüfer in Deutschland e.V. (Institute of Public Auditors in Germany), unless the shareholders waive this determination. The book value of the call option shares corresponds to their share of the company's equity within the meaning of Section 266 (3) A. HGB (without taking into account any additions to the capital reserve by the investors) at the time of withdrawal. In the event that disputes arise between the shareholders with regard to the market value and/or the book value of the call option shares, Section ${preAcquisitionSectionNumber}.2 below shall apply accordingly.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "Should it be legally established in an individual case that a provision contained in this Section for determining the call option purchase price is invalid or unreasonable, the lowest permissible purchase price shall be agreed as the call option purchase price instead.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The call option can only be exercised within a period of six (6) weeks after the withdrawal at the request of the investors in text form to the withdrawing shareholder (“call option exercise”).",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `The sale and assignment obligation of the Exiting Shareholder declared in Section ${sectionNumbers["Call option on the departure of a founding shareholder"]}.1 above shall expressly continue to apply after the death of the Exiting Shareholder. Any legal successors of the Withdrawing Shareholder shall be subject in particular to the obligations under this Section ${sectionNumbers["Call option on the departure of a founding shareholder"]} in accordance with Section ${accessionSectionNumber} below (Accession to the Shareholders' Agreement).`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `Sections ${preAcquisitionSectionNumber} (Pre-acquisition right) and ${tagAlongSectionNumber} (Tag-along right) below do not apply to transfers of call option shares in accordance with the provisions of this section.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "If and to the extent that the call option shares are sold and assigned to the company in whole or in part in accordance with the aforementioned provisions as specified by the shareholders, the call option purchase price shall be paid by the company in three (3) equal installments. The first installment shall be due for payment one (1) month after assignment of the call option shares. Each subsequent installment is due three (3) months after the previous installment is due. Each installment shall bear interest at an annual rate of two (2) percentage points above the base interest rate from the date of exercise of the call option. The interest shall be paid at the same time as the corresponding installment. The company has the right, with the consent of the majority of shareholders, to pay the call option purchase price in full or in part at any time before the due date. The voting rights attributable to the call option shares shall be suspended from the date of exercise of the call option until the date of full payment of the call option purchase price in accordance with this Section.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 4: Pre-Acquisition Right
        const generatePreAcquisitionRight = (sha, sectionNumbers) => {
            const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
            const accessionSectionNumber = sectionNumbers["Accession to the shareholders' agreement"] || 'X';

            return [
                new Paragraph({
                    text: `Pre-acquisition right`,
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "In the event that a shareholder (“Selling Shareholder”) intends to sell all or part of his shares in the Company to a third party (including another shareholder and the Company) (“Purchaser”) (“Seller Shares”), the other shareholders of the Company are each entitled to acquire the Seller Shares in proportion to their shareholding in the share capital of the Company in accordance with the following provisions (“Pre-acquisition Right”):",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The shareholder wishing to sell must notify the other shareholders (including the purchaser, if the purchaser is a shareholder) (“pre-acquisition beneficiaries”) and the management of the company of the following information in text form (“notification of sale”):",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "Name/company name and registered office/address of the shareholder wishing to sell,",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "Name/company name and registered office/address of the acquirer,",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "Purchase price or other consideration for the seller's shares,",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "Due date of the purchase price or other consideration,",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "The number and nominal amounts of the seller's shares that are intended to be sold, and",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "Any warranties and guarantees assumed by the selling shareholder.",
                    numbering: { reference: alphabeticalNumberingReference, level: 1 },
                }),
                new Paragraph({
                    text: "Each Pre-acquisition Beneficiary may only exercise its pro rata pre-acquisition right in full and within one (1) month of receipt of the notification of sale (“Exercise Period”) and only by declaration in text form to the management of the company.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "After expiry of the Exercise Period, the management of the company must immediately notify the shareholder willing to sell and the pre-emptive beneficiaries in text form of the result of the exercise of the respective pre-emption rights and the (provisional) allocation of the seller's shares among the pre-emptive beneficiaries willing to purchase (“Exercise Notification”).",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "If a Pre-acquisition Beneficiary does not exercise its right to acquire the Seller's shares on a pro rata basis within the Exercise Period pursuant to Section 4.1(b) above, the other Pre-acquisition Beneficiaries shall each have the further right to acquire the portion of the Seller's shares attributable to this Pre-acquisition Beneficiary in proportion to their shareholding in the share capital of the Company (without taking into account the shares of the shareholders not exercising their Pre-acquisition Rights) (“Extended Pre-acquisition Right”). The Extended Pre-Acquisition Right can only be exercised in full and within 2 (two) weeks of receipt of the Exercise Notice by the other Pre-Acquisition Beneficiaries (“Extended Exercise Period”) by means of a declaration in text form to the management of the company. Section 4.1(c) above applies accordingly.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "In the event that all pre-acquisition rights are exercised in due form and time in accordance with sections 4.1(a) to 4.1(d) above, the Shareholder willing to sell and the pre-acquisition beneficiaries willing to purchase shall be obliged to conclude a notarized share purchase and transfer agreement for all Seller Shares without undue delay in accordance with the allocation set out in the Exercise Notice(s) and at the purchase price stated in the Sale Notice and on the other terms and conditions set out therein, insofar as other terms and conditions (in particular guarantees, limitation of liability and statute of limitations) are customary.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: `If the selling shareholder is obliged to sell his selling shares to the pre-acquisition beneficiaries pursuant to section 4.1(e) or is entitled to sell them to the acquirer pursuant to section 4.1(f) above and if the requirements of section ${accessionSectionNumber} (accession to the shareholders' agreement) are met with regard to the acquirer, transfers of shares must be approved in accordance with this section.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 5: Tag Along
        const generateTagAlong = (sha, sectionNumbers) => {
            const preAcquisitionSectionNumber = sectionNumbers["Pre-acquisition right"] || 'X';
            const callOptionSectionNumber = sectionNumbers["Call option on the departure of a founding shareholder"] || 'X';
            const accessionSectionNumber = sectionNumbers["Accession to the shareholders' agreement"] || 'X';

            return [
                new Paragraph({
                    text: `Tag Along`,
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "If a shareholder willing to sell intends to sell his seller shares, each shareholder entitled to pre-acquisition rights may, after receipt of the notification of sale, waive his respective pre-acquisition rights (“co-seller”) and demand that the shareholder willing to sell also sell and transfer the shares held by this co-seller to the purchaser in whole or in part (“co-seller shares”) under the conditions specified in the notification of sale (“co-sale right”). The co-sale right must be exercised within two (2) weeks of receipt of the notification of sale by the co-seller and only by declaration in text form to the management of the company. The management of the company must immediately notify the shareholder willing to sell and the other shareholders of the exercise of a co-sale right in text form. By exercising the co-sale right, the co-seller waives the right to exercise his pre-acquisition right.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `The above paragraphs ${preAcquisitionSectionNumber}.1 and ${preAcquisitionSectionNumber}.2 apply accordingly with regard to the co-seller's shares.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `If the selling shareholder is entitled to sell seller shares and co-seller shares to the acquirer with the participation of the co-sellers pursuant to sections 5.3 and 5.4 above and if the requirements of section ${accessionSectionNumber} above (accession to the shareholders' agreement) for this sale are met, transfers of shares pursuant to this section must be approved in accordance with section ${sectionNumbers["Disposal of shares, subscription rights"]}.1 above, subject to section ${sectionNumbers["Disposal of shares, subscription rights"]}.2 above.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 6: Drag-Along
        const generateDragAlong = (sha, sectionNumbers) => {
            return [
                new Paragraph({
                    text: `Drag-Along`,
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "All shareholders are obliged to sell and transfer all shares they hold in the company to one or more third parties (“total sale”) if the shareholders resolve to sell all shares by a majority of 75% (seventy-five percent) of the votes cast.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 7: Control and Information Rights (Conditional)
        const generateControlAndInformationRights = (sha, sectionNumbers) => {
            const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
            const finalProvisionsSectionNumber = sectionNumbers["Final provisions"] || 'X';
            return [
                new Paragraph({
                    text: "Control and information rights",
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "In addition, the shareholders are entitled to the following information rights:",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The shareholders shall receive a monthly status report from the company for the previous month in the form requested by the investors by the end of the 20th (twentieth) calendar day of the following month and a business plan for the following year in the form requested by the shareholders by November 15 of each financial year at the latest. The status report must also be accompanied by a business analysis (BWA), a list of totals and balances as well as a list of accounts payable and accounts receivable for the respective reporting month.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: `The company is obliged to inform the shareholders immediately in writing of any changes to the twelve-month plan and any business plan submitted in accordance with Section ${sectionNumbers["Control and information rights"]}.1(a) above. In addition, the company is obliged to amend the twelve-month plan and/or the business plan without delay and to provide the shareholders with the new version without delay both in writing and as a data set (text documents as Word files and tables as Excel files).`,
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: `The company must prepare its annual financial statements within three (3) months of the end of each financial year in accordance with the provisions for large corporations, have them audited by an auditor and submit them to the shareholders together with the audit report and auditor's opinion immediately after the audit report has been prepared. This also applies to the annual ESG reporting in accordance with Section ${finalProvisionsSectionNumber}.3, which the company must submit to the shareholders on the most important adverse sustainability impacts (so-called PAI statement).`,
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "The company must inform the shareholders immediately in writing of all measures that go beyond the scope of normal business transactions. In particular, the company shall inform the shareholders immediately if the company becomes aware that a change of control over the company is imminent or has occurred through the acquisition of a majority shareholding within the meaning of Section 16 of the German Stock Corporation Act (AktG) or any other possibility of control pursuant to Section 290 (2) of the German Commercial Code (HGB). Furthermore, the company shall inform the shareholders without delay of any planned restructuring, reorganization measures or the conclusion of intercompany agreements within the meaning of Sections 291 et seq. AktG. In the event of a planned IPO, the company will inform the shareholders in particular of any application made for the company to be admitted to trading on a national, international or transnational stock exchange.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "The company must inform the shareholders immediately in writing of any events that could cause a significant deterioration in the economic situation of the company.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "The shareholders are entitled to inspect all documents of the company relating to the fulfillment of the information rights specified in this Section at any time and to gain access to the company's business premises and management. The shareholders may use the services of third parties to exercise their information and control rights.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "Within the scope of the contractual information and reporting obligations, the company will send notifications, documents and information as well as all other correspondence electronically via a secure Internet connection if the shareholders so request.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "The information, documents and information owed by the company must be provided both in writing and in the form of a data record (text documents in Word, tables in Excel). This shall only apply to documents originating from third parties if they are available to the Company itself as a data record.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "The parties are obliged to work towards ensuring that the managing directors of the company fulfill the aforementioned information rights of the shareholders at all times in accordance with the provisions made here. In particular, they are obliged, if necessary, to vote in favor of a corresponding resolution at shareholders' meetings of the company, on the basis of which the managing directors of the company are to be instructed to fulfill the information rights of the shareholders.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 8: Matrimonial Property Regime (Conditional)
        const generateMatrimonialPropertyRegime = (sha, sectionNumbers) => {
            const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
            return [
                new Paragraph({
                    text: "Matrimonial property regime",
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "Married partners living under the matrimonial property regime",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "of the community of accrued gains, it must be agreed in a marriage contract that the partner is not subject to the restrictions of § 1365 BGB, or",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: "the community of accrued gains and it has not been agreed that his or her shares in the company are not subject to equalization of accrued gains if this matrimonial property regime is terminated other than by the death of one of the spouses, or (ii) the community of property regime and the shareholding in the company has not been declared reserved property of the shareholder in the marriage contract and this has been entered in the matrimonial property register, the shareholders may redeem the shares of the shareholder concerned in accordance with the provisions of the partnership agreement if (x) the marriage of the shareholder concerned is divorced and (y) the shares of the shareholder in the company are voluntarily or compulsorily transferred to the spouse of the shareholder for this reason or compulsory execution is levied on these shares in the company. The shareholders hereby consent to this redemption.",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
            ];
        };

        // Generate Section 9: Term, termination
        const generateTermAndTermination = (sha, sectionNumbers) => {
            return [
                new Paragraph({
                    text: "Term, termination",
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "The shareholders' agreement shall end if only one of the shareholders still holds an interest in the company, but at the latest after 15 (fifteen) years, calculated from the date of notarization of this shareholders' agreement. If a shareholder leaves the company for any legal reason whatsoever, his rights and obligations arising from this shareholders' agreement shall end when his departure as a shareholder of the company takes effect, unless expressly stipulated otherwise in this shareholders' agreement.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The shareholders agreement may not be terminated until the end of its term. The right of the parties to terminate for good cause remains unaffected by this.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 10: Accession to the shareholders' agreement
        const generateAccessionToShareholdersAgreement = (sha, sectionNumbers) => {
            const alphabeticalNumberingReference = getAlphabeticalNumberingReference();
            const callOptionSectionNumber = sectionNumbers["Call option on the departure of a founding shareholder"] || 'X';
            const preAcquisitionSectionNumber = sectionNumbers["Pre-acquisition right"] || 'X';
            const tagAlongSectionNumber = sectionNumbers["Tag Along"] || 'X';
            const dragAlongSectionNumber = sectionNumbers["Drag-Along"] || 'X';
            return [
                new Paragraph({
                    text: "Accession to the shareholders' agreement",
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "The shareholders undertake among themselves to obligate their legal successors, if any, as well as any other parties joining the company as shareholders",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "to enter into the rights and obligations of this shareholders' agreement, or - if the accession of legal successors or further shareholders to this shareholders' agreement is not desired or cannot be enforced",
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: `to make the effectiveness of the transfer to a legal successor or the issue of new shares in the company to a new shareholder dependent on the continuation of the investors' redemption preferences and anti-dilution rights provided for in the company's Articles of Association and in this shareholders' agreement and on the conclusion of a shareholders' agreement between the parties and the legal successor or the new shareholder, to grant the investors the rights set out in the above Sections ${callOptionSectionNumber} (call option upon the withdrawal of a founding shareholder), ${preAcquisitionSectionNumber} (pre-acquisition right), ${tagAlongSectionNumber} (tag-along right) and ${dragAlongSectionNumber} (drag-along) to at least the extent provided for therein.`,
                    numbering: { reference: alphabeticalNumberingReference, level: 0 },
                }),
                new Paragraph({
                    text: `In the event that a shareholder transfers its shares in the company to a company affiliated with the respective shareholder within the meaning of Sections 15 et seq. AktG, the other parties hereby irrevocably offer the respective affiliated company to accede to this shareholders' agreement in the version applicable at the time of accession, whereby the respective affiliated company fully assumes the rights and obligations of the transferring shareholder. The acceptance of the above offer by the respective affiliated company must be declared to the company in notarized form. The other parties hereby irrevocably authorize the company to accept the declaration of acceptance (irrevocable power of attorney to receive).`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 11: Other agreements
        const generateOtherAgreements = (sha, sectionNumbers) => {
            return [
                new Paragraph({
                    text: "Other agreements",
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "The parties agree that all rights and obligations of the parties arising from this shareholders' agreement shall apply in the relationship between the parties upon conclusion of this shareholders' agreement.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "In the relationship between the parties, the provisions of this shareholders' agreement shall take precedence over the provisions of the articles of association, the rules of procedure for the management of the company and all other agreements between the parties.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The shareholders agree that the conclusion of this shareholders' agreement between them creates an internal partnership under civil law (Sections 705 et seq. of the German Civil Code) without its own company assets, and that this shareholders' agreement constitutes its articles of association. The shareholders clarify that the shares in the company remain in their respective sole ownership and that no joint ownership of the shares in the company is established.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "In the event that, in connection with the implementation of a capital measure, the shareholders have approved the conclusion of an amendment and/or supplementary agreement to this shareholders' agreement submitted by the management of the company with a majority of at least 95.0% (ninety-five percent) of the votes cast, all parties are obliged to conclude the corresponding amendment and/or supplementary agreement to this shareholders' agreement and to make corresponding declarations of intent in a formally effective manner.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The parties undertake to make and receive all declarations in due form at any time and without special consideration, to adopt all resolutions at the shareholders' meeting of the company and to take all measures necessary to effectively implement and fulfill the provisions laid down in this shareholders' agreement.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The provisions of this shareholders' agreement shall apply mutatis mutandis in the event of a transformation of the company (change of legal form, merger, demerger).",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        };

        // Generate Section 12: Final provisions
        const generateFinalProvisions = (sha, sectionNumbers) => {
            return [
                new Paragraph({
                    text: "Final provisions",
                    heading: HeadingLevel.HEADING_2,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "Assignment of rights",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The parties are not entitled to transfer, pledge or otherwise encumber claims or other rights arising from this shareholders' agreement without the consent of the other party.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Assumption of costs",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "Unless this shareholders' agreement expressly provides otherwise, all fees, charges, auditing costs and other costs in connection with the conclusion and amendment of this shareholders' agreement shall be borne by the company.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Written form",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `Amendments to this shareholders' agreement, including its annexes, must be made in writing, unless a stricter form is required by law. This also applies to amendments to this clause ${sectionNumbers["Final provisions"]}.3.`,
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Place of jurisdiction",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "All legal disputes between the parties arising from and in connection with this shareholders' agreement or its annexes shall be decided by the ordinary German courts. To the extent permitted by law, the exclusive place of jurisdiction shall be the registered office of the company.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Banking days and text form",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "“Banking days” are trading days within the meaning of Section 47 WpHG.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "“Text form” is the statutory form pursuant to Section 126b BGB, i.e. in particular letter, fax or email.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Notifications",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "Any requests, notices, declarations to be made or other communications required under this Shareholders' Agreement shall be addressed to the parties or to the person or address designated by one of the parties, unless expressly provided otherwise in this Shareholders' Agreement.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Confidentiality",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "The parties undertake to treat the content of this shareholders' agreement, including all annexes, as secret and confidential vis-à-vis third parties, unless the party in question is a person bound to professional secrecy or the facts in question are publicly known or their public disclosure is required by law. In this case, the parties are obliged to inform each other in advance and to limit the public announcements to the content prescribed by law or by the authorities.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Announcements",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: "Any public announcement of the parties' participation in the company and in this shareholders' agreement must be jointly agreed between the parties in advance.",
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
                new Paragraph({
                    text: "Severability clause",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
                new Paragraph({
                    text: `Should a provision of this shareholders' agreement be or become void, invalid or unenforceable in whole or in part, or should a provision that is necessary in itself not be included, the validity and enforceability of all other provisions of this shareholders' agreement shall not be affected. In place of the invalid, ineffective or unenforceable provision or to fill the loophole, the parties shall agree a legally permissible provision that corresponds as closely as possible to what the parties intended or would have agreed in accordance with the meaning and purpose of this shareholders' agreement if they had recognized the ineffectiveness or loophole. If the invalidity of a provision is based on a measure of performance or time (deadline or date) specified therein, the parties shall agree on a provision with a legally permissible measure that comes closest to the original measure. It is the express intention of the parties that this severability clause does not merely result in a reversal of the burden of proof, but that Section 139 BGB is waived in its entirety.`,
                    numbering: { reference: "decimalNumbering", level: 2 },
                }),
            ];
    };

    // Finally, the component renders the button that triggers the document generation
    return (
        <button
            onClick={generateShareholdersAgreement}
            className="text-white px-4 py-2 rounded hover:text-white bg-[#2A9D8F] hover:bg-[#238276] flex items-center text-sm"
        >
            <img src={process.env.PUBLIC_URL + '/ux_icons/download.svg'} alt="icon" className="h-5 w-5 mr-2 fill-current text-white" />
            Shareholders Agreement (english)
        </button>
    );
};

export default ShareholdersAgreementGenerator;