// src/hooks/useUserData.js
import { useState, useEffect } from 'react';
import axios from '../axios';

export const useUserData = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasDraft, setHasDraft] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
          }
        });
        setUserData(response.data);
        setHasDraft(response.data.entity.has_draft);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return { userData, loading, error, hasDraft };
};