import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Radiothumbs from '../../../components/wizard_components/Radiothumbs';
import TextField from '../../../components/wizard_components/TextField';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useSchema } from '../../../hooks/useSchema';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';

const KnowYourselfWizardIndustry = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, loading: userDataLoading } = useUserData();
  const { schema, loading: schemaLoading } = useSchema();
  const { handleSubmit, handleDraftSubmit, error: submitError } = useFormSubmit('/know-yourself/market');
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [dataStatus, setDataStatus] = useState('published');
  const [initialValues, setInitialValues] = useState({
    industry: '',
    otherIndustry: '',
    sector: '',
    otherSector: '',
    industryNotes: ''
  });

  useEffect(() => {
    if (userData?.entity?.know_yourself_response) {
      const { published, draft } = userData.entity.know_yourself_response;
      const relevantDraftData = {
        industry: draft?.industry || '',
        otherIndustry: draft?.otherIndustry || '',
        sector: draft?.sector || '',
        otherSector: draft?.otherSector || '',
        industryNotes: draft?.industryNotes || ''
      };
      const relevantPublishedData = {
        industry: published?.industry || '',
        otherIndustry: published?.otherIndustry || '',
        sector: published?.sector || '',
        otherSector: published?.otherSector || '',
        industryNotes: published?.industryNotes || ''
      };
  
      const isDataEmpty = (data) => {
        return Object.values(data).every(value => 
          value === '' || 
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === 'object' && Object.keys(value).length === 0)
        );
      };
  
      const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);
  
      if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
        setDataStatus('draft');
        setInitialValues(relevantDraftData);
      } else if (!isDataEmpty(relevantPublishedData)) {
        setDataStatus('published');
        setInitialValues(relevantPublishedData);
      } else {
        setDataStatus('new');
        setInitialValues({
          industry: '',
          otherIndustry: '',
          sector: '',
          otherSector: '',
          industryNotes: ''
        });
      }
    }
  }, [userData]);

  if (userDataLoading || schemaLoading) {
    return <div>{t('Loading...')}</div>;
  }

  const industrySvgs = {
    "Agriculture": {
      default: '/thumbs/Agriculture_industry.svg',
      hover: '/thumbs/Agriculture_industry_hover.svg',
      active: '/thumbs/Agriculture_industry_active.svg'
    },
    "Climate and Environment": {
      default: '/thumbs/Climate_industry.svg',
      hover: '/thumbs/Climate_industry_hover.svg',
      active: '/thumbs/Climate_industry_active.svg'
    },
    "Consumer Goods / E-Commerce": {
      default: '/thumbs/Consumer_Goods_industry.svg',
      hover: '/thumbs/Consumer_Goods_industry_hover.svg',
      active: '/thumbs/Consumer_Goods_industry_active.svg'
    },
    "Consumer Services": {
      default: '/thumbs/Consumer_Services_industry.svg',
      hover: '/thumbs/Consumer_Services_industry_hover.svg',
      active: '/thumbs/Consumer_Services_industry_active.svg'
    },
    "Culture & Media": {
      default: '/thumbs/Media_industry.svg',
      hover: '/thumbs/Media_industry_hover.svg',
      active: '/thumbs/Media_industry_active.svg'
    },
    "Education": {
      default: '/thumbs/Education_industry.svg',
      hover: '/thumbs/Education_industry_hover.svg',
      active: '/thumbs/Education_industry_active.svg'
    },
    "Energy, Waste & Utilities": {
      default: '/thumbs/Energy_industry.svg',
      hover: '/thumbs/Energy_industry_hover.svg',
      active: '/thumbs/Energy_industry_active.svg'
    },
    "Finance": {
      default: '/thumbs/Finance_industry.svg',
      hover: '/thumbs/Finance_industry_hover.svg',
      active: '/thumbs/Finance_industry_active.svg'
    },
    "Government": {
      default: '/thumbs/Government_industry.svg',
      hover: '/thumbs/Government_industry_hover.svg',
      active: '/thumbs/Government_industry_active.svg'
    },
    "Healthcare": {
      default: '/thumbs/Healthcare_industry.svg',
      hover: '/thumbs/Healthcare_industry_hover.svg',
      active: '/thumbs/Healthcare_industry_active.svg'
    },
    "Manufacturing": {
      default: '/thumbs/Manufacturing_industry.svg',
      hover: '/thumbs/Manufacturing_industry_hover.svg',
      active: '/thumbs/Manufacturing_industry_active.svg'
    },
    "Mining, Quarrying, and Oil": {
      default: '/thumbs/Mining_industry.svg',
      hover: '/thumbs/Mining_industry_hover.svg',
      active: '/thumbs/Mining_industry_active.svg'
    },
    "Professional Services": {
      default: '/thumbs/Professional_industry.svg',
      hover: '/thumbs/Professional_industry_hover.svg',
      active: '/thumbs/Professional_industry_active.svg'
    },
    "Real Estate": {
      default: '/thumbs/Real_Estate_industry.svg',
      hover: '/thumbs/Real_Estate_industry_hover.svg',
      active: '/thumbs/Real_Estate_industry_active.svg'
    },
    "Technology": {
      default: '/thumbs/Technology_industry.svg',
      hover: '/thumbs/Technology_industry_hover.svg',
      active: '/thumbs/Technology_industry_active.svg'
    },
    "Telecommunication": {
      default: '/thumbs/Telecommunication_industry.svg',
      hover: '/thumbs/Telecommunication_industry_hover.svg',
      active: '/thumbs/Telecommunication_industry_active.svg'
    },
    "Transportation": {
      default: '/thumbs/Transportation_industry.svg',
      hover: '/thumbs/Transportation_industry_hover.svg',
      active: '/thumbs/Transportation_industry_active.svg'
    },
    "Other": {
      default: '/thumbs/Other_industry.svg',
      hover: '/thumbs/Other_industry_hover.svg',
      active: '/thumbs/Other_industry_active.svg'
    }
  };

  const validationSchema = Yup.object({
    industry: Yup.string().required(t('KnowYourselfWizard.industryRequired')),
    otherIndustry: Yup.string().when('industry', {
      is: 'Other',
      then: (schema) => schema.required(t('KnowYourselfWizard.otherIndustryRequired')),
    }),
    sector: Yup.string().when('industry', {
      is: (value) => value !== 'Other',
      then: (schema) => schema.required(t('KnowYourselfWizard.sectorRequired')),
      otherwise: (schema) => schema.notRequired()
    }),
    otherSector: Yup.string().when('sector', {
      is: 'Other',
      then: (schema) => schema.required(t('KnowYourselfWizard.otherSectorRequired')),
    }),
    industryNotes: Yup.string().max(1000, t('KnowYourselfWizard.notesMaxLength'))
  });

  const getTranslatedIndustries = () => {
    if (!schema || !schema.properties.industry.enum) {
      return [];
    }
    return schema.properties.industry.enum.map((industry) => ({
      value: industry,
      svg: industrySvgs[industry],
      label: t(`Industries.${industry}`)
    }));
  };

  const getTranslatedSectors = (industry) => {
    const industryData = schema.properties.sector.dependencies.industry.oneOf.find(
      (ind) => ind.properties.industry.enum.includes(industry)
    ) ?? {};
    if (industryData) {
      return industryData.properties.sector.enum.map((sector) => ({
        value: sector,
        svg: industrySvgs[industry],
        label: t(`Sectors.${sector}`)
      }));
    }
    return [];
  };

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await handleSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
      setDataStatus('published');
      await handleDraftSubmit({ responses: values });

    // Set a timeout to navigate after 1 second
    setTimeout(() => {
      navigate('/know-yourself/market');
    }, 1000);

    } catch (error) {
      // This will catch any errors from the API call
      setNotification({ 
        show: true, 
        type: 'error', 
        message: 'Failed to save data', 
        errors: [error.message] 
      });
    } finally {
      setSubmitting(false);
    }
  };
  
  const onDraftSubmit = async (values, { setSubmitting }) => {
    try {
      // Wrap the values in a 'responses' object
      await handleDraftSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: 'Draft saved successfully!' });
      setDataStatus('draft');
    } catch (error) {
      setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Notification
        show={notification.show}
        setShow={(show) => setNotification({ ...notification, show })}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />
      <Progress />
      <div className="data-status-banner mt-4 mb-4">
        {dataStatus === 'published' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is the last saved and published version.')}
          </span>
        )}
        {dataStatus === 'draft' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is a draft version. Some of the information on this page might not have been published yet.')}
          </span>
        )}
        {dataStatus === 'new' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('There is no prior version of this page. Please manually save or save as draft below.')}
          </span>
        )}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
      {({ values, errors, touched, setFieldValue, isSubmitting, validateForm }) => {
        const handleIndustryChange = (event) => {
          setFieldValue('industry', event.target.value);
          setFieldValue('sector', '');
          setFieldValue('otherIndustry', '');
          setFieldValue('otherSector', '');
        };
  
        const handleSectorChange = (event) => {
          setFieldValue('sector', event.target.value);
          setFieldValue('otherSector', '');
        };

        const handleSubmit = async (e) => {
          e.preventDefault();
          const errors = await validateForm(values);
          if (Object.keys(errors).length === 0) {
            // No validation errors, proceed with submission
            onSubmit(values, { setSubmitting: () => {} });
          } else {
            // Show validation errors in notification
            const errorMessages = Object.entries(errors).map(([field, message]) => `${t(`KnowYourselfWizard.${field}`)}: ${message}`);
            setNotification({
              show: true,
              type: 'error',
              message: 'Failed to save data due to validation errors:',
              errors: errorMessages
            });
          }
        };
  
        return (
          <Form onSubmit={handleSubmit}>
            <div className="industry-section">
              <h2 className="text-primary-color">{t('KnowYourselfWizard.industry')}</h2>
              <p className="mt-2">{t('KnowYourselfWizard.industryDescription')}</p>
              <Radiothumbs
                name="industry"
                options={getTranslatedIndustries()}
                value={values.industry}
                onChange={handleIndustryChange}
              />
              {values.industry === 'Other' && (
                <TextField name="otherIndustry" label={t('KnowYourselfWizard.otherIndustry')} />
              )}

              {values.industry && values.industry !== 'Other' && (
                <>
                  <div className="mt-6"></div>
                  <p className="mt-2">{t('KnowYourselfWizard.sectorDescription')}</p>
                  <Radiothumbs
                    name="sector"
                    options={getTranslatedSectors(values.industry)}
                    value={values.sector}
                    onChange={handleSectorChange}
                  />
                  {values.sector === 'Other' && (
                    <TextField name="otherSector" label={t('KnowYourselfWizard.otherSector')} />
                  )}
                </>
              )}
            </div>
            <div className="mt-6"></div>
            <p>{t('KnowYourselfWizard.industryNotesDescription')}</p>
            <TextField 
              name="industryNotes" 
              type="textarea" 
            />
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button 
                type="button" 
                className="text-sm text-gray-900"
                onClick={() => navigate('/know-yourself/market')}
              >
                {t('cancel')}
              </button>
              <button
                type="button"
                onClick={() => onDraftSubmit(values, { setSubmitting: () => {} })}
                disabled={isSubmitting}
                className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                {t('Save as Draft')}
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
              >
                {isSubmitting ? t('saving') : t('save')}
              </button>
            </div>
      </Form>
        );
      }}
  </Formik>
  </>
);
};

export default KnowYourselfWizardIndustry;