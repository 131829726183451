import React from 'react';
import PropTypes from 'prop-types';

const Radiothumbs = ({ name = '', options = [], value = '', onChange = () => {} }) => {
  return (
    <div className="radiothumbs">
      {options.map((option) => (
        <label 
          key={option.value} 
          className={`radiothumb ${value === option.value ? 'selected' : ''}`} 
          style={{
            backgroundImage: `url(${value === option.value ? option.svg?.active : option.svg?.default})`
          }}
          onMouseEnter={(e) => {
            if (value !== option.value) {
              e.currentTarget.style.backgroundImage = `url(${option.svg?.hover})`;
            }
          }}
          onMouseLeave={(e) => {
            if (value !== option.value) {
              e.currentTarget.style.backgroundImage = `url(${option.svg?.default})`;
            }
          }}
        >
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
            className="hidden"
          />
          <div className="radiothumb-content">
            <span className="text">{option.label}</span>
          </div>
        </label>
      ))}
    </div>
  );
};

Radiothumbs.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      svg: PropTypes.shape({
        default: PropTypes.string.isRequired,
        hover: PropTypes.string.isRequired,
        active: PropTypes.string.isRequired,
      }).isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Radiothumbs;