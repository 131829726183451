import React from 'react';

const SubscriptionChoice = ({ selectedTier, onTierSelect, t }) => {
  const renderFeatureList = (features, tierType) => {
    return features.map((featureKey) => (
      <li key={featureKey} className="flex items-start">
        <svg className="h-5 w-5 text-primary-color flex-shrink-0 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
        <span className="text-gray-600 text-xs">
          {t(`subscription.${tierType}.features.${featureKey}`)}
        </span>
      </li>
    ));
  };

  const founderFeatures = ['wizard', 'financing', 'privacy', 'taskManagement'];
  const professionalFeatures = ['foundersFeatures', 'cybersecurity', 'quality', 'threats', 'reporting'];

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-primary-color">
          {t('subscription.title')}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          {t('subscription.subtitle')}
        </p>
      </div>

      <div className="grid md:col-span-2 grid-cols-1 md:grid-cols-2 gap-6">
        {/* Founder Tier */}
        <div
          className={`relative p-8 rounded-lg border-2 cursor-pointer transition-all ${
            selectedTier === 'founder'
              ? 'border-primary-color bg-primary-color/5 shadow-md'
              : 'border-gray-200 hover:border-gray-300 hover:shadow-sm'
          }`}
          onClick={() => onTierSelect('founder')}
        >
          <div>
            <h3 className="text-xl font-bold">{t('subscription.founder.title')}</h3>
            <div className="mt-2 mb-4">
              <span className="text-3xl font-bold">€5</span>
              <span className="text-gray-600 text-xs ml-1">{t('subscription.perUserMonth')}</span>
            </div>
            <p className="text-sm text-gray-600 mb-6">
              {t('subscription.founder.description')}
            </p>
            <ul className="space-y-3">
              {renderFeatureList(founderFeatures, 'founder')}
            </ul>
          </div>
        </div>

        {/* Professional Tier */}
        <div
          className={`relative p-8 rounded-lg border-2 cursor-pointer transition-all ${
            selectedTier === 'professional'
              ? 'border-primary-color bg-primary-color/5 shadow-md'
              : 'border-gray-200 hover:border-gray-300 hover:shadow-sm'
          }`}
          onClick={() => onTierSelect('professional')}
        >
          <div>
            <h3 className="text-xl font-bold">{t('subscription.professional.title')}</h3>
            <div className="mt-2 mb-4">
              <span className="text-3xl font-bold">€9</span>
              <span className="text-gray-600 text-xs ml-1">{t('subscription.perUserMonth')}</span>
            </div>
            <p className="text-sm text-gray-600 mb-6">
              {t('subscription.professional.description')}
            </p>
            <ul className="space-y-3">
              {renderFeatureList(professionalFeatures, 'professional')}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionChoice;