// src/pages/PasswordResetConfirmation.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PasswordResetConfirmation() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">{t('password_successfully_reset')}</h2>
        <p className="text-center text-gray-700 mb-4">
          {t('your_password_has_been_reset')}
        </p>
        <div className="text-center">
          <Link to="/login" className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color btn-link">
            {t('back_to_login')}
          </Link>
        </div>
      </div>
    </div>
  );
}
