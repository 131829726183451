import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export default function Alerts() {
  const { user } = useContext(AuthContext);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Alerts</h1>
      <p>Welcome, {user?.first_name}!</p>
      <p>There are no alerts yet. Phew!</p>
    </div>
  );
}
