import { useNavigate } from 'react-router-dom';
import animationData from '../assets/stop-animation.json';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

const DisclaimerCheck = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lottieRef = useRef();
  const [direction, setDirection] = useState(1);
  const pauseDuration = 1000; // 1 second pause at each end
  
  useEffect(() => {
    if (lottieRef.current) {
      const anim = lottieRef.current;
      
      const animate = () => {
        if (direction === 1) {
          anim.setSpeed(0.5); // Slower speed
          anim.playSegments([0, 30], true);
          setTimeout(() => {
            setDirection(-1);
          }, (30 / 0.3) * (1000/60) + pauseDuration); // Calculate time to complete forward animation + pause
        } else {
          anim.setSpeed(-0.5); // Negative for reverse, same slow speed
          anim.playSegments([30, 0], true);
          setTimeout(() => {
            setDirection(1);
          }, (30 / 0.3) * (1000/60) + pauseDuration); // Calculate time to complete reverse animation + pause
        }
      };

      // Start animation
      animate();

      // Set up listener for direction changes
      const intervalId = setInterval(() => {
        animate();
      }, ((30 / 0.3) * (1000/60) + pauseDuration) * 2); // Total time for one complete cycle

      return () => clearInterval(intervalId);
    }
  }, [direction]);

  return (
    <div className="min-h-[50vh] flex items-center justify-center p-4">
      <div className="max-w-2xl w-full bg-red-100 border border-red-300 rounded-lg p-6 text-center">
        <div className="w-32 h-32 mx-auto mb-4">
          <Lottie 
            lottieRef={lottieRef}
            animationData={animationData}
            loop={false}
            autoplay={false}
          />
        </div>
        <p className="text-red-800 text-lg mb-6">
          {t('wizard.disclaimer.check_required')}
        </p>
        <button
          onClick={() => navigate('/startup-wizard/introduction')}
          className="inline-flex justify-center rounded-md bg-red-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          {t('wizard.disclaimer.go_to_introduction')}
        </button>
      </div>
    </div>
  );
};

export default DisclaimerCheck;