import axios from '../../axios';

export const saveAsDraft = async (wizardName, section, data) => {
  try {
    await axios.post(`/api/v1/wizards/${wizardName}/draft`, {
      responses: { [section]: data }
    }, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
    });
    
    return { success: true, message: 'Draft saved successfully' };
  } catch (error) {
    console.error('Error saving draft:', error);
    return { success: false, message: 'Error saving draft', error };
  }
};

export const saveData = async (wizardName, section, data) => {
  try {
    // Save the published data
    await axios.post(`/api/v1/wizards/${wizardName}`, {
      responses: { [section]: data }
    }, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
    });

    // Also save the same data as draft
    await saveAsDraft(wizardName, section, data);

    return { success: true, message: 'Data saved and published successfully' };
  } catch (error) {
    console.error('Error saving data:', error);
    return { success: false, message: 'Error saving data', error };
  }
};

export default {
  saveAsDraft,
  saveData
};