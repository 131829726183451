// src/components/wizard_components/RadioButtons.js
import React from 'react';
import PropTypes from 'prop-types';
import './RadioButtons.css';

const RadioButtons = ({ name, options, value, onChange }) => {
  return (
    <div className="radio-buttons">
      {options.map((option) => (
        <label key={option.value} className="radio-button-label">
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
            className="radio-button-input"
          />
          <span className="radio-button-text">{option.label}</span>
        </label>
      ))}
    </div>
  );
};

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RadioButtons;
