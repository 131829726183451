import React, { useState } from 'react';
import axios from '../../axios'; // Import the configured Axios instance
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/v1/users/password', {
        user: { email }
      });
      navigate('/password-reset-success', { state: { email } });
    } catch (error) {
      // Navigate to the success page even if there was an error
      navigate('/password-reset-success', { state: { email } });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="flex justify-center mb-6">
        <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-16 mb-4" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">{t('reset_password')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-tertiary-color">{t('email')}</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm px-3 py-2"
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end gap-x-6">
            <button
              type="button"
              className="text-sm text-primary-color"
              onClick={() => navigate('/login')}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary-color px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
            >
              {t('send')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
