import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from '../../../axios';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import FounderCard from '../../../components/wizard_components/FounderCard';
import FounderCardAdd from '../../../components/wizard_components/FounderCardAdd';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import DisclaimerCheck from '../../../components/wizard_components/DisclaimerCheck';

const StartupWizardFounders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardName, updateWizardData, liveMode, toggleLiveMode, wizardData } = useWizard();
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const [isOverviewFixed, setIsOverviewFixed] = useState(false);
  const [overviewHeight, setOverviewHeight] = useState(0);
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'founders');
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const { data: introData, loading: introLoading, error: introError } = useFetchData(wizardName, 'introduction');

  useEffect(() => {
    if (introData) {
      setDisclaimerAccepted(introData.disclaimerChecked || false);
    }
  }, [introData]);

  const totalShareCapital = wizardData.published?.company?.shareCapital || 0;

  // Initialize initialValues when data is loaded
  const initialValues = useMemo(() => {
    if (data) {
      return {
        ...data,
        founders: data.founders || [
          {
            fullName: '',
            dateOfBirth: '',
            nationality: '',
            address: {
              street: '',
              streetAdditional: '',
              zip: '',
              place: '',
              country: '',
            },
            email: '',
            phone: '',
            isManagingDirector: false,
            sharePercentage: 0,
            capitalInvested: 0,
          },
        ],
      };
    } else {
      return {
        founders: [
          {
            fullName: '',
            dateOfBirth: '',
            nationality: '',
            address: {
              street: '',
              streetAdditional: '',
              zip: '',
              place: '',
              country: '',
            },
            email: '',
            phone: '',
            isManagingDirector: false,
            sharePercentage: 0,
            capitalInvested: 0,
          },
        ],
      };
    }
  }, [data]);

  // Other useEffects and functions remain the same...
  useEffect(() => {
    const overviewElement = document.getElementById('share-capital-overview');
    if (overviewElement) {
      setOverviewHeight(overviewElement.offsetHeight);
    }

    const handleScroll = () => {
      const overviewElement = document.getElementById('share-capital-overview');
      if (overviewElement) {
        const overviewRect = overviewElement.getBoundingClientRect();
        const parentRect = overviewElement.parentElement.getBoundingClientRect();

        if (parentRect.top <= 0 && parentRect.bottom >= overviewHeight) {
          setIsOverviewFixed(true);
        } else {
          setIsOverviewFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [overviewHeight]);

  const validationSchema = Yup.object({
    founders: Yup.array()
      .of(
        Yup.object({
          fullName: Yup.string().required(t('Full Name is required')),
          dateOfBirth: Yup.date().required(t('Date of Birth is required')),
          nationality: Yup.string().required(t('Nationality is required')),
          address: Yup.object({
            street: Yup.string().required(t('Street is required')),
            zip: Yup.string().required(t('ZIP is required')),
            place: Yup.string().required(t('Place is required')),
            country: Yup.string().required(t('Country is required')),
          }),
          email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
          phone: Yup.string().required(t('Phone is required')),
          isManagingDirector: Yup.boolean(),
          capitalInvested: Yup.number()
            .min(0, t('Capital invested must be non-negative'))
            .max(totalShareCapital, t('Capital invested cannot exceed total share capital'))
            .required(t('Capital invested is required')),
        })
      )
      .min(1, t('At least one founder is required'))
      .test('sum-of-capital', t('Total capital invested must equal total share capital'), function (founders) {
        const totalInvested = founders.reduce((sum, founder) => sum + (founder.capitalInvested || 0), 0);
        return Math.abs(totalInvested - totalShareCapital) < 0.01; // Allow for small floating point errors
      }),
  });

  const handleSubmit = async (values, { setSubmitting, validateForm }) => {
    // Validate the form
    const errors = await validateForm(values);

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      setNotification({
        show: true,
        type: 'error',
        message: t('Please correct the following errors:'),
        errors: Object.values(errors).flat(),
      });
      setSubmitting(false);
      return;
    }

    // Check if all capital has been invested
    const shareStats = calculateShareStats(values.founders);
    if (shareStats.remainingCapital > 0) {
      setNotification({
        show: true,
        type: 'error',
        message: t('Cannot save: Not all capital has been invested.'),
        errors: [
          `${formatNumber(shareStats.remainingCapital)} € (${formatNumber(100 - shareStats.totalPercentage)}%) of capital still needs to be invested.`,
        ],
      });
      setSubmitting(false);
      return;
    }

    try {
      // Save the data (pass values directly)
      const result = await saveData(wizardName, 'founders', values);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('Data saved successfully'),
        });

        // Update wizard data (pass values directly)
        updateWizardData('founders', values, false);
        updateWizardData('founders', values, true);

        setTimeout(() => navigate('/startup-wizard/articlesOfAssociation'), 1000);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('Error saving data'),
        errors: [error.message],
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDraftSubmit = async (values, { setSubmitting }) => {
    try {
      // Save as draft (pass values directly)
      const result = await saveAsDraft(wizardName, 'founders', values);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('Draft saved successfully'),
        });

        // Update wizard data (pass values directly)
        updateWizardData('founders', values, true);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('Error saving draft'),
        errors: [error.message],
      });
    } finally {
      setSubmitting(false);
    }
  };

  const calculateShareStats = (founders = []) => {
    const totalInvested = founders.reduce((sum, founder) => sum + (founder.capitalInvested || 0), 0);
    const remainingCapital = totalShareCapital - totalInvested;
    const totalPercentage = (totalInvested / totalShareCapital) * 100;

    return {
      totalInvested,
      remainingCapital,
      totalPercentage,
    };
  };

  const formatNumber = (number, decimalPlaces = 2) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(number);
  };

  if (!initialValues) {
    return <div>Loading...</div>;
  }

  if (introLoading) return <div>{t('common.loading')}</div>;
  if (introError) return <div>{t('common.error', { error: introError?.message })}</div>;

  if (introData && !introData.disclaimerChecked) {
    return <DisclaimerCheck />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <Notification
        show={notification.show}
        setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />

      <DataStatusBanner status={dataStatus} />

      <div className="mt-8">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
          {t('Founding Team')}
        </p>
      </div>

      <div className="mt-6 text-gray-600">
        <p>{t('The founding team of a startup typically consists of the initial group of individuals who come together to create and launch the business. These are the co-founders who contribute to the startups vision, strategy, and early development. The founding team is crucial in shaping the companys culture, building the initial product or service, and setting the direction for future growth. Members of the founding team often bring complementary skills, such as technical expertise, business acumen, marketing, and sales, which are essential for the startups success.')}</p>
        <p className="mt-4">{t('The distribution of shares should reflect the contributions, risks, and commitments of each founder, while also considering the long-term sustainability of the team and the company. It is a foundational decision that can significantly impact the startups future, so it is crucial to approach it thoughtfully and with full transparency among the founding team.')}</p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, submitForm }) => {
          const shareStats = values.founders
            ? calculateShareStats(values.founders)
            : { totalShares: 0, attributedShares: 0, remainingShares: totalShareCapital };

          return (
            <Form className="mt-8 space-y-8">

              <AutoSave
                values={values}
                liveMode={liveMode}
                wizardName={wizardName}
                updateWizardData={updateWizardData}
              />

              <div
                id="share-capital-overview"
                className={`bg-white overflow-hidden shadow rounded-lg transition-all duration-300 ${isOverviewFixed
                    ? 'fixed top-0 left-[256px] right-0 z-10'
                    : ''
                  }`}
                style={{
                  width: isOverviewFixed ? 'calc(100% - 256px)' : '100%',
                }}
              >
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className={`text-lg font-medium leading-6 ${isOverviewFixed ? 'text-white' : 'text-gray-900'} transition-colors duration-300`}>
                      {t('Share Capital Overview')}
                    </h3>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{t('Total Share Capital')}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{formatNumber(totalShareCapital)} €</dd>
                      </div>
                      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{t('Capital Invested')}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-green-600">
                          {formatNumber(shareStats.totalInvested)} €
                          <span className="text-sm text-gray-500 ml-2">({formatNumber(shareStats.totalPercentage)}%)</span>
                        </dd>
                      </div>
                      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{t('Capital to be Invested')}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-red-600">
                          {formatNumber(shareStats.remainingCapital)} €
                          <span className="text-sm text-gray-500 ml-2">({formatNumber(100 - shareStats.totalPercentage)}%)</span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                {values.founders.map((founder, index) => (
                  <FounderCard
                    key={index}
                    founder={founder}
                    index={index}
                    setFieldValue={setFieldValue}
                    values={values}
                    onRemove={() => {
                      if (values.founders.length > 1) {
                        const newFounders = values.founders.filter((_, i) => i !== index);
                        setFieldValue('founders', newFounders);
                      }
                    }}
                    totalShareCapital={totalShareCapital}
                  />
                ))}
                <FounderCardAdd
                  onAdd={() => {
                    const newFounder = {
                      fullName: '',
                      dateOfBirth: '',
                      nationality: '',
                      address: {
                        street: '',
                        streetAdditional: '',
                        zip: '',
                        place: '',
                        country: '',
                      },
                      email: '',
                      phone: '',
                      isManagingDirector: false,
                      sharePercentage: 0,
                      capitalInvested: 0,
                    };
                    const newFounders = [...values.founders, newFounder];
                    setFieldValue('founders', newFounders);
                  }}
                />
              </div>

              <ActionButtons
                onCancel={() => navigate('/startup-wizard')}
                onDraft={() => handleDraftSubmit(values, { setSubmitting: () => {} })}
                onSubmit={submitForm}
                isSubmitting={isSubmitting}
                currentPage="founders"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

// AutoSave Component
const AutoSave = ({ values, liveMode, wizardName, updateWizardData }) => {
  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode && values) {
      const result = await saveAsDraft(wizardName, 'founders', values);
      if (result.success) {
        updateWizardData('founders', values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, values]);

  return null;
};


export default StartupWizardFounders;