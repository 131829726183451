import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SparklesIcon, BriefcaseIcon, LockClosedIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';

const Home = () => {
  const { t } = useTranslation();

  const handleLinkClick = (category, action, name) => {
    window._paq.push(['trackEvent', category, action, name]);
  };

  const tiers = [
    {
      name: t('pricing.founders.name'),
      id: 'tier-founders',
      href: '/signup',
      priceMonthly: '€5',
      description: t('pricing.founders.description'),
      features: [
        t('pricing.founders.feature1'),
        t('pricing.founders.feature2'),
        t('pricing.founders.feature3'),
        t('pricing.founders.feature4'),
      ],
      featured: false,
    },
    {
      name: t('pricing.professional.name'),
      id: 'tier-professional',
      href: '/signup',
      priceMonthly: '€9',
      description: t('pricing.professional.description'),
      features: [
        t('pricing.professional.feature1'),
        t('pricing.professional.feature2'),
        t('pricing.professional.feature3'),
        t('pricing.professional.feature4'),
        t('pricing.professional.feature5'),
      ],
      featured: true,
    },
  ];

  const features = [
    {
      name: t('features.wizards.title'),
      description: t('features.wizards.description'),
      icon: SparklesIcon,
    },
    {
      name: t('features.collaboration.title'),
      description: t('features.collaboration.description'),
      icon: BriefcaseIcon,
    },
    {
      name: t('features.gdpr.title'),
      description: t('features.gdpr.description'),
      icon: LockClosedIcon,
    },
  ];

  const faqs = [
    {
      question: t('faq.difference.question'),
      answer: t('faq.difference.answer'),
    },
    {
      question: t('faq.implementation.question'),
      answer: t('faq.implementation.answer'),
    },
    {
      question: t('faq.security.question'),
      answer: t('faq.security.answer'),
    },
  ];

  return (
    <div className="bg-white">

      {/* Banner */}
      <div className="fixed top-0 flex w-full z-40 items-center gap-x-6 bg-secondary-color px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p className="text-xs text-white">
          <strong className="font-semibold">{t('betabanner1')}</strong>
          <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          {t('betabanner2')}
          <strong className="font-semibold">{t('betabanner3')}</strong>
        </p>
        <div className="flex flex-1 justify-end">
          <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
            <span className="sr-only">Dismiss</span>
            <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
          </button>
        </div>
      </div>

      {/* Hero section */}
      <div className="relative isolate">
        <div className="px-6 pb-0 pt-16 lg:px-8">
          <div className="flex justify-between items-center">
            <div className="w-32 " />
            <img src="/logo_twocolor.svg" alt={t('common.logoAlt')} className="h-24 mt-6" />
            <Link
              to="/login"
              className="rounded-lg bg-primary-color px-4 py-2 text-sm font-semibold text-white hover:bg-secondary-color"
              onClick={() => handleLinkClick('Homepage', 'Click', 'Login')}
            >
              {t('navigation.login')}
            </Link>
          </div>
        </div>

        <div className="mx-auto max-w-4xl pt-24 sm:pt-24 pb-24 sm:pb-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              {t('hero.title.start')} {' '}
              <span className="text-secondary-color">{t('hero.title.highlight')}</span>.
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t('hero.subtitle')}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/signup"
                className="rounded-md bg-secondary-color px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color"
                onClick={() => handleLinkClick('Homepage', 'Click', 'Signup')}
              >
                {t('common.getStarted')}
              </Link>
              <a href="#features" className="text-sm font-semibold leading-6 text-gray-900">
                {t('common.learnMore')} <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Feature section */}
      <div id="features" className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-0">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold pt-0 pb-0 leading-7 text-primary-color">{t('features.heading')}</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('features.title')}</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {t('features.subtitle')}
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-secondary-color">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-secondary-color" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              src="/dashboard.png"
              alt={t('features.dashboardAlt')}
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
          </div>
        </div>
      </div>

      {/* Updated About/Testimonial section */}
      <div className="relative z-10 mt-32 bg-gray-900 pb-20 sm:mt-56 sm:pb-24 xl:pb-0">
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-primary-color to-secondary-color opacity-25"
            />
          </div>
        </div>
        <div className="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
          <div className="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
            <div className="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
              <img
                src="/team.png"
                alt={t('about.teamAlt')}
                className="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl"
              />
            </div>
          </div>
          <div className="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
            <figure className="relative isolate pt-6 sm:pt-12">
              <svg
                viewBox="0 0 162 128"
                aria-hidden="true"
                className="absolute left-0 top-0 -z-10 h-32 stroke-white/20"
              >
                <path
                  id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                  fill="none"
                  d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                />
                <use xlinkHref="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
              </svg>
              <blockquote className="text-2xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
                <p className="text-2xl font-semibold text-white">
                  {t('about.quote')}
                </p>
              </blockquote>
              <figcaption className="mt-8 text-base">
                <div className="font-semibold text-white">{t('about.authors')}</div>
                <div className="mt-1 text-gray-400">{t('about.role')}</div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>

      {/* Pricing section */}
      <div className="bg-white py-24 mt-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold pb-0 leading-7 text-primary-color">{t('pricing.heading')}</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {t('pricing.title')}
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            {t('pricing.subtitle')}
          </p>
          <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
            {tiers.map((tier, tierIdx) => (
              <div
                key={tier.id}
                className={`rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10
                  ${tier.featured ? 'relative bg-gray-900 shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0'}
                  ${tierIdx === 0 ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none' : ''}
                `}
              >
                <h3 className={`text-base font-semibold leading-7 ${tier.featured ? 'text-secondary-color' : 'text-secondary-color'}`}>
                  {tier.name}
                </h3>
                <p className="mt-4 flex items-baseline gap-x-2">
                  <span className={`text-5xl font-bold tracking-tight ${tier.featured ? 'text-white' : 'text-gray-900'}`}>
                    {tier.priceMonthly}
                  </span>
                  <span className={tier.featured ? 'text-gray-400' : 'text-gray-500'}>{t('pricing.perMonth')}</span>
                </p>
                <p className={`mt-6 text-base leading-7 ${tier.featured ? 'text-gray-300' : 'text-gray-600'}`}>
                  {tier.description}
                </p>
                <ul role="list" className={`mt-8 space-y-3 text-sm leading-6 ${tier.featured ? 'text-gray-300' : 'text-gray-600'}`}>
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className={`h-6 w-5 flex-none ${tier.featured ? 'text-secondary-color' : 'text-secondary-color'}`} />
                      {feature}
                    </li>
                  ))}
                </ul>
                <Link
                  to={tier.href}
                  className={`mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                    ${tier.featured ? 'bg-primary-color text-white hover:bg-secondary-color' : 'text-primary-color ring-1 ring-inset ring-primary-color hover:ring-secondary-color'}`}
                >
                  {t('pricing.getStarted')}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ section */}
      <div className="bg-white mb-32">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-5">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                {t('faq.title')}
              </h2>
              <p className="mt-0 text-base leading-7 text-gray-600">
                {t('faq.contactUs')}{' '}
                <a href="mailto:support@cenedril.net" className="font-semibold text-primary-color hover:text-secondary-color">
                  {t('faq.contactLink')}
                </a>
              </p>
            </div>
            <div className="mt-10 lg:col-span-7 lg:mt-0">
              <dl className="space-y-10">
                {faqs.map((faq) => (
                  <div key={faq.question}>
                    <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;