import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from '../axios';
import { useNavigate, useLocation } from 'react-router-dom';

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    console.log('Logging out user');
    setUser(null);
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('tokenExpiresAt');
    sessionStorage.removeItem('email');
  }, []);

  const checkTokenExpiration = useCallback(() => {
    try {
      const token = sessionStorage.getItem('authToken');
      const expiration = sessionStorage.getItem('tokenExpiresAt');
      const now = Math.floor(new Date().getTime() / 1000);
  
      if (!token || !expiration) {
        console.log('No token or expiration found. User is not logged in.');
        return false;
      }
  
      const expirationTime = parseInt(expiration, 10);
      if (isNaN(expirationTime)) {
        console.log('Invalid expiration time. Logging out.');
        logout();
        navigate('/session-expired');
        return false;
      }
  
      if (now >= expirationTime) {
        console.log('Token has expired. Logging out.');
        logout();
        navigate('/session-expired');
        return false;
      }
  
      return true;
    } catch (error) {
      console.error('Error in checkTokenExpiration:', error);
      return false;
    }
  }, [logout, navigate]);

  useEffect(() => {
  
    const checkAndFetchUser = async () => {
      const token = sessionStorage.getItem('authToken');
      if (token) {
        console.log('Token found, checking expiration');
        if (checkTokenExpiration()) {
          try {
            console.log('Fetching user data');
            const response = await axios.get('/api/v1/live_data/user_data', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            if (response.data) {
              console.log('User data fetched successfully');
              setUser(response.data);
              sessionStorage.setItem('user', JSON.stringify(response.data));
            }
          } catch (error) {
            console.error('Error fetching user:', error);
            logout();
            navigate('/session-expired');
          }
        }
      } else {
      }
      setLoading(false);
    };
  
    checkAndFetchUser();
  
    const intervalId = setInterval(() => {
      const token = sessionStorage.getItem('authToken');
      if (token) {
        checkTokenExpiration();
      } else {
      }
    }, 10000); // Check every 10 seconds
  
    return () => {
      clearInterval(intervalId);
    };
  }, [location.pathname, checkTokenExpiration, logout, navigate]);

  const login = (userData) => {
    const expirationTime = Math.floor(new Date().getTime() / 1000) + 24 * 60 * 60; // 24 hours from now in seconds
    console.log('Logging in user. Token expires at:', new Date(expirationTime * 1000).toLocaleString());
    setUser(userData);
    sessionStorage.setItem('user', JSON.stringify(userData));
    sessionStorage.setItem('authToken', userData.authentication_token);
    sessionStorage.setItem('tokenExpiresAt', expirationTime.toString());
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
}