import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from "react-icons/io5";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { countryData } from '../../constants/countryData';

const sortedCountryData = [...countryData].sort((a, b) => a.name.localeCompare(b.name));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const OfficeCard = ({ office, index, onRemove, setFieldValue, companyCountry }) => {
  const { t } = useTranslation();

  const disabledInputClass = "mt-1 block w-full border-gray-300 rounded-md bg-gray-100 text-gray-500 cursor-not-allowed";
  const enabledInputClass = "mt-1 block w-full border-gray-300 rounded-md";

  // Handle employee number changes
  const handleEmployeeChange = (e, field) => {
    const value = e.target.value;
    
    // If empty, set to 0
    if (value === '') {
      field.onChange({
        target: {
          name: field.name,
          value: 0
        }
      });
      return;
    }

    // Convert to number and validate
    const numValue = parseInt(value, 10);
    
    // Only allow positive numbers
    if (!isNaN(numValue) && numValue >= 0) {
      field.onChange({
        target: {
          name: field.name,
          value: numValue
        }
      });
    }
  };

  return (
    <div className="border rounded-lg p-4 mb-4 bg-white">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">
          {index === 0 ? t('Remote Workers') : (office.location ? office.location : `${t('Office')} ${index}`)}
        </h3>
        {index > 1 && (
          <button
            type="button"
            className="text-red-500 hover:text-red-700 transition-colors duration-200"
            onClick={onRemove}
            aria-label={t('Remove')}
          >
            <IoTrashOutline className="w-5 h-5" />
          </button>
        )}
      </div>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Location')}</label>
        {index === 0 ? (
          <input
            value={t('Remote Workers')}
            className={disabledInputClass}
            disabled
          />
        ) : (
          <Field name={`offices.${index}.location`} className={enabledInputClass} />
        )}
      </div>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Country')}</label>
        {index <= 1 ? (
          <input
            value={t(`Countries.${sortedCountryData.find(c => c.code === companyCountry)?.name || ''}`)}
            className={disabledInputClass}
            disabled
          />
        ) : (
          <Listbox
            value={sortedCountryData.find(c => c.code === office.country) || sortedCountryData[0]}
            onChange={(selectedCountry) => setFieldValue(`offices.${index}.country`, selectedCountry.code)}
          >
            {({ open }) => (
              <>
                <div className="relative mt-1">
                  <ListboxButton className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-color focus:outline-none focus:ring-1 focus:ring-primary-color sm:text-sm">
                    <span className="block truncate">
                      {t(`Countries.${sortedCountryData.find(c => c.code === office.country)?.name || ''}`)}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <ListboxOptions
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {sortedCountryData.map((country) => (
                      <ListboxOption
                        key={country.code}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-primary-color' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={country}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {t(`Countries.${country.name}`)}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary-color',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </>
            )}
          </Listbox>
        )}
      </div>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Number of employees')}</label>
        <Field name={`offices.${index}.employees`}>
          {({ field }) => (
            <input
              {...field}
              type="number"
              min="0"
              value={field.value}
              onChange={(e) => handleEmployeeChange(e, field)}
              onBlur={(e) => {
                // Ensure we always have at least 0
                if (e.target.value === '' || isNaN(parseInt(e.target.value, 10))) {
                  field.onChange({
                    target: {
                      name: field.name,
                      value: 0
                    }
                  });
                }
                field.onBlur(e);
              }}
              className={enabledInputClass}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

export default OfficeCard;