import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../axios';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon, PaintBrushIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import KnowYourselfOverviewDisplay from '../../../components/KnowYourselfOverviewDisplay';
import Notification from '../../../components/Notification';

const KnowYourselfOverview = () => {
  const [company, setCompany] = useState(null);
  const [knowYourselfResponse, setKnowYourselfResponse] = useState({});
  const [knowYourselfDraftResponse, setKnowYourselfDraftResponse] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
          }
        });
        setCompany(response.data.entity);
        setKnowYourselfResponse(response.data.entity.know_yourself_response?.published || {});
        setKnowYourselfDraftResponse(response.data.entity.know_yourself_response?.draft || {});
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanyData();
  }, []);

  const pages = [
    { id: "industry", name: t('KnowYourselfProgress.industry'), fields: ['industry', 'otherIndustry', 'sector', 'otherSector', 'industryNotes'] },
    { id: "market", name: t('KnowYourselfProgress.market'), fields: ['regions', 'subregions', 'marketNotes'] },
    { id: "business-model", name: t('KnowYourselfProgress.businessModel'), fields: ['clientele', 'businessSectors', 'clienteleAgeGroup', 'clienteleGenders', 'clienteleIncome', 'revenueCategories', 'modelNotes'] },
    { id: "product", name: t('KnowYourselfProgress.productsServices'), fields: ['products'] },
    { id: "team", name: t('KnowYourselfProgress.team'), fields: ['founders', 'keyTeamMembers', 'responsibilities', 'offices'] },
    { id: "investment", name: t('KnowYourselfProgress.funding'), fields: ['investmentStage', 'lookingForFunding', 'fundingAmount', 'fundingCurrency', 'targetFundingRound', 'exitStrategies', 'investmentNotes'] },
  ];

  const initialValues = {
    industry: '', otherIndustry: '', sector: '', otherSector: '', industryNotes: '',
    regions: [], subregions: [], marketNotes: '',
    clientele: [], businessSectors: [], clienteleAgeGroup: [0, 100], clienteleGenders: '', clienteleIncome: [], revenueCategories: {SalesBasedModels: [], ServiceBasedModels: [], RecurringRevenueModels: [], AlternativeRevenueModels: []}, modelNotes: '',
    products: [],
    founders: [], keyTeamMembers: [], responsibilities: {}, offices: [],
    investmentStage: '', lookingForFunding: '', fundingAmount: '', fundingCurrency: '', targetFundingRound: '', exitStrategies: [], investmentNotes: ''
  };

  const isEqual = (value1, value2) => {
    return JSON.stringify(value1) === JSON.stringify(value2);
  };
  
  const isDraft = (page) => {
    return page.fields.some(field => {
      const draftValue = knowYourselfDraftResponse[field];
      const publishedValue = knowYourselfResponse[field];
      const initialValue = initialValues[field];
      return !isEqual(draftValue, publishedValue) && !isEqual(draftValue, initialValue);
    });
  };
  
  const isPublished = (page) => {
    return page.fields.some(field => {
      const publishedValue = knowYourselfResponse[field];
      const initialValue = initialValues[field];
      return publishedValue !== undefined && 
             publishedValue !== null && 
             publishedValue !== '' && 
             !isEqual(publishedValue, initialValue);
    });
  };
  
  const getStepStatus = (page) => {
    const isPageEmpty = page.fields.every(field => 
      isEqual(knowYourselfResponse[field], initialValues[field]) &&
      isEqual(knowYourselfDraftResponse[field], initialValues[field])
    );
  
    if (isPageEmpty) return 'empty';
    if (isDraft(page)) return 'draft';
    if (isPublished(page)) return 'complete';
    return 'empty';
  };
  
  const isDataComplete = () => {
    return pages.every(page => isPublished(page));
  };

  const renderStepIcon = (status) => {
    switch (status) {
      case 'complete':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        );
      case 'draft':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-[rgb(254,249,195)]">
            <PaintBrushIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </span>
        );
      default:
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
            <span className="text-gray-500 text-xl"></span>
          </span>
        );
    }
  };

  const handleDelete = (page) => {
    const status = getStepStatus(page);
    if (status !== 'empty') {
      setPageToDelete(page);
      setDeleteModalOpen(true);
    }
  };

  const confirmDelete = async () => {
    if (pageToDelete) {
      try {
        await axios.post('/api/v1/wizards/know_yourself/reset', { pageId: pageToDelete.id }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
          }
        });
  
        const resetData = pageToDelete.fields.reduce((acc, field) => {
          acc[field] = initialValues[field];
          return acc;
        }, {});
  
        setKnowYourselfResponse(prev => ({...prev, ...resetData}));
        setKnowYourselfDraftResponse(prev => ({...prev, ...resetData}));
  
        setNotification({
          show: true,
          type: 'success',
          message: t('Data reset successfully')
        });
  
        setTimeout(() => setNotification(prev => ({ ...prev, show: false })), 3000);
      } catch (error) {
        console.error('Error resetting data:', error);
        setNotification({
          show: true,
          type: 'error',
          message: t('Error resetting data. Please try again.')
        });
      }
      setDeleteModalOpen(false);
      setPageToDelete(null);
    }
  };

  if (!company) {
    return <div>{t('Loading...')}</div>;
  }

  const generalInfo = {
    'Company Name': company.name,
    'Website': company.website,
    'Country': company.country,
    'Year Founded': company.year_founded
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <Notification
      show={notification.show}
      setShow={(show) => setNotification(prev => ({ ...prev, show }))}
      type={notification.type}
      message={notification.message}
        />
        <h1 className="text-2xl font-bold mb-4">{t('Know Yourself Wizard')}</h1>
        <div className="bg-white shadow rounded-lg">
          <nav aria-label="Progress">
            <ol role="list">
              {pages.map((page, pageIdx) => {
                const status = getStepStatus(page);
                return (
                  <li key={page.id} className={pageIdx !== pages.length - 1 ? 'border-b border-gray-200' : ''}>
                    <div className="w-full text-left px-6 py-5 flex items-center justify-between">
                      <button 
                        onClick={() => navigate(`/know-yourself/${page.id}`)} 
                        className="flex items-center flex-grow"
                      >
                        <span className="flex-shrink-0">
                          {renderStepIcon(status)}
                        </span>
                        <span className="ml-6 flex flex-col text-left">
                          <span className="text-sm font-medium text-gray-900">{page.name}</span>
                          <span className="text-sm font-medium text-gray-500">
                            {status === 'complete' ? t("Page published") : 
                             status === 'draft' ? t("Draft ongoing") : 
                             t("No data")}
                          </span>
                        </span>
                      </button>
                      <div>
                      <button 
                        onClick={() => navigate(`/know-yourself/${page.id}`)}
                        className="text-primary-color hover:text-secondary-color mr-4"
                        aria-label={t("Edit")}
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </button>
                      <button 
                        onClick={() => handleDelete(page)}
                        className={`text-red-600 hover:text-red-800 ${getStepStatus(page) === 'empty' ? 'opacity-0 cursor-default' : ''}`}
                        aria-label={t("Delete")}
                        disabled={getStepStatus(page) === 'empty'}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          </nav>
        </div>
        {isDataComplete() && (
        <div id="know-yourself-overview" className="mt-8">
          <KnowYourselfOverviewDisplay 
            data={knowYourselfResponse} 
            generalInfo={generalInfo} 
            pages={pages}
          />
        </div>
        )}
      </div>

      <Transition.Root show={deleteModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDeleteModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          {t("Delete data")}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {t("Are you sure you want to delete all data for this page? This action cannot be undone.")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={confirmDelete}
                    >
                      {t("Delete")}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDeleteModalOpen(false)}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default KnowYourselfOverview;