// src/components/wizard_components/TextField.js
import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const TextField = ({ label = '', helperText = '', ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="col-span-full">
      {label && (
        <label htmlFor={props.id || props.name} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        {props.type === 'textarea' ? (
          <textarea
            {...field}
            {...props}
            rows="3"
            className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6`}
          ></textarea>
        ) : (
          <input
            {...field}
            {...props}
            className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6`}
          />
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
      {!meta.error && helperText && (
        <p className="mt-3 text-sm leading-6 text-gray-600">{helperText}</p>
      )}
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
};

export default TextField;
