import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoAddCircle } from "react-icons/io5";

const OfficeCardAdd = ({ onAdd }) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="group relative flex flex-col items-center justify-center w-full h-[308px] rounded-lg border-2 border-dashed border-gray-300 bg-white text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-secondary-color focus:ring-offset-2"
      onClick={onAdd}
    >

<IoAddCircle className="text-5xl text-primary-color group-hover:text-secondary-color transition-colors duration-200 mb-2" />
      <span className="block text-sm font-semibold text-primary-color group-hover:text-secondary-color transition-colors duration-200">{t('Add another office')}
      </span>
    </button>
  );
};

export default OfficeCardAdd;