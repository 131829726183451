import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { RadioGroup } from '@headlessui/react';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import StartupWizardDrawer from '../../../components/wizard_components/StartupWizardDrawer';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Formik, Form } from 'formik';
import DisclaimerCheck from '../../../components/wizard_components/DisclaimerCheck';
import {
  BOOLEAN_VALUES,
  MEETING_LOCATIONS,
  EXTRAORDINARY_RESOLUTIONS,
  convertUIToStorage,
  convertStorageToDocument,
  getOptionsForField
} from '../../../constants/translationMappings';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Debounce function remains the same
function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

const StartupWizardArticlesOfAssociation = () => {
  const { wizardName, updateWizardData, liveMode, toggleLiveMode } = useWizard();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'articlesOfAssociation');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const { data: introData, loading: introLoading, error: introError } = useFetchData(wizardName, 'introduction');

  useEffect(() => {
    if (introData) {
      setDisclaimerAccepted(introData.disclaimerChecked || false);
    }
  }, [introData]);

  const getInitialValues = (data, i18n) => {
    const defaultValues = {
      companyPurpose: '',
      operationRestriction: BOOLEAN_VALUES.NO,
      terminationDate: '',
      financialYearDeviation: BOOLEAN_VALUES.NO,
      financialYearStandard: BOOLEAN_VALUES.YES,
      financialYearStart: '',
      shareholderMeetingNotice: '4',
      shareholderMeetingQuorum: '50',
      meetingLocations: [
        MEETING_LOCATIONS.STATUTORY_SEAT,
        MEETING_LOCATIONS.REMOTE,
        MEETING_LOCATIONS.ANYWHERE
      ],
      chairmanAppointmentQuorum: '50',
      ordinaryResolutionThreshold: '50',
      extraordinaryResolutionThreshold: '75',
      extraordinaryResolutions: Object.values(EXTRAORDINARY_RESOLUTIONS),
      shareholderTermination: BOOLEAN_VALUES.YES
    };

    if (!data) return defaultValues;

    // Convert stored values to display values
    const convertedValues = {
      ...defaultValues,
      ...data,
      operationRestriction: data.operationRestriction || defaultValues.operationRestriction,
      financialYearDeviation: data.financialYearDeviation || defaultValues.financialYearDeviation,
      financialYearStandard: data.financialYearStandard || defaultValues.financialYearStandard,
      shareholderTermination: data.shareholderTermination || defaultValues.shareholderTermination,
    };

    return convertedValues;
  };

  const processValuesForSave = (values) => {
    return {
      ...values,
      operationRestriction: convertUIToStorage(values.operationRestriction),
      financialYearDeviation: convertUIToStorage(values.financialYearDeviation),
      financialYearStandard: convertUIToStorage(values.financialYearStandard),
      shareholderTermination: convertUIToStorage(values.shareholderTermination),
      meetingLocations: values.meetingLocations.map(location => convertUIToStorage(location)),
      extraordinaryResolutions: values.extraordinaryResolutions.map(resolution => convertUIToStorage(resolution))
    };
  };

  const getBooleanOptions = () => getOptionsForField('boolean', i18n.language);
  const getMeetingLocationOptions = () => getOptionsForField('meetingLocations', i18n.language);
  const getExtraordinaryResolutionOptions = () => getOptionsForField('extraordinaryResolutions', i18n.language);

  const handleSave = async (values, { setSubmitting }) => {
    const processedValues = processValuesForSave(values);
    const result = await saveData(wizardName, 'articlesOfAssociation', processedValues);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('Data saved successfully') });
      updateWizardData('articlesOfAssociation', processedValues, false);
      updateWizardData('articlesOfAssociation', processedValues, true);
      setTimeout(() => navigate('/startup-wizard/shareholdersAgreement'), 1000);
    } else {
      setNotification({ show: true, type: 'error', message: t('Error saving data') });
    }
    setSubmitting(false);
  };

  const handleSaveAsDraft = async (values, { setSubmitting }) => {
    const processedValues = processValuesForSave(values);
    const result = await saveAsDraft(wizardName, 'articlesOfAssociation', processedValues);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('Saved as draft successfully') });
      updateWizardData('articlesOfAssociation', processedValues, true);
    } else {
      setNotification({ show: true, type: 'error', message: t('Error saving as draft') });
    }
    setSubmitting(false);
  };

   // AutoSave component updated with translation handling
   const AutoSave = ({ values, liveMode, wizardName, updateWizardData }) => {
    const autoSaveDraft = useCallback(async () => {
      if (liveMode && values) {
        const processedValues = processValuesForSave(values);
        const result = await saveAsDraft(wizardName, 'articlesOfAssociation', processedValues);
        if (result.success) {
          updateWizardData('articlesOfAssociation', processedValues, true);
        } else {
          console.error('Failed to save draft:', result.error);
        }
      }
    }, [liveMode, values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, values]);

  return null;
};

  const validationSchema = Yup.object({
    companyPurpose: Yup.string().required(t('Company purpose is required')),
  });

  const handleOpenDrawer = (title, content) => {
    setDrawerContent({ title, content });
    setDrawerOpen(true);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return <div>No data available</div>;

  if (introLoading) return <div>{t('loading')}</div>;
  if (introError) return <div>{t('error', { error: introError?.message })}</div>;

  if (introData && !introData.disclaimerChecked) {
    return <DisclaimerCheck />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <DataStatusBanner status={dataStatus} />

      <div className="text-left mt-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          {t('StartupWizard.articlesOfAssociation')}
        </h1>
        <p className="mt-4 text-base text-gray-600">
          {t('StartupWizard.articlesOfAssociationDescription')}
        </p>
      </div>

      <Formik
        initialValues={getInitialValues(data, i18n)}
        validationSchema={validationSchema}
        onSubmit={handleSave}
        enableReinitialize={true}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, submitForm, setSubmitting }) => (
          <Form className="mt-12 space-y-8">
            <AutoSave
              values={values}
              liveMode={liveMode}
              wizardName={wizardName}
              updateWizardData={updateWizardData}
            />

            {/* Company Purpose */}
            <div>
              <div className="flex items-center">
                <label htmlFor="companyPurpose" className="block text-lg font-medium text-gray-700">
                  {t('StartupWizard.questions.companyPurpose.label')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('StartupWizard.questions.companyPurpose.tooltipTitle'),
                    t('StartupWizard.questions.companyPurpose.tooltip')
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <textarea
                name="companyPurpose"
                id="companyPurpose"
                value={values.companyPurpose}
                onChange={(e) => setFieldValue('companyPurpose', e.target.value)}
                rows="3"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
              />
            </div>

            {/* Operation Restriction */}
            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('StartupWizard.questions.operationRestriction.label')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('StartupWizard.questions.operationRestriction.tooltipTitle'),
                    t('StartupWizard.questions.operationRestriction.tooltip')
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <RadioGroup
                value={values.operationRestriction}
                onChange={(value) => setFieldValue('operationRestriction', value)}
              >
                <div className="space-y-2">
                  {getBooleanOptions().map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option.value}
                      className={({ checked }) =>
                        classNames(
                          'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                          checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label as="span" className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}>
                              {option.label}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {/* Termination Date (conditional) */}
            {values.operationRestriction === BOOLEAN_VALUES.YES && (
              <div>
                <label htmlFor="terminationDate" className="block text-lg font-medium text-gray-700">
                  {t('StartupWizard.questions.financialYear.deviation.label')}
                </label>
                <input
                  type="date"
                  name="terminationDate"
                  id="terminationDate"
                  value={values.terminationDate}
                  onChange={(e) => setFieldValue('terminationDate', e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                />
              </div>
            )}

            {/* Financial Year Deviation */}
            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('StartupWizard.questions.financialYear.deviation.label')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('StartupWizard.questions.financialYear.deviation.tooltipTitle'),
                    t('StartupWizard.questions.financialYear.deviation.tooltip')
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <RadioGroup
                value={values.financialYearDeviation}
                onChange={(value) => setFieldValue('financialYearDeviation', value)}
              >
                <div className="space-y-2">
                  {getBooleanOptions().map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option.value}
                      className={({ checked }) =>
                        classNames(
                          'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                          checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label as="span" className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}>
                              {option.label}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {/* Financial Year Standard (conditional) */}
            {values.financialYearDeviation === BOOLEAN_VALUES.YES && (
              <>
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-2">
                    {t('StartupWizard.questions.financialYear.standard')}
                  </label>
                  <RadioGroup
                    value={values.financialYearStandard}
                    onChange={(value) => setFieldValue('financialYearStandard', value)}
                  >
                    <div className="space-y-2">
                      {getBooleanOptions().map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                          className={({ checked }) =>
                            classNames(
                              'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                              checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <span className={classNames(
                                checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                                active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                              )}>
                                <span className="rounded-full bg-white w-1.5 h-1.5" />
                              </span>
                              <div className="ml-3 flex flex-col">
                                <RadioGroup.Label as="span" className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}>
                                  {option.label}
                                </RadioGroup.Label>
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                {values.financialYearStandard === BOOLEAN_VALUES.NO && (
                  <div>
                    <label htmlFor="financialYearStart" className="block text-lg font-medium text-gray-700">
                      {t('StartupWizard.questions.financialYear.start')}
                    </label>
                    <input
                      type="date"
                      name="financialYearStart"
                      id="financialYearStart"
                      value={values.financialYearStart}
                      onChange={(e) => setFieldValue('financialYearStart', e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                    />
                  </div>
                )}
              </>
            )}

            {/* Meeting Locations */}
            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('StartupWizard.questions.shareholderMeeting.locations.label')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('StartupWizard.questions.shareholderMeeting.locations.tooltipTitle'),
                    t('StartupWizard.questions.shareholderMeeting.locations.tooltip')
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="space-y-2">
                {getMeetingLocationOptions().map((option) => (
                  <label key={option.value} className="flex items-center">
                    <input
                      type="checkbox"
                      name="meetingLocations"
                      value={option.value}
                      checked={values.meetingLocations.includes(option.value)}
                      onChange={(e) => {
                        const newMeetingLocations = e.target.checked
                          ? [...values.meetingLocations, option.value]
                          : values.meetingLocations.filter(loc => loc !== option.value);
                        setFieldValue('meetingLocations', newMeetingLocations);
                      }}
                      className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700">{option.label}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Extraordinary Resolutions */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                {t('StartupWizard.questions.resolutions.extraordinary.catalog')}
              </label>
              <div className="space-y-2">
                {getExtraordinaryResolutionOptions().map((option) => (
                  <label key={option.value} className="flex items-center">
                    <input
                      type="checkbox"
                      name="extraordinaryResolutions"
                      value={option.value}
                      checked={values.extraordinaryResolutions.includes(option.value)}
                      onChange={(e) => {
                        const newExtraordinaryResolutions = e.target.checked
                          ? [...values.extraordinaryResolutions, option.value]
                          : values.extraordinaryResolutions.filter(res => res !== option.value);
                        setFieldValue('extraordinaryResolutions', newExtraordinaryResolutions);
                      }}
                      className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700">{option.label}</span>
                  </label>
                ))}
              </div>
            </div>

            <ActionButtons
              onCancel={() => navigate('/startup-wizard')}
              onDraft={() => handleSaveAsDraft(values, { setSubmitting })}
              onSubmit={submitForm}
              isSubmitting={isSubmitting}
              currentPage="articlesOfAssociation"
            />
          </Form>
        )}
      </Formik>

      <Notification
        show={notification.show}
        setShow={(show) => setNotification(prev => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />

      <StartupWizardDrawer
        isOpen={drawerOpen}
        title={drawerContent.title}
        content={drawerContent.content}
        onClose={() => setDrawerOpen(false)}
      />
    </div>
  );
};

export default StartupWizardArticlesOfAssociation;