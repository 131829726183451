// src/components/NewTaskCard.js
import React, { useState } from 'react';
import axios from '../axios';
import { useTranslation } from 'react-i18next';

const NewTaskCard = ({ onAdd }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [newTaskName, setNewTaskName] = useState('');
  const { t } = useTranslation();

  const handleAddTask = async () => {
    if (newTaskName.trim() === '') return;

    try {
      await axios.post('/api/v1/tasks', {
        name: newTaskName,
        status: 'backlog',
        priority: 'normal',
        area: 'other'
      }, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setNewTaskName('');
      setIsAdding(false);
      onAdd();
    } catch (error) {
      console.error('Error adding new task:', error);
    }
  };

  if (!isAdding) {
    return (
      <button
        className="w-full mt-0 px-4 py-2 bg-gray-200 outline-dashed outline-gray-400 hover:outline-gray-200 text-gray-400 rounded hover:bg-white hover:text-black transition-colors"
        onClick={() => setIsAdding(true)}
      >
        {t('newTask')}
      </button>
    );
  }

  return (
    <div className="bg-white p-4 mt-4 rounded shadow">
      <input
        type="text"
        value={newTaskName}
        onChange={(e) => setNewTaskName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAddTask();
          }
        }}
        placeholder={t('enterTaskName')}
        className="text-sm rounded-md focus:border-secondary-color focus:ring-secondary-color font-bold mb-2 w-full"
        autoFocus
      />
      <div className="flex justify-end space-x-2">
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 text-sm transition-colors"
          onClick={() => setIsAdding(false)}
        >
          {t('cancel')}
        </button>
        <button
          className="px-4 py-2 bg-primary-color text-white rounded hover:bg-secondary-color text-sm transition-colors"
          onClick={handleAddTask}
        >
          {t('addTask')}
        </button>
      </div>
    </div>
  );
};

export default NewTaskCard;