import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import TaskCard from './TaskCard';
import NewTaskCard from './NewTaskCard';
import Notification from './Notification';
import axios from '../axios';
import { 
  CircleStackIcon, 
  PlayIcon, 
  ClipboardDocumentCheckIcon, 
  CheckCircleIcon,
  BarsArrowUpIcon,
  BarsArrowDownIcon
} from '@heroicons/react/24/outline';

const KanbanBoard = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [showOnlyMyTasks, setShowOnlyMyTasks] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  // Add notification state
  const [showNotification, setShowNotification] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    type: 'success',
    message: '',
    errors: []
  });

  const columns = [
    { id: 'backlog', title: t('Backlog'), icon: <CircleStackIcon className="h-5 w-5 me-2 inline" /> },
    { id: 'current', title: t('Current'), icon: <PlayIcon className="h-5 w-5 me-2 inline" /> },
    { id: 'approval', title: t('Approval'), icon: <ClipboardDocumentCheckIcon className="h-5 w-5 me-2 inline" /> },
    { id: 'done', title: t('Done'), icon: <CheckCircleIcon className="h-5 w-5 me-2 inline" /> },
  ];

  // Function to show notifications
  const showNotificationMessage = (type, message, errors = []) => {
    setNotificationProps({ type, message, errors });
    setShowNotification(true);
  };

  useEffect(() => {
    fetchTasks();
    fetchUsers();
  }, [showArchived, showOnlyMyTasks]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/api/v1/tasks', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
        params: { includeArchived: showArchived },
      });
      
      let filteredTasks = response.data;
      
      if (showOnlyMyTasks) {
        const currentUser = JSON.parse(sessionStorage.getItem('user'));
        filteredTasks = filteredTasks.filter(task => 
          task.assigned_to_id === currentUser.id || 
          task.approved_by_id === currentUser.id
        );
      }

      filteredTasks.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return sortOrder === 'asc' 
          ? dateA - dateB 
          : dateB - dateA;
      });

      setTasks(filteredTasks);
    } catch (error) {
      showNotificationMessage('error', t('Error fetching tasks'), [error.message]);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setUsers(response.data.entity.users);
    } catch (error) {
      showNotificationMessage('error', t('Error fetching users'), [error.message]);
    }
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const task = tasks.find(t => t.id.toString() === draggableId);
    const oldStatus = task.status;
    const newStatus = destination.droppableId;

    const currentUser = JSON.parse(sessionStorage.getItem('user'));
    if (
      (newStatus === 'done' && task.approved_by_id !== currentUser.id) ||
      (newStatus !== 'done' && task.assigned_to_id !== currentUser.id)
    ) {
      showNotificationMessage('error', t("You don't have permission to move this task."));
      return;
    }

    const newTasks = tasks.map(t => {
      if (t.id === task.id) {
        return { ...t, status: newStatus };
      }
      return t;
    });
    setTasks(newTasks);

    try {
      await axios.put(`/api/v1/tasks/${task.id}`,
        { task: { status: newStatus } },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` } }
      );
    } catch (error) {
      setTasks(tasks); // Revert the change
      showNotificationMessage('error', t('Failed to update task'), [error.message]);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(current => {
      const newOrder = current === 'asc' ? 'desc' : 'asc';
      const sortedTasks = [...tasks].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return newOrder === 'asc' 
          ? dateA - dateB 
          : dateB - dateA;
      });
      setTasks(sortedTasks);
      return newOrder;
    });
  };

  return (
    <>
      {/* Add Notification component at the top level */}
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        type={notificationProps.type}
        message={notificationProps.message}
        errors={notificationProps.errors}
      />

      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold">{t('Tasks')}</h1>
        <div className="flex items-center gap-4">
          {/* Sort Order Toggle */}
          <button 
            onClick={toggleSortOrder}
            className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {sortOrder === 'asc' ? (
              <BarsArrowUpIcon className="h-5 w-5 me-1" />
            ) : (
              <BarsArrowDownIcon className="h-5 w-5 me-1" />
            )}
            {t('Sort by Creation Date')}
          </button>

          {/* My Tasks Toggle */}
          <label className="inline-flex items-center cursor-pointer">
            <input 
              type="checkbox" 
              checked={showOnlyMyTasks} 
              onChange={() => setShowOnlyMyTasks(prev => !prev)} 
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              {t('Show only my tasks')}
            </span>
          </label>

          {/* Archive Toggle */}
          <label className="inline-flex items-center cursor-pointer">
            <input 
              type="checkbox" 
              checked={showArchived} 
              onChange={() => setShowArchived(prev => !prev)} 
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            {t('Show archived tasks')}
            </span>
          </label>
        </div>
      </div>

      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex space-x-4 p-4">
          {columns.map(column => (
            <div key={column.id} className="flex-1">
              <h2 className="text-lg font-semibold mb-2">
                {column.icon}
                {column.title}
              </h2>
              <Droppable droppableId={column.id}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="bg-gray-200 p-4 rounded-lg min-h-[200px]"
                  >
                    {tasks
                      .filter(task => task.status === column.id)
                      .map((task, index) => (
                        <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TaskCard 
                                task={task} 
                                users={users} 
                                onUpdate={fetchTasks}
                                showNotification={showNotificationMessage} 
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                    {column.id === 'backlog' && (
                      <NewTaskCard 
                        onAdd={fetchTasks}
                        showNotification={showNotificationMessage}
                      />
                    )}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

export default KanbanBoard;