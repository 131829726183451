import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { countryData } from '../../constants/countryData';
import axios from '../../axios';
import SubscriptionChoice from './SubscriptionChoice';
import Notification from '../../components/Notification';

const sortedCountryData = [...countryData].sort((a, b) => a.name.localeCompare(b.name));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SignUp() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  // Entity/Company State
  const [entityType, setEntityType] = useState('Company');
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [yearFounded, setYearFounded] = useState('');

  // Personal & Billing Information State
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [billingAddress, setBillingAddress] = useState({
    company: '',
    line1: '',
    line2: '',
    postal_code: '',
    city: '',
    state: '',
  });

  // Location/Language State
  const [country, setCountry] = useState(sortedCountryData[0]);
  const [language, setLanguage] = useState({ id: 'en', name: 'English' });

  // Wait for i18n to initialize and set initial language
  useEffect(() => {
    if (i18n.isInitialized) {
      setLanguage({
        id: i18n.language || 'en',
        name: i18n.language === 'de' ? 'Deutsch' : 'English'
      });
      setIsLoading(false);
    }
  }, [i18n.isInitialized, i18n.language]);

  // Update language when it changes
  useEffect(() => {
    if (i18n.isInitialized) {
      setLanguage({
        id: i18n.language || 'en',
        name: i18n.language === 'de' ? 'Deutsch' : 'English'
      });
    }
  }, [i18n.language]);

  // Subscription State
  const [subscriptionTier, setSubscriptionTier] = useState('founder');
  const [isProcessing, setIsProcessing] = useState(false);

  const [notification, setNotification] = useState({
    show: false,
    type: '',
    message: '',
    errors: []
  });

  // Handle billing address changes
  const handleAddressChange = (field, value) => {
    setBillingAddress(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
  
    try {
      // 1. Create user/company first
      const userData = {
        user: {
          email,
          password,
          password_confirmation: confirmPassword,
          first_name: firstName,
          last_name: lastName,
          entity_type: entityType,
          entity_name: companyName,
          website,
          year_founded: parseInt(yearFounded, 10),
          country: country.code,
          language: i18n.language || 'en',
          billing_address: {
            ...billingAddress,
            country: country.code
          }
        }
      };
  
      const response = await axios.post('/api/v1/users', userData);
      
      // Store the authentication token
      localStorage.setItem('authToken', response.data.authentication_token);
  
      // 2. Create Stripe checkout session
      const sessionResponse = await axios.post(
        '/api/v1/stripe/create_checkout_session',
        {
          tier: subscriptionTier,
          success_url: `${window.location.origin}/registration-success?payment_status=succeeded`,
          cancel_url: `${window.location.origin}/registration-success?payment_status=failed`,
          locale: i18n.language || 'en',
        },
        { headers: { Authorization: `Bearer ${response.data.authentication_token}` } }
      );
  
      // 3. Redirect to Stripe Checkout
      window.location.href = sessionResponse.data.url;
  
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      const errorDetails = error.response?.data?.errors || [];
  
      setNotification({
        show: true,
        type: 'error',
        message: t('errors.registrationFailed'),
        errors: [errorMessage, ...errorDetails],
      });
      
      // Clear token if registration failed
      localStorage.removeItem('authToken');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6 pb-20">
        <Notification
          show={notification.show}
          setShow={(show) => setNotification(prev => ({ ...prev, show }))}
          type={notification.type}
          message={notification.message}
          errors={notification.errors}
        />

        <div className="bg-white p-12 rounded-lg shadow-lg w-full max-w-3xl">
          <h2 className="text-2xl font-bold mb-6 text-center text-primary-color">
            {t('sign_up')}
          </h2>

          <form onSubmit={handleSubmit} className="space-y-12">
            {/* Company Profile Section */}
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-primary-color">
                  {t('company_profile')}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {t('company_profile_info')}
                </p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full">
                  <div className="flex space-x-0">
                    <button
                      type="button"
                      className={`w-1/2 px-4 py-2 rounded-l-md ${entityType === 'Company'
                          ? 'bg-primary-color text-white'
                          : 'bg-gray-200 text-gray-900'
                        }`}
                      onClick={() => setEntityType('Company')}
                    >
                      {t('company')}
                    </button>
                    <button
                      type="button"
                      className="w-1/2 px-4 py-2 rounded-r-md bg-gray-200 text-gray-400"
                      disabled
                    >
                      {t('portfolio')}
                    </button>
                  </div>
                </div>

                {/* Company Details Fields */}
                <div className="sm:col-span-6">
                  <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('company_name')}
                  </label>
                  <input
                    type="text"
                    id="company-name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('website')}
                  </label>
                  <div className="mt-2 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                      http://
                    </span>
                    <input
                      type="text"
                      id="website"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      className="block w-full rounded-none rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                      placeholder="www.example.com"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="year-founded" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('year_founded')}
                  </label>
                  <input
                    type="number"
                    id="year-founded"
                    value={yearFounded}
                    onChange={(e) => setYearFounded(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    min="1000"
                    max={new Date().getFullYear() + 2}
                  />
                </div>

                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    {t('country')}
                  </label>
                  <Listbox value={country} onChange={setCountry}>
                    {({ open }) => (
                      <div className="relative mt-2">
                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-color sm:text-sm sm:leading-6">
                          <span className="block truncate">{t(`Countries.${country.name}`)}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </ListboxButton>

                        <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {sortedCountryData.map((country) => (
                            <ListboxOption
                              key={country.code}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-primary-color text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={country}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {t(`Countries.${country.name}`)}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-primary-color',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </div>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>

          {/* Subscription Choice */}
          <SubscriptionChoice
            selectedTier={subscriptionTier}
            onTierSelect={setSubscriptionTier}
            t={t}
          />

            {/* Personal Information Section */}
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-primary-color">
                  {t('personal_information')}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {t('use_permanent_address')}
                </p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('first_name')}
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('last_name')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('email_address')}
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('password')}
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('confirm_password')}
                  </label>
                  <input
                    type="password"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    autoComplete="new-password"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

          {/* Form Actions */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="text-sm font-semibold text-gray-900"
              onClick={() => navigate('/')}
              disabled={isProcessing}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              disabled={isProcessing}
              className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-color/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color disabled:opacity-50"
            >
              {isProcessing ? t('processing') : t('continue_to_payment')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}