// DashboardLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import MobileNavigation from './MobileNavigation';
import Breadcrumbs from './Breadcrumbs';
import './DashboardLayout.css';

export default function DashboardLayout() {
  return (
    <div className="flex h-full min-h-screen">
      <Sidebar className="hidden md:block" />
      <div className="flex-1 flex flex-col">
        <Breadcrumbs />
        <main className="flex-1 content-area p-6 bg-gray-100">
          <Outlet />
        </main>
      </div>
      <MobileNavigation className="block md:hidden" />
    </div>
  );
}