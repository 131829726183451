// src/components/wizard_components/Slider.js
import React from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import './Slider.css';

const AgeRangeSlider = ({ value = [0, 100], onChange, min = 0, max = 100 }) => {
  return (
    <div className="slider-container">
      <Slider
        range
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        trackStyle={[{ backgroundColor: 'var(--primary-color)' }]}
        handleStyle={[
          { borderColor: 'var(--primary-color)' },
          { borderColor: 'var(--primary-color)' }
        ]}
        railStyle={{ backgroundColor: 'var(--tertiary-color)' }}
      />
      <div className="slider-labels">
        <span>{value[0]}</span>
        <span>{value[1]}</span>
      </div>
    </div>
  );
};

AgeRangeSlider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number
};

export default AgeRangeSlider;