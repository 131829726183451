import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Radiothumbs from '../../../components/wizard_components/Radiothumbs';
import Checkthumbs from '../../../components/wizard_components/Checkthumbs';
import TextField from '../../../components/wizard_components/TextField';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useSchema } from '../../../hooks/useSchema';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';

const KnowYourselfWizardInvestment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, loading: userDataLoading } = useUserData();
  const { schema, loading: schemaLoading } = useSchema();
  const { handleSubmit, handleDraftSubmit, error: submitError } = useFormSubmit('/know-yourself');
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [dataStatus, setDataStatus] = useState('new');
  const [initialValues, setInitialValues] = useState({
    investmentStage: '',
    lookingForFunding: '',
    fundingAmount: '',
    fundingCurrency: 'USD',
    targetFundingRound: '',
    exitStrategies: [],
    investmentNotes: '',
  });

  useEffect(() => {
    if (userData?.entity?.know_yourself_response) {
      const { published, draft } = userData.entity.know_yourself_response;
      const relevantDraftData = {
        investmentStage: draft?.investmentStage || '',
        lookingForFunding: draft?.lookingForFunding || '',
        fundingAmount: draft?.fundingAmount || '',
        fundingCurrency: draft?.fundingCurrency || 'USD',
        targetFundingRound: draft?.targetFundingRound || '',
        exitStrategies: draft?.exitStrategies || [],
        investmentNotes: draft?.investmentNotes || '',
      };
      const relevantPublishedData = {
        investmentStage: published?.investmentStage || '',
        lookingForFunding: published?.lookingForFunding || '',
        fundingAmount: published?.fundingAmount || '',
        fundingCurrency: published?.fundingCurrency || 'USD',
        targetFundingRound: published?.targetFundingRound || '',
        exitStrategies: published?.exitStrategies || [],
        investmentNotes: published?.investmentNotes || '',
      };
  
      const isDataEmpty = (data) => {
        return Object.values(data).every(value => 
          value === '' || 
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === 'object' && Object.keys(value).length === 0)
        );
      };
  
      const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);
  
      if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
        setDataStatus('draft');
        setInitialValues(relevantDraftData);
      } else if (!isDataEmpty(relevantPublishedData)) {
        setDataStatus('published');
        setInitialValues(relevantPublishedData);
      } else {
        setDataStatus('new');
        setInitialValues({
          investmentStage: '',
          lookingForFunding: '',
          fundingAmount: '',
          fundingCurrency: 'USD',
          targetFundingRound: '',
          exitStrategies: [],
          investmentNotes: '',
        });
      }
    }
  }, [userData]);

  const investmentStageSvgs = {
    "Pre-Seed": {
      default: '/thumbs/PreSeed_investmentstage.svg',
      hover: '/thumbs/PreSeed_investmentstage_hover.svg',
      active: '/thumbs/PreSeed_investmentstage_active.svg'
    },
    "Seed": {
      default: '/thumbs/Seed_investmentstage.svg',
      hover: '/thumbs/Seed_investmentstage_hover.svg',
      active: '/thumbs/Seed_investmentstage_active.svg'
    },
    "Series A": {
      default: '/thumbs/SeriesA_investmentstage.svg',
      hover: '/thumbs/SeriesA_investmentstage_hover.svg',
      active: '/thumbs/SeriesA_investmentstage_active.svg'
    },
    "Series B": {
      default: '/thumbs/SeriesB_investmentstage.svg',
      hover: '/thumbs/SeriesB_investmentstage_hover.svg',
      active: '/thumbs/SeriesB_investmentstage_active.svg'
    },
    "Series C": {
      default: '/thumbs/SeriesC_investmentstage.svg',
      hover: '/thumbs/SeriesC_investmentstage_hover.svg',
      active: '/thumbs/SeriesC_investmentstage_active.svg'
    },
    "Past Series C": {
      default: '/thumbs/SeriesCplus_investmentstage.svg',
      hover: '/thumbs/SeriesCplus_investmentstage_hover.svg',
      active: '/thumbs/SeriesCplus_investmentstage_active.svg'
    },
    "Post-Investment": {
      default: '/thumbs/PostInvestment_investmentstage.svg',
      hover: '/thumbs/PostInvestment_investmentstage_hover.svg',
      active: '/thumbs/PostInvestment_investmentstage_active.svg'
    },
    "Not Applicable": {
      default: '/thumbs/NotApplicable_investmentstage.svg',
      hover: '/thumbs/NotApplicable_investmentstage_hover.svg',
      active: '/thumbs/NotApplicable_investmentstage_active.svg'
    }
  };

  const exitStrategySvgs = {
    "Strategic Merger/Acquisition": {
      default: '/thumbs/MandA_exit.svg',
      hover: '/thumbs/MandA_exit_hover.svg',
      active: '/thumbs/MandA_exit_active.svg'
    },
    "Private Equity Acquisition/Buyout": {
      default: '/thumbs/PEAquisition_exit.svg',
      hover: '/thumbs/PEAquisition_exit_hover.svg',
      active: '/thumbs/PEAquisition_exit_active.svg'
    },
    "Public Listing (IPO)": {
      default: '/thumbs/IPO_exit.svg',
      hover: '/thumbs/IPO_exit_hover.svg',
      active: '/thumbs/IPO_exit_active.svg'
    },
    "Succession": {
      default: '/thumbs/Succession_exit.svg',
      hover: '/thumbs/Succession_exit_hover.svg',
      active: '/thumbs/Succession_exit_active.svg'
    },
    "No Exit Planned": {
      default: '/thumbs/NoExit_exit.svg',
      hover: '/thumbs/NoExit_exit_hover.svg',
      active: '/thumbs/NoExit_exit_active.svg'
    }
  };

  const validationSchema = Yup.object({
    investmentStage: Yup.string().required(t('KnowYourselfWizard.investmentStage')),
    lookingForFunding: Yup.string().required(t('KnowYourselfWizard.lookingForFunding')),
    fundingAmount: Yup.number().when('lookingForFunding', {
      is: 'Yes',
      then: () => Yup.number().required(t('KnowYourselfWizard.fundingAmount')).min(1).max(1000000000),
      otherwise: () => Yup.number().notRequired()
    }),
    fundingCurrency: Yup.string().when('lookingForFunding', {
      is: 'Yes',
      then: () => Yup.string().required(t('KnowYourselfWizard.fundingCurrency')),
      otherwise: () => Yup.string().notRequired()
    }),
    targetFundingRound: Yup.string().when('lookingForFunding', {
      is: 'Yes',
      then: () => Yup.string().required(t('KnowYourselfWizard.targetFundingRound')),
      otherwise: () => Yup.string().notRequired()
    }),
    exitStrategies: Yup.array().min(1, t('KnowYourselfWizard.exitStrategies')),
    investmentNotes: Yup.string().max(1000, t('KnowYourselfWizard.notesMaxLength'))
  });

  const formatFundingAmount = (value) => {
    if (!value) return '';
    return new Intl.NumberFormat('en-US').format(value);
  };

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await handleSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
      setDataStatus('published');
      await handleDraftSubmit({ responses: values });

      setTimeout(() => {
        navigate('/know-yourself');
      }, 1000);
    } catch (error) {
      setNotification({ 
        show: true, 
        type: 'error', 
        message: 'Failed to save data', 
        errors: [error.message] 
      });
    } finally {
      setSubmitting(false);
    }
  };
  
  const onDraftSubmit = async (values, { setSubmitting }) => {
    try {
      await handleDraftSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: 'Draft saved successfully!' });
      setDataStatus('draft');
    } catch (error) {
      setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Notification
        show={notification.show}
        setShow={(show) => setNotification({ ...notification, show })}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />
      <Progress />
      <div className="data-status-banner mt-4 mb-4">
        {dataStatus === 'published' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is the last saved and published version.')}
          </span>
        )}
        {dataStatus === 'draft' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is a draft version. Some of the information on this page might not have been published yet.')}
          </span>
        )}
        {dataStatus === 'new' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('There is no prior version of this page. Please manually save or save as draft below.')}
          </span>
        )}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isSubmitting, validateForm }) => {
          const handleSubmit = async (e) => {
            e.preventDefault();
            const errors = await validateForm(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values, { setSubmitting: () => {} });
            } else {
              const errorMessages = Object.entries(errors).flatMap(([field, message]) => 
                typeof message === 'string' ? [`${t(`KnowYourselfWizard.${field}`)}: ${message}`] : []
              );
              setNotification({
                show: true,
                type: 'error',
                message: 'Failed to save data due to validation errors:',
                errors: errorMessages
              });
            }
          };

          return (
            <Form onSubmit={handleSubmit}>
          <div className="investment-section">
            <h2 className="text-primary-color">{t('KnowYourselfWizard.funding')}</h2>
            <h3 className="mt-6 text-primary-color">{t('KnowYourselfWizard.investmentStage')}</h3>
            <p>{t('KnowYourselfWizard.investmentStageDescription')}</p>
            <Radiothumbs
              name="investmentStage"
              options={Object.keys(investmentStageSvgs).map(stage => ({
                value: stage,
                svg: investmentStageSvgs[stage],
                label: t(`InvestmentStages.${stage}`)
              }))}
              value={values.investmentStage}
              onChange={(e) => setFieldValue('investmentStage', e.target.value)}
            />

            <h3 className="mt-6 text-primary-color">{t('KnowYourselfWizard.lookingForFunding')}</h3>
            <Field as="select" name="lookingForFunding" className="mt-1 block w-full border-gray-300 rounded-md">
              <option value="">{t('Select an option')}</option>
              <option value="Yes">{t('yes')}</option>
              <option value="No">{t('no')}</option>
            </Field>

            {values.lookingForFunding === 'Yes' && (
              <>
                <p className="mt-6">{t('KnowYourselfWizard.fundingAmount')}</p>
                <div className="flex">
                  <Field 
                    as="select" 
                    name="fundingCurrency" 
                    className="mt-1 block w-24 border-gray-300 rounded-md mr-2"
                  >
                    <option value="">{t('currency')}</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="CHF">CHF</option>
                    <option value="CAD">CAD</option>
                    <option value="GBP">GBP</option>
                    <option value="JPY">JPY</option>
                  </Field>
                  <Field 
                    name="fundingAmount" 
                    type="text" 
                    className="mt-1 block w-full border-gray-300 rounded-md"
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setFieldValue('fundingAmount', value);
                    }}
                    value={formatFundingAmount(values.fundingAmount)}
                  />
                </div>

                <p className="mt-6">{t('KnowYourselfWizard.targetFundingRound')}</p>
                <Radiothumbs
                  name="targetFundingRound"
                  options={Object.keys(investmentStageSvgs).filter(stage => stage !== 'Not Applicable' && stage !== 'Post-Investment').map(stage => ({
                    value: stage,
                    svg: investmentStageSvgs[stage],
                    label: t(`InvestmentStages.${stage}`)
                  }))}
                  value={values.targetFundingRound}
                  onChange={(e) => setFieldValue('targetFundingRound', e.target.value)}
                />
              </>
            )}

            <h3 className="mt-6 text-primary-color">{t('KnowYourselfWizard.exitStrategy')}</h3>
            <p>{t('KnowYourselfWizard.exitStrategyDescription')}</p>
            <Checkthumbs
              name="exitStrategies"
              options={Object.keys(exitStrategySvgs).map(strategy => ({
                value: strategy,
                svg: exitStrategySvgs[strategy],
                label: t(`ExitStrategies.${strategy}`)
              }))}
              value={values.exitStrategies}
              onChange={(value) => setFieldValue('exitStrategies', value)}
            />
          </div>
          <div className="mt-6"></div>
            <p>{t('KnowYourselfWizard.investmentNotesDescription')}</p>
            <TextField 
              name="investmentNotes" 
              type="textarea" 
            />
          <div className="mt-6 flex items-center justify-end gap-x-6">
                <button 
                  type="button" 
                  className="text-sm text-gray-900"
                  onClick={() => navigate('/know-yourself/team')}
                >
                  {t('cancel')}
                </button>
                <button
                  type="button"
                  onClick={() => onDraftSubmit(values, { setSubmitting: () => {} })}
                  disabled={isSubmitting}
                  className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  {t('Save as Draft')}
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                >
                  {isSubmitting ? t('saving') : t('save')}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default KnowYourselfWizardInvestment;