import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export default function Logout() {
  const { user } = useContext(AuthContext);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Page Name</h1>
      <p>Welcome, {user?.first_name}!</p>
    </div>
  );
}
