// In EmployeeCard.js

import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from "react-icons/io5";

const EmployeeCard = ({ employee, index, type, companyYearFounded, onRemove, setFieldValue }) => {
  const { t } = useTranslation();
  const years = Array.from({ length: 61 }, (_, i) => i + 1970);

  const handleChange = (field) => (event) => {
    setFieldValue(`${type}s[${index}].${field}`, event.target.value);
  };

  return (
    <div className="border rounded-lg p-4 mb-4 bg-white max-w-m">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">
          {employee.firstName && employee.lastName 
            ? `${employee.firstName} ${employee.lastName}` 
            : `${t(type === 'founder' ? 'Founder' : 'Key Team Member')} ${index + 1}`}
        </h3>
        <button
          type="button"
          className="text-red-500 hover:text-red-700 transition-colors duration-200"
          onClick={onRemove}
          aria-label={t('Remove')}
        >
          <IoTrashOutline className="w-5 h-5" />
        </button>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('First Name')}</label>
          <input
            name={`${type}s[${index}].firstName`}
            value={employee.firstName}
            onChange={handleChange('firstName')}
            className="mt-1 block w-full border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Last Name')}</label>
          <input
            name={`${type}s[${index}].lastName`}
            value={employee.lastName}
            onChange={handleChange('lastName')}
            className="mt-1 block w-full border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Role')}</label>
        <input
          name={`${type}s[${index}].role`}
          value={employee.role}
          onChange={handleChange('role')}
          className="mt-1 block w-full border-gray-300 rounded-md"
        />
      </div>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Email')}</label>
        <input
          name={`${type}s[${index}].email`}
          value={employee.email}
          onChange={handleChange('email')}
          className="mt-1 block w-full border-gray-300 rounded-md"
        />
      </div>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('In company since')}</label>
        {type === 'founder' ? (
          <input
            name={`${type}s[${index}].yearInCompany`}
            className="mt-1 block w-full border-gray-300 rounded-md bg-gray-100"
            disabled
            value={companyYearFounded}
          />
        ) : (
          <select
            name={`${type}s[${index}].yearInCompany`}
            value={employee.yearInCompany}
            onChange={handleChange('yearInCompany')}
            className="mt-1 block w-full border-gray-300 rounded-md"
          >
            {years.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default EmployeeCard;