import React, { useEffect } from 'react';

const Confetti = () => {
  useEffect(() => {
    // Globals
    const random = Math.random,
      cos = Math.cos,
      sin = Math.sin,
      PI = Math.PI,
      PI2 = PI * 2;
    let timer = undefined,
      frame = undefined,
      confetti = [];

    const particles = 10,
      spread = 40,
      sizeMin = 3,
      sizeMax = 12 - sizeMin,
      eccentricity = 10,
      deviation = 100,
      dxThetaMin = -0.1,
      dxThetaMax = -dxThetaMin - dxThetaMin,
      dyMin = 0.13,
      dyMax = 0.18,
      dThetaMin = 0.4,
      dThetaMax = 0.7 - dThetaMin;

      const colorThemes = [
        function() {
          return color(200 * random()|0, 200 * random()|0, 200 * random()|0);
        }
      ];
  
      function color(r, g, b) {
        return 'rgb(' + r + ',' + g + ',' + b + ')';
      }

    // Cosine interpolation
    function interpolation(a, b, t) {
      return ((1 - cos(PI * t)) / 2) * (b - a) + a;
    }

    // Create a 1D Maximal Poisson Disc over [0, 1]
    const radius = 1 / eccentricity,
      radius2 = radius + radius;
    function createPoisson() {
      let domain = [radius, 1 - radius],
        measure = 1 - radius2,
        spline = [0, 1];
      while (measure) {
        let dart = measure * random(),
          i,
          l,
          interval,
          a,
          b,
          c,
          d;

        for (i = 0, l = domain.length, measure = 0; i < l; i += 2) {
          a = domain[i];
          b = domain[i + 1];
          interval = b - a;
          if (dart < measure + interval) {
            spline.push((dart += a - measure));
            break;
          }
          measure += interval;
        }
        c = dart - radius;
        d = dart + radius;

        for (i = domain.length - 1; i > 0; i -= 2) {
          l = i - 1;
          a = domain[l];
          b = domain[i];
          if (a >= c && a < d) {
            if (b > d) {
              domain[l] = d;
            } else {
              domain.splice(l, 2);
            }
          } else if (a < c && b > c) {
            if (b <= d) {
              domain[i] = c;
            } else {
              domain.splice(i, 0, c, d);
            }
          }
        }

        for (i = 0, l = domain.length, measure = 0; i < l; i += 2) {
          measure += domain[i + 1] - domain[i];
        }
      }

      return spline.sort();
    }

    // Create the overarching container
    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.width = '100%';
    container.style.height = '0';
    container.style.overflow = 'visible';
    container.style.zIndex = '9999';

    // Confetto constructor
    function Confetto(theme) {
      this.frame = 0;
      this.outer = document.createElement('div');
      this.inner = document.createElement('div');
      this.outer.appendChild(this.inner);

      const outerStyle = this.outer.style,
        innerStyle = this.inner.style;
      outerStyle.position = 'absolute';
      outerStyle.width = sizeMin + sizeMax * random() + 'px';
      outerStyle.height = sizeMin + sizeMax * random() + 'px';
      innerStyle.width = '100%';
      innerStyle.height = '100%';
      innerStyle.backgroundColor = theme();

      outerStyle.perspective = '50px';
      outerStyle.transform = 'rotate(' + 360 * random() + 'deg)';
      this.axis =
        'rotate3D(' +
        cos(360 * random()) +
        ',' +
        cos(360 * random()) +
        ',0,';
      this.theta = 360 * random();
      this.dTheta = dThetaMin + dThetaMax * random();
      innerStyle.transform = this.axis + this.theta + 'deg)';

      this.x = window.innerWidth * random();
      this.y = -deviation;
      this.dx = sin(dxThetaMin + dxThetaMax * random());
      this.dy = dyMin + dyMax * random();
      outerStyle.left = this.x + 'px';
      outerStyle.top = this.y + 'px';

      this.splineX = createPoisson();
      this.splineY = [];
      for (let i = 1, l = this.splineX.length - 1; i < l; ++i) {
        this.splineY[i] = deviation * random();
      }
      this.splineY[0] = this.splineY[this.splineX.length - 1] = deviation * random();

      this.update = function (height, delta) {
        this.frame += delta;
        this.x += this.dx * delta;
        this.y += this.dy * delta;
        this.theta += this.dTheta * delta;

        const phi = (this.frame % 7777) / 7777;
        let i = 0,
          j = 1;
        while (phi >= this.splineX[j]) i = j++;
        const rho = interpolation(
          this.splineY[i],
          this.splineY[j],
          (phi - this.splineX[i]) / (this.splineX[j] - this.splineX[i])
        );
        const phiPi = phi * PI2;

        outerStyle.left = this.x + rho * cos(phiPi) + 'px';
        outerStyle.top = this.y + rho * sin(phiPi) + 'px';
        innerStyle.transform = this.axis + this.theta + 'deg)';
        return this.y > height + deviation;
      };
    }

    function poof() {
      if (!frame) {
        document.body.appendChild(container);

        // Use a random color theme instead of just the first one
        const theme = colorThemes[Math.floor(random() * colorThemes.length)];
        const addConfetto = () => {
          const confetto = new Confetto(theme);
          confetti.push(confetto);
          container.appendChild(confetto.outer);
          timer = setTimeout(addConfetto, spread * random());
        };
        
        addConfetto();

        setTimeout(() => {
          if (timer) {
            clearTimeout(timer);
            timer = undefined;
          }
        }, 5000);

        let prev = undefined;
        const loop = (timestamp) => {
          const delta = prev ? timestamp - prev : 0;
          prev = timestamp;
          const height = window.innerHeight;

          for (let i = confetti.length - 1; i >= 0; --i) {
            if (confetti[i].update(height, delta)) {
              container.removeChild(confetti[i].outer);
              confetti.splice(i, 1);
            }
          }

          if (timer || confetti.length) {
            frame = requestAnimationFrame(loop);
          } else {
            document.body.removeChild(container);
            frame = undefined;
          }
        };
        
        frame = requestAnimationFrame(loop);
      }
    }

    // Start the animation when component mounts
    poof();

    // Cleanup when component unmounts
    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }
      if (frame) {
        cancelAnimationFrame(frame);
        frame = undefined;
      }
      if (container && container.parentNode) {
        container.parentNode.removeChild(container);
      }
      confetti = [];
    };
  }, []); // Empty dependency array means this only runs once when mounted

  return null; // This component doesn't render anything visible directly
};

export default Confetti;